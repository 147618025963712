import { useStore } from "./useStore";

/**
 * Use the email to uniquely identify the logged in user because that is what Okta gives us.
 * @interface OktaUser
 */
export interface OktaUser {
  // email is often the foreign key, preferred_username is also the email and commented out for safety
  email: string | null;
  // email_verified: boolean | null;
  // family_name: string | null;
  // given_name: string | null;
  // locale: string | null;
  // name: string | null;
  // preferred_username: string | null;
  // sub: string | null;
  // updated_at: number | null;
  // zoneinfo: string | null;
}

const initialOktaUser = {
  value: null,
  loading: true
};
export interface UseOktaUser {
  value: OktaUser | null;
  loading: boolean;
}
const useOktaUserStore_ = useStore<UseOktaUser>(initialOktaUser);

export const useOktaUserStore = (): [UseOktaUser, (t: UseOktaUser) => void] => {
  const [value, setOktaUser] = useOktaUserStore_();

  if (value && value.value && value.value.email) {
    // redundant as lowercased upon first fetch, but consistently insures lowercase
    value.value.email = value.value.email.toLowerCase().trim();
  }
  return [
    value,
    (usedOktaUser: UseOktaUser): void => {
      return setOktaUser(usedOktaUser);
    }
  ];
};
