import {
  OnClickQuantitySelectedAdd,
  OnClickQuantitySelectedRemove
} from "./onClickQuantitySelected";
import { createAlterationProductBatch } from "../../shared/functions/createAlterationProductBatch";

export const switchOnPolarityAdjustAlterationProducts = (
  input: OnClickQuantitySelectedAdd | OnClickQuantitySelectedRemove,
  units: number,
  ForcesLoadingFalse: () => "ForcesLoadingFalse"
): Promise<"ForcesLoadingFalse"> => {
  const subset = {
    upc: input.upc,
    units,
    oktaUser: input.oktaUser,
    pendingAlteration: input.pendingAlteration && input.pendingAlteration.data,
    createAlteration: input.createAlteration,
    activeClosetId: input.closetId,
    alterationPolarity: input.alterationPolarity,
    onStart: () => {
      //input.setIsUpdatingQuantity(true);
    },
    onFinish: () => {
      //input.setIsUpdatingQuantity(false);
    },
    onSuccess: () => {
      //input.setIsDialogOpen(false);
    },
    setIsAllowScan: input.setIsScanAllowed
  };
  switch (input.tag) {
    case "Add":
      return createAlterationProductBatch(
        {
          tag: "Add",
          ...subset,
          createAlterationProductBatchAddMutationFn:
            input.createAlterationProductBatchAddMutation
        },
        ForcesLoadingFalse
      );
    case "Remove":
      return createAlterationProductBatch(
        {
          tag: "Remove",
          ...subset,
          removeCreateAlterationProductBatchMutationFn:
            input.removeCreateAlterationProductBatchMutation
        },
        ForcesLoadingFalse
      );
  }
};
