import {
  Maybe,
  AlterationProduct,
  useAlterationProductsQuery,
  AlterationPolarity
} from "../../../apis/cims/generated/graphql-cims";
import { MAX_AWS_PARSABLE_DATE } from "../../../utils/constants";
import {
  AlterationLoading,
  usePendingAlteration
} from "../../../apis/cims/hooks/Alteration";
import { useActiveClosetId } from "../../../store/useGlobalState";

export interface UseAlterationProducts {
  pendingAlteration: AlterationLoading;
  alterationProducts: {
    loading: boolean;
    data: Maybe<
      Pick<
        AlterationProduct,
        "upc" | "pending" | "id" | "alterationProductClosetProductId"
      >
    >[];
  };
}

export const useAlterationProducts = (
  polarity: AlterationPolarity
): UseAlterationProducts => {
  const [activeClosetId] = useActiveClosetId();
  const pendingAlteration = usePendingAlteration(activeClosetId, polarity);

  const alterationProductsResult = useAlterationProductsQuery({
    variables: {
      alterationProductAlterationId:
        (pendingAlteration.data && pendingAlteration.data.id) ||
        "NO_ALTERATION",
      createdAt: MAX_AWS_PARSABLE_DATE
    },
    skip:
      pendingAlteration.loading ||
      !(pendingAlteration.data && pendingAlteration.data.id !== "")
  });

  const alterationProducts =
    (alterationProductsResult.data &&
      alterationProductsResult.data.listAlterationProductsByAlterationId &&
      alterationProductsResult.data.listAlterationProductsByAlterationId
        .items) ||
    [];
  return {
    pendingAlteration,
    alterationProducts: {
      loading: pendingAlteration.loading,
      data: alterationProducts
    }
  };
};
