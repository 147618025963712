import React, { useState } from "react";
import { ClosetDrawer } from "../Closet/components/ClosetDrawer";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { useDrawerOpen } from "../../store/useGlobalState";
import { Button } from "../../rmwc/button";
import { TermsDialog } from "../Closet/components/TermsDialog";
import { PageLayout } from "../shared/pageLayout/PageLayout";
import { useProductDetailsFetchClosetId } from "../../apis/cims/hooks/Closet";

export const UserAgreement = () => {
  const closetId = useProductDetailsFetchClosetId();
  const [drawerOpen, setDrawerOpen] = useDrawerOpen();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  return (
    <PageLayout>
      <ClosetDrawer {...{ closetId, drawerOpen, setDrawerOpen }} />
      <TopAppBarShared
        tag="MenuOut"
        title="User Agreement"
        onClick={() => {}}
      />
      <div style={{ padding: "1em" }}>
        You must agree to the User Agreement before you can use CIMS.
        <TermsDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
        <div style={{ padding: "1em", textAlign: "center" }}>
          <Button
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: 25,
              padding: "10px",
              fontSize: "16px",
              textTransform: "capitalize",
              fontFamily: "'Helvetica Neue', 'Arial', sans-serif"
            }}
            onClick={() => setIsDialogOpen(true)}
          >
            View User Agreement
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};
