import React from "react";
import {
  IncomingProps,
  OutgoingProps,
  IncomingRejectedProps,
  OutgoingRejectedProps
} from "../TransferDetailPage";
import { UseFetchProductsInfo } from "../../../apis/cpg/hooks/ProductPage";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { CpgProducts } from "../../shared/components/CpgProducts";
import { switchOnPropTagSummary } from "../functions/switchOnPropTagSummary";

export const SwitchOnPropTagCpgProducts = (
  props: (
    | IncomingProps
    | OutgoingProps
    | IncomingRejectedProps
    | OutgoingRejectedProps
  ) & { productsInfo: UseFetchProductsInfo }
) => {
  const [oktaUser] = useOktaUserStore();
  const productDetailsSummarized = switchOnPropTagSummary(
    props,
    props.productsInfo
  );
  const cpgProductsProps = { productDetailsSummarized, oktaUser };

  switch (props.tag) {
    case "Incoming":
      return (
        <CpgProducts tag="AlterationDetailsIncoming" {...cpgProductsProps} />
      );
    case "Outgoing":
      return (
        <CpgProducts tag="AlterationDetailsOutgoing" {...cpgProductsProps} />
      );
    case "IncomingRejected":
      return (
        <CpgProducts
          tag="AlterationDetailsIncomingRejected"
          {...cpgProductsProps}
        />
      );
    case "OutgoingRejected":
      return (
        <CpgProducts
          tag="AlterationDetailsOutgoingRejected"
          {...cpgProductsProps}
        />
      );
  }
};
