import { MAX_UNITS } from "../../../utils/constants";
import { UpcProduct, ProductDetail } from "../../../apis/cims/hooks/UpcProduct";
import { getAddAlterationQuantityRange, getRemoveAlterationQuantityRange } from "../../shared/limitsService";

type TagType =
  | "Add"
  | "Remove"
  | "AlterationDetailIncoming"
  | "AlterationDetailOutgoing"
  | "AlterationDetailIncomingRejected"
  | "AlterationDetailOutgoingRejected"
  | "ClosetDetail"

export const getUnitSelectRange = (
  tag: TagType,
  singleUpcAmountCurrent: number = 0,
  alterationProductsTotal: number,
  singleUpcAmountInCloset: number,
): [number, number] | undefined => {
  switch (tag) {
    case "Add": {
      return getAddAlterationQuantityRange(alterationProductsTotal, singleUpcAmountCurrent)
    }
    case "Remove": {
      return getRemoveAlterationQuantityRange(alterationProductsTotal, singleUpcAmountCurrent, singleUpcAmountInCloset)
    }
  }
  return undefined
}

export const switchOnPolarityUnitSelectOptions = (
  tag: TagType,
  arrayOfSpecificUpcProductsInCloset: (Partial<ProductDetail<UpcProduct>> | null)[]
) => {
  switch (tag) {
    case "Add":
      return Array.from(Array(MAX_UNITS + 1).keys()).map(x => String(x));
    case "Remove":
      const closetProductTotal =
        (arrayOfSpecificUpcProductsInCloset && arrayOfSpecificUpcProductsInCloset.length) || 0;
      return closetProductTotal > MAX_UNITS
        ? Array.from(Array(MAX_UNITS + 1).keys()).map(x => String(x))
        : Array.from(Array(closetProductTotal + 1).keys()).map(x => String(x));
    case "AlterationDetailIncoming":
    case "AlterationDetailIncomingRejected":
    case "AlterationDetailOutgoing":
    case "AlterationDetailOutgoingRejected":
    case "ClosetDetail":
      //Unused, this is a no-op.
      return [];
  }
};
