import { recordError } from "../../../utils/logging";
import ApolloClient, { ApolloQueryResult } from "apollo-client";
import memoizee from "memoizee";
import useAsync, { AsyncState } from "react-use/lib/useAsync";
import { ProductDetail, UpcProduct } from "../../cims/hooks/UpcProduct";
import {
  FindAllProductsByUpcQuery,
  FindAllProductsByUpcQueryVariables,
  FindAllProductsByUpcDocument,
  Product as CpgProduct,
  Size,
} from "../generated/graphql-cpg";
import { DocumentNode } from "graphql";
import { CPG_REGIONS } from "../../../utils/constants";
import React from "react";

export const cpgProductTitle = (
  cpgProduct: Pick<CpgProduct, "copy"> | null
) => {
  const copy = (cpgProduct && cpgProduct.copy) || null;
  if (copy) {
    if (copy.primaryName && copy.secondaryName) {
      return `${copy.secondaryName} - ${copy.primaryName}`;
    } else if (copy.secondaryName) {
      return copy.secondaryName;
    } else {
      return copy.primaryName || "";
    }
  } else {
    return "";
  }
};

const allProductsRefetch = (
  upc: string[],
  regions: string[]
): {
  query: DocumentNode;
  variables: FindAllProductsByUpcQueryVariables;
} => {
  return {
    variables: {
      upc: upc,
      region: regions
    },
    query: FindAllProductsByUpcDocument
  };
};

interface CpgProductDetail {
  product: Pick<CpgProduct, "productCode" | "genderAge" | "category"> | null;
  size: Pick<Size, "description"> | null;
  title: string;
}

export type CpgProductDetailLoading = CpgProductDetail & {
  loading: boolean;
};

export interface CpgProductResult {
  product: CpgProduct | null;
  size: Pick<Size, "description" | "upc"> | null;
  title: string;
}

export const cpgAllProductsByUpc: (
  client: ApolloClient<any>,
  upc: string[],
  region: string[]
) => Promise<(CpgProductResult | null)[] | null> = memoizee(
  (
    client: ApolloClient<any>,
    upc: string[],
    region: string[]
  ): Promise<(CpgProductResult | null)[] | null> => {
    return client
      .query<FindAllProductsByUpcQuery, FindAllProductsByUpcQueryVariables>(
        allProductsRefetch(upc, region)
      )
      .then((result: ApolloQueryResult<FindAllProductsByUpcQuery>) => {

        let allMatchedCurrentProducts: CpgProductResult[] | null = [];

        let cpgProducts =
          result.data.findProductsByUpc && result.data.findProductsByUpc.products;
        // console.log("result", result.data.findProductsByUpc)
        const cpgProductsUniqueProductCodes = [...new Set(cpgProducts?.map((data: CpgProduct | null) => data?.productCode))]; // filter out extraneous results by Product Code

        const cpgProductsUnique = cpgProductsUniqueProductCodes.map((uniqueProductCode) => { // use the unique product code only to match to upcs
          return cpgProducts?.find(cpgProduct => uniqueProductCode === cpgProduct?.productCode)
        })
        cpgProductsUnique?.forEach((currentCpgProduct) => {
          return !!currentCpgProduct?.sizes?.filter(size => {

            const hasCpgSizeForClosetUpc = !!size && size.upc && upc.includes(size?.upc);

            if (hasCpgSizeForClosetUpc) {

              let currentProductFound: CpgProductResult = {
                product: currentCpgProduct,
                size: size,
                title: currentCpgProduct?.copy?.primaryName || ""
              };
              allMatchedCurrentProducts && allMatchedCurrentProducts.push(currentProductFound);
            }
          });
        });
        return allMatchedCurrentProducts;
      })
      .catch((error: string) => {
        recordError("1r0b1f1g-2d41-400d-a19e-17926ex3dfgd", error);
        return null;
      });
  }
);

export type UseFetchAllProductsInfo = AsyncState<
  (ProductDetail<UpcProduct> | null)[] | null
>;
export function useFetchAllProductsInfo(
  upcProducts: {
    items: (UpcProduct | null)[] | null,
    loading: boolean
  },
  client: ApolloClient<any>
): UseFetchAllProductsInfo {
  const items = (upcProducts && upcProducts.items) || [];
  // const itemsShort = items && items.slice(0,800); // for testing
  const itemsUpc: string[] = items && items.map((item: UpcProduct | null) => item?.upc || "");
  // const itemsShortUpc = itemsShort.map(item => item?.upc) // for testing
  // const cpgRegions = ["USA","CHINA"]; // for testing

  const pairedAll = React.useMemo(() => {
    return itemsUpc && itemsUpc.length > 0 ? (
      cpgAllProductsByUpc(client, itemsUpc, CPG_REGIONS)
        .then((cpgProductsAllResults: (CpgProductResult | null)[] | null) => {

          let productDataNotFound: string[] = [];

          const itemsAll = items && items.map((upcProduct) => {
            if (cpgProductsAllResults && upcProduct && upcProduct.upc) {
              const cpgProductDataByClosetUpc: CpgProductResult | null | undefined = cpgProductsAllResults && cpgProductsAllResults?.find((cpgProduct) => {
                return upcProduct?.upc === cpgProduct?.size?.upc;
              })

              if (cpgProductDataByClosetUpc) {
                // console.log("cpgProductDataByClosetUpc", cpgProductDataByClosetUpc)
                return {

                  upcProduct: upcProduct,
                  cpgProduct: cpgProductDataByClosetUpc.product,
                  size: cpgProductDataByClosetUpc.size,
                  title: cpgProductTitle(cpgProductDataByClosetUpc.product),
                }

              } else {
                productDataNotFound.push(upcProduct.upc); // track which products were not found
                return null
              }
            } else {
              return null
            }
          })

          return itemsAll

        })
        .catch(error => {
          recordError("123qa759-49dd-455a-8358-c699v23589c9", error);
          return null;
        })
    ) : (
      null
    )
  }, [items, itemsUpc, client])

  const productDetailsItemsAll = useAsync<({
    upcProduct: UpcProduct;
    cpgProduct: CpgProduct | null;
    size: Size | null;
    title: string;
  } | null)[] | null>(() => {
    if (pairedAll) {
      return pairedAll;
    } else {
      return new Promise<null>((resolve, _reject) => {
        return resolve(null);
      });
    }
  }, [items && items.length]);


  if (upcProducts.loading) {
    return { loading: upcProducts.loading };
  } else {
    return productDetailsItemsAll;
  }
}
