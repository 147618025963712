import React, { useState, Dispatch, SetStateAction } from "react";

import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogButton
} from "../../../rmwc/dialog";
import { List, ListItem } from "@rmwc/list";
import { TextField } from "../../../rmwc/textfield";
import { LinearProgress } from "@rmwc/linear-progress";
import { useActiveClosetId } from "../../../store/useGlobalState";
import useDebounce from "react-use/lib/useDebounce";
import { useListClosetsByUserEmailQuery } from "../../../apis/cims/generated/graphql-cims";
import NoResults from "../../shared/components/NoResults";
import { ClosetSelected } from "../types/ClosetSelected";

interface AlterationPurposeTransferDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (x: boolean) => void;
  searchClosetsEmailValue: string;
  setClosetSelectedConfirmed: Dispatch<SetStateAction<ClosetSelected | null>>;
  setClosetSelected: Dispatch<SetStateAction<ClosetSelected>>;
  closetSelected: ClosetSelected | null;
  setSearchClosetsEmailValue: Dispatch<SetStateAction<string>>;
}

export const AlterationPurposeTransferDialog = (
  props: AlterationPurposeTransferDialogProps
) => {
  const [searchClosetsEmail, setSearchClosetsEmail] = useState("");
  const [
    searchClosetsEmailDebounced,
    setSearchClosetsEmailDebounced
  ] = React.useState("");
  useDebounce(
    () => {
      setSearchClosetsEmailDebounced(searchClosetsEmail);
    },
    500,
    [searchClosetsEmail]
  );

  const transferClosets = useListClosetsByUserEmailQuery({
    variables: {
      userEmail: searchClosetsEmailDebounced.toLowerCase()
    }
  });

  const [activeClosetId] = useActiveClosetId();
  const [selectedEmailFromDropdown, setSelectedEmailFromDropdown] = useState(
    false
  );

  const isSelectButtonAvailable = Boolean(props.closetSelected)

  return (
    <Dialog open={props.isDialogOpen}>
      <span
        style={{
          textAlign: "right",
          padding: "1em",
          fontSize: "16px",
          color: "#8D8D8D"
        }}
        onClick={() => {
          props.setIsDialogOpen(false);
        }}
      >
        X
      </span>
      <DialogTitle
        style={{
          fontFamily: "'Helvetica Neue Medium','Arial', sans-serif",
          fontSize: "24px",
          color: "#111111",
          textAlign: "left",
          lineHeight: "30px"
        }}
      >
        TRANSFER
        <hr />
        <span
          style={{
            fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
            fontSize: "16px",
            color: "#111111",
            textAlign: "left",
            lineHeight: "20px",
            fontWeight: "normal"
          }}
        >
          Select a user to transfer product(s) to
        </span>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start"
        }}
      >
        <TextField
          icon="search"
          value={props.searchClosetsEmailValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchClosetsEmail(e.target.value);
            props.setSearchClosetsEmailValue(e.target.value);
          }}
          placeholder="Search by email"
          style={{ width: "calc(100% - 7px)" }}
        />
        {searchClosetsEmail !== searchClosetsEmailDebounced && (
          <LinearProgress
            style={{
              height: 1,
              width: "calc(100% - 16px)",
              backgroundColor: "#fa5400"
            }}
          />
        )}
        <div
          style={{
            width: "calc(100% - 6px)",
            overflowY: "scroll",
            height: 150
          }}
        >
          <List
            style={{
              width: "100%",
              backgroundColor: "whitesmoke",
              textAlign: "center",
              paddingBottom: 5,
              overflow: "auto",
            }}
          >
            {(() => {
              const listClosetsItems =
                transferClosets.data?.listClosets?.items ?? [];

              const filterOtherUsersClosets =
                listClosetsItems &&
                listClosetsItems.filter(transferCloset => {
                  return transferCloset && transferCloset.id !== activeClosetId;
                });

              const filterOtherUsersClosetsSorted = filterOtherUsersClosets.sort(
                (a, b) => {
                  if (a && a.userEmail && b && b.userEmail) {
                    if (a.userEmail > b.userEmail) {
                      return 1;
                    } else {
                      return -1;
                    }
                  } else {
                    return -1;
                  }
                }
              );

              return transferClosets.loading ||
                searchClosetsEmail !== searchClosetsEmailDebounced ? (
                ""
              ) : searchClosetsEmail !== "" &&
                filterOtherUsersClosets.length < 1 ? (
                <NoResults />
              ) : (
                filterOtherUsersClosetsSorted.map(transferCloset => {
                  return (
                    <ListItem
                      activated={
                        (transferCloset && transferCloset.id) ===
                        (props.closetSelected && props.closetSelected.id)
                      }
                      onClick={() => {
                        props.setSearchClosetsEmailValue(
                          transferCloset ? transferCloset.userEmail : ""
                        );
                        props.setClosetSelected(transferCloset);
                        setSelectedEmailFromDropdown(true);
                      }}
                      style={{
                        fontFamily: "'Helvetica Neue','Arial', sans-serif"
                      }}
                    >
                      {transferCloset && transferCloset.userEmail}
                    </ListItem>
                  );
                })
              );
            })()}
          </List>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-around", padding: "1em" }}>
        <DialogButton
          onClick={() => {
            props.setIsDialogOpen(false);
            props.setClosetSelected(null);
            props.setSearchClosetsEmailValue("");
            setSearchClosetsEmail("");
            setSearchClosetsEmailDebounced("");
          }}
          style={{
            borderRadius: "22px",
            border: "1px solid black",
            fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
            backgroundColor: "white",
            color: "black",
            width: "100px",
            height: "40px",
            textTransform: "capitalize",
            fontSize: "16px"
          }}
        >
          Cancel
        </DialogButton>
        <DialogButton
          disabled={!isSelectButtonAvailable}
          onClick={() => {
            props.setIsDialogOpen(false);
            props.setClosetSelectedConfirmed(props.closetSelected);
            props.setClosetSelected(null);
            props.setSearchClosetsEmailValue("");
            setSearchClosetsEmail("");
            setSearchClosetsEmailDebounced("");
          }}
          style={{
            borderRadius: "22px",
            border:
              !isSelectButtonAvailable
                ? "1px solid silver"
                : "1px solid black",
            fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
            backgroundColor:
              !isSelectButtonAvailable
                ? "rgb(245,245,245)"
                : "black",
            color:
              !isSelectButtonAvailable
                ? "rgb(204,204,204)"
                : "white",
            width: "100px",
            height: "40px",
            textTransform: "capitalize",
            fontSize: "16px"
          }}
        >
          Select
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
