import {
  compareSizesByShoeNumber,
  compareSizesToApparelOrder
} from "./sizesFromStyleColor";

// sort sizes for dropdown either by numeric (shoes) or fixed apparel order (apparel)
export const sortedProductSizesAvailableSelected = (
  sizesToSort: string[] | undefined | null
) =>
  sizesToSort &&
  Number(sizesToSort[0]) &&
  Number(sizesToSort[sizesToSort.length - 1])
    ? sizesToSort.sort(compareSizesByShoeNumber)
    : sizesToSort
    ? sizesToSort.sort(compareSizesToApparelOrder)
    : null;
