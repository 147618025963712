import { recordError } from "../../../utils/logging";
import ApolloClient, { ApolloQueryResult } from "apollo-client";
import memoizee from "memoizee";
import useAsync, { AsyncState } from "react-use/lib/useAsync";
import React from "react";
import { ProductDetail, UpcProduct } from "../../cims/hooks/UpcProduct";
import {
  FindProductsByUpcQuery,
  FindProductsByUpcQueryVariables,
  FindProductsByUpcDocument,
  Product as CpgProduct,
  Size,
  useFindProductsByUpcQuery
} from "../generated/graphql-cpg";
import { DocumentNode } from "graphql";

export const cpgProductTitle = (
  cpgProduct: Pick<CpgProduct, "copy"> | null
) => {
  const copy = (cpgProduct && cpgProduct.copy) || null;
  if (copy) {
    if (copy.primaryName && copy.secondaryName) {
      return `${copy.secondaryName} - ${copy.primaryName}`;
    } else if (copy.secondaryName) {
      return copy.secondaryName;
    } else {
      return copy.primaryName || "";
    }
  } else {
    return "";
  }
};

const productRefetch = (
  upc: string
): {
  query: DocumentNode;
  variables: FindProductsByUpcQueryVariables;
} => {
  return {
    variables: {
      upc: upc
    },
    query: FindProductsByUpcDocument
  };
};

interface CpgProductDetail {
  product: Pick<CpgProduct, "productCode" | "genderAge" | "category"> | null;
  size: Pick<Size, "description"> | null;
  title: string;
}

export type CpgProductDetailLoading = CpgProductDetail & {
  loading: boolean;
};

export const useCpgProductByUpc = (upc: string): CpgProductDetailLoading => {
  const foundProducts = useFindProductsByUpcQuery({ variables: { upc } });

  const cpgProducts =
    foundProducts.data &&
    foundProducts.data.findProductsByUpc &&
    foundProducts.data.findProductsByUpc.products;

  let sizeOut: Pick<Size, "description"> | null = null;
  const cpgProduct =
    (cpgProducts &&
      (cpgProducts.find(product => {
        return (
          !!product &&
          !!product.region &&
          product.region === "USA" && // default to USA
          !!product.sizes &&
          !!product.sizes.find(size => {
            const isFound = !!size && size.upc === upc;
            if (isFound) {
              sizeOut = size;
            }
            return isFound; // but size must exist
          })
        );
      }) ||
        cpgProducts.find(product => {
          return (
            !!product &&
            !!product.sizes &&
            !!product.sizes.find(size => {
              const isFound = !!size && size.upc === upc;
              if (isFound) {
                sizeOut = size;
              }
              return isFound; // non-usa size better than none
            })
          );
        }) ||
        cpgProducts.find(product => {
          return !!product; // without size better than none
        }))) ||
    null;

  return {
    loading: foundProducts.loading,
    product: cpgProduct,
    size: sizeOut,
    title: cpgProductTitle(cpgProduct)
  };
};

export interface CpgProductResult {
  product: CpgProduct | null;
  size: Pick<Size, "description" | "upc"> | null;
  title: string;
}

export const cpgProductByUpc: (
  client: ApolloClient<any>,
  upc: string
) => Promise<CpgProductResult | null> = memoizee(
  (
    client: ApolloClient<any>,
    upc: string
  ): Promise<CpgProductResult | null> => {
    let sizeOut: Pick<Size, "description"> | null = null;
    console.log("Making call to CPGProductResult")
    return client
      .query<FindProductsByUpcQuery, FindProductsByUpcQueryVariables>(
        productRefetch(upc)
      )
      .then((result: ApolloQueryResult<FindProductsByUpcQuery>) => {
        const cpgProducts =
          result.data.findProductsByUpc && result.data.findProductsByUpc.products;
        const cpgProduct =
          (cpgProducts &&
            (cpgProducts.find(product => {
              return (
                !!product &&
                !!product.region &&
                product.region === "USA" && // default to USA
                !!product.sizes &&
                !!product.sizes.find(size => {
                  const isFound = !!size && size.upc === upc;
                  if (isFound) {
                    sizeOut = size;
                  }
                  return isFound; // but size must exist
                })
              );
            }) ||
              cpgProducts.find(product => {
                return (
                  !!product &&
                  !!product.sizes &&
                  !!product.sizes.find(size => {
                    const isFound = !!size && size.upc === upc;
                    if (isFound) {
                      sizeOut = size;
                    }
                    return isFound; // non-usa size better than none
                  })
                );
              }) ||
              cpgProducts.find(product => {
                return !!product; // without size better than none
              }))) ||
          null;
        // console.log("ProductPage", cpgProducts)
        return {

          product: cpgProduct,
          size: sizeOut,
          title: cpgProductTitle(cpgProduct)
        };
      })
      .catch((error: string) => {
        recordError("8b0b1f1e-7d46-400d-a99e-47926ee3dfbc", error);
        return null;
      });
  }
);


export type UseFetchProductsInfo = AsyncState<
  (ProductDetail<UpcProduct> | null)[] | null
>;

export function useFetchProductsInfo(
  upcProducts: {
    items: (UpcProduct | null)[] | null;
    loading: boolean;
  },
  client: ApolloClient<any>
): UseFetchProductsInfo {
  const items = (upcProducts && upcProducts.items) || [];
  const paired = React.useMemo(() => {
    return items
      ? items.map(closetProduct => {
        if (closetProduct && closetProduct.upc) {
          return cpgProductByUpc(client, closetProduct && closetProduct.upc)
            .then(cpgProduct => {
              return {
                upcProduct: closetProduct,
                cpgProduct: cpgProduct && cpgProduct.product,
                size: cpgProduct && cpgProduct.size,
                title: (cpgProduct && cpgProduct.title) || "no name"
              };
            })
            .catch(error => {
              recordError("682fa749-49dd-455a-8358-a659b55589c9", error);
              return null;
            });
        } else {
          recordError("5f2e0739-a846-471b-a4f4-97394a0edf8a", closetProduct);
          return null;
        }
      })
      : null;
  }, [items, client]);

  const productDetailsItems = useAsync<
    (ProductDetail<UpcProduct> | null)[] | null
  >(() => {
    if (paired) {
      return Promise.all(paired);
    } else {
      return new Promise<null>((resolve, _reject) => {
        return resolve(null);
      });
    }
  }, [items && items.length]);


  if (upcProducts.loading) {
    return { loading: upcProducts.loading };
  } else {
    return productDetailsItems;
  }
}