import {
  makeRemoteExecutableSchema,
  mergeSchemas,
  introspectSchema
} from "graphql-tools";
import { HttpLink } from "apollo-link-http";
import aws_config from "../../aws-exports";
import { setContext } from "apollo-link-context";

export const mergeAllAPIEndpoints = async (
  getAccessToken: () => Promise<string>,
  cpgEndpoint: string
) => {
  const accessToken = await getAccessToken();
  
  const compclosetLink = setContext((_request, _previousContext) => ({
    headers: {
      Authorization: accessToken
    }
  })).concat(
    new HttpLink({ uri: aws_config.aws_appsync_graphqlEndpoint, fetch })
  );
  const compclosetSchema = makeRemoteExecutableSchema({
    link: compclosetLink,
    schema: await introspectSchema(compclosetLink)
  });

  const cpgLink = setContext((_request, _previousContext) => ({
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })).concat(new HttpLink({ uri: cpgEndpoint, fetch }));
  const cpgSchema = makeRemoteExecutableSchema({
    link: cpgLink,
    schema: await introspectSchema(cpgLink)
  });

  return mergeSchemas({
    schemas: [cpgSchema, compclosetSchema]
  });
};
