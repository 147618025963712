import "./ClosetPage.css";
import "@rmwc/circular-progress/circular-progress.css";
//
import { ClosetDrawer } from "./components/ClosetDrawer";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { ClosetFabs } from "./components/ClosetFabs";
import React from "react";
import SwooshCims from "../../icons/SwooshCims";
import { Redirect } from "react-router-dom";
import { CpgProducts } from "../shared/components/CpgProducts";
import { Snackbar, SnackbarAction } from "../../rmwc/snackbar";
import { PageLayout } from "../shared/pageLayout/PageLayout";
import { Typography } from "@rmwc/typography";
import { ProductDetailsLoading } from "../../apis/cims/hooks/Closet";
import { UseOktaUser } from "../../store/useOktaUser";
import { ProductDetailsSummaryLoading } from "../../apis/cims/hooks/UpcProduct";
import { AlterationState } from "./functions/alterationState";

type ClosetPageViewPropsType = {
  alterationStateAdd: AlterationState;
  closet: ProductDetailsLoading["closet"];
  closetProductDetails: any;
  closetProductsSummarized: ProductDetailsSummaryLoading;
  drawerOpen: boolean;
  hasAcceptedEula: boolean;
  canSeeProducts: boolean;
  isClosetDetailsLoading: boolean;
  isShowSuccessSnackbar: boolean;
  isClosetEmptyOrNoCloset: boolean;
  oktaUser: UseOktaUser;
  setDrawerOpen: (o: boolean) => void;
  setIsShowSuccessSnackbar: (o: boolean) => void;
  userEmail?: string | null;
};

ClosetPageView.displayName = "ClosetPageView";
export function ClosetPageView(props: ClosetPageViewPropsType) {
  const {
    alterationStateAdd,
    closet,
    drawerOpen,
    hasAcceptedEula,
    isShowSuccessSnackbar,
    oktaUser,
    setDrawerOpen,
    setIsShowSuccessSnackbar,
    userEmail,
    canSeeProducts,
    isClosetEmptyOrNoCloset,
    isClosetDetailsLoading,
    closetProductsSummarized,
  } = props;
  const [isFixedHeader, setIsFixedHeader] = React.useState<boolean>(false);
  const scrollableContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const containerElem = scrollableContainerRef.current;

    if (containerElem) {
      const handleScroll = () => {
        const isFixed = containerElem.scrollTop > 110.733;
        setIsFixedHeader(isFixed);
      };
      containerElem.addEventListener("scroll", handleScroll);
      return () => {
        containerElem.removeEventListener("scroll", handleScroll);
      };
    }
    return undefined;
  }, []);

  const closetId = closet.data?.id || null
  const pageToolbarElement = (
    <>
      <ClosetDrawer {...{ closetId, drawerOpen, setDrawerOpen }} />
      <TopAppBarShared
        tag="MenuOut"
        title="Closet"
        onClick={() => {
          hasAcceptedEula ? setDrawerOpen(true) : setDrawerOpen(false);
        }}
      />
    </>
  );
  const elementBottom = (
    <div style={{ height: 72 }}>
      {hasAcceptedEula && <ClosetFabs {...{ isClosetEmptyOrNoCloset, isClosetDetailsLoading, closetId }} />}
    </div>
  );

  if (canSeeProducts) {
    if (!hasAcceptedEula) {
      return (
        <Redirect
          to={{
            pathname: "/accept-eula"
          }}
        />
      );
    }

    return (
      <PageLayout>
        {pageToolbarElement}
        <ClosetPageViewLayout
          contentTypography={null}
          contentProducts={
            <CpgProducts
              tag="ClosetDetails"
              oktaUser={oktaUser}
              productDetailsSummarized={closetProductsSummarized}
              closet={closet}
              alterationStateAdd={alterationStateAdd}
              isFixedHeader={isFixedHeader}
            />
          }
          contentFabs={elementBottom}
          scrollableContainerRef={scrollableContainerRef}
        />
        <Snackbar
          open={isShowSuccessSnackbar}
          onClose={() => {
            setIsShowSuccessSnackbar(false);
          }}
          message="Successful submission"
          action={<SnackbarAction label="Ok" />}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {pageToolbarElement}
      <ClosetPageViewLayout
        contentTypography={<Typography use="headline5">{userEmail}</Typography>}
        contentProducts={null}
        contentFabs={elementBottom}
        scrollableContainerRef={scrollableContainerRef}
      />
    </PageLayout>
  );
}

type ClosetPageViewLayoutPropsType = {
  contentProducts: React.ReactNode;
  contentFabs: React.ReactNode;
  contentTypography: React.ReactNode;
  scrollableContainerRef: React.MutableRefObject<any>;
};
function ClosetPageViewLayout(props: ClosetPageViewLayoutPropsType) {
  const {
    contentProducts,
    contentFabs,
    contentTypography,
    scrollableContainerRef
  } = props;

  return (
    <div
      className="heightMinusTopAppBar"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        ref={scrollableContainerRef}
        style={{ flexBasis: 0, flexGrow: 1, height: 0, overflow: "auto" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 16
          }}
        >
          <SwooshCims
            style={{ width: 100, paddingLeft: 10, paddingBottom: 5 }}
          />
          {contentTypography}
        </div>
        {contentProducts}
      </div>
      {contentFabs}
    </div>
  );
}
