import { UseOktaUser, useOktaUserStore } from "../../../store/useOktaUser";
import { MAX_AWS_PARSABLE_DATE } from "../../../utils/constants";
import {
  PendingAlterationDocument,
  PendingAlterationQueryVariables,
  AlterationPolarity,
  usePendingAlterationQuery,
  Maybe,
  Alteration
} from "../generated/graphql-cims";
import { DocumentNode } from "graphql/language/ast";

export interface PendingAlterationRefetchQuery {
  loading: boolean;
  value: {
    query: DocumentNode;
    variables: PendingAlterationQueryVariables;
  };
}

export const pendingAlterationRefetchQuery = (
  oktaUser: UseOktaUser,
  activeClosetId: string | null,
  polarity: AlterationPolarity
) => {
  const email = (oktaUser.value && oktaUser.value.email) || "";
  return {
    query: PendingAlterationDocument,
    variables: {
      userEmail: email,
      alterationClosetId: activeClosetId || "",
      polarity,
      createdAt: MAX_AWS_PARSABLE_DATE
    }
  };
};

export const usePendingAlterationRefetchQuery = (
  oktaUser: UseOktaUser,
  activeClosetId: string | null,
  polarity: AlterationPolarity
): PendingAlterationRefetchQuery => {
  const email = (oktaUser.value && oktaUser.value.email) || "";
  return {
    loading: oktaUser.loading || email === "" || activeClosetId === null,
    value: pendingAlterationRefetchQuery(oktaUser, activeClosetId, polarity)
  };
};

export type AlterationLoading = {
  data: Maybe<
    Pick<Alteration, "id" | "alterationClosetId" | "polarity" | "createdAt">
  >;
  loading: boolean;
};
export const usePendingAlteration = (
  activeClosetId: string | null,
  polarity: AlterationPolarity
): AlterationLoading => {
  const [oktaUser] = useOktaUserStore();

  // get pending scanning session (alteration) for okta user
  const pendingAlterationRefetchQuery = usePendingAlterationRefetchQuery(
    oktaUser,
    activeClosetId,
    polarity
  );
  const pendingAlterationResult = usePendingAlterationQuery({
    variables: pendingAlterationRefetchQuery.value.variables,
    skip: pendingAlterationRefetchQuery.loading
  });
  const alteration =
    (pendingAlterationResult &&
      pendingAlterationResult.data &&
      pendingAlterationResult.data.listPendingAlteration &&
      pendingAlterationResult.data.listPendingAlteration.items &&
      pendingAlterationResult.data.listPendingAlteration.items.find(
        x => !!x
      )) ||
    null;
  return {
    data: alteration,
    loading:
      (activeClosetId && pendingAlterationRefetchQuery.loading) ||
      pendingAlterationResult.loading
  };
};
