import React from "react";
import { Typography as Typo } from "@rmwc/typography";
import { TypographyT } from "@rmwc/typography";
import "@material/typography/dist/mdc.typography.css";

export function Typography(props: {
  use: TypographyT | "body3";
  children: React.ReactNode;
}) {
  switch (props.use) {
    case "body3":
      return <span className={"mdc-typography--body3"}>{props.children}</span>;
    default:
      return <Typo use={props.use}>{props.children}</Typo>;
  }
}
