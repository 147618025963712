const BREAKPOINT_WHEN_PL_SCROLLABLE_PX = 600;
const TOP_BOTTOM_INDENT = 100;

function getScrollableContainer(): HTMLElement {
  const breakPointH = window.innerHeight;
  if (breakPointH < BREAKPOINT_WHEN_PL_SCROLLABLE_PX) {
    return document.getElementsByClassName("c-page-layout")[0] as HTMLElement;
  }
  return document.getElementsByClassName("rmwc-data-table")[0] as HTMLElement;
}

function getRelativeOffset(elem: HTMLElement, parentElem: HTMLElement) {
  let offset: number = 0;
  let curr: HTMLElement | null = elem;
  while (curr && curr !== parentElem) {
    offset += curr.offsetTop;
    curr = curr.offsetParent as HTMLElement;
  }
  return offset;
}

/**
 * Scrolls into view, tries to position in the middle of scrollable container.
 * @param elem
 */
export function scrollIntoView(elem: HTMLElement) {
  const scrollableContainer = getScrollableContainer();
  if (scrollableContainer) {
    const elemHeight = elem.offsetHeight;
    const elemOffsetTop = getRelativeOffset(elem, scrollableContainer);
    const containerHeight = scrollableContainer.offsetHeight;

    const isBottomBorderBelowViewPort =
      elemOffsetTop +
        elemHeight +
        TOP_BOTTOM_INDENT -
        containerHeight -
        scrollableContainer.scrollTop >
      0;
    const isTopBorderAboveViewPort =
      elemOffsetTop - scrollableContainer.scrollTop - TOP_BOTTOM_INDENT < 0;
    const isElementFullyVisible =
      !isBottomBorderBelowViewPort && !isTopBorderAboveViewPort;

    if (!isElementFullyVisible) {
      const idealScrollTop =
        elemOffsetTop + elemHeight / 2 - containerHeight / 2;
      const to = idealScrollTop > 0 ? idealScrollTop : 0;
      const from = scrollableContainer.scrollTop;
      applyScrollTopSmooth(scrollableContainer, from, to);
    }
  } else {
    console.warn("Unable to find scrollable container");
  }
}

function applyScrollTopSmooth(elem: HTMLElement, from: number, to: number) {
  let current = from;

  const doStep = () => {
    if (from > to) {
      current = current - 20;
      current = current < to ? to : current;
    } else {
      current = current + 20;
      current = current > to ? to : current;
    }
  };

  const proceed = () => {
    if (to !== current) {
      doStep();
      elem.scrollTop = current;
      requestAnimationFrame(proceed);
    }
  };

  proceed();
}
