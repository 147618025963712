import { MutationFn } from "../../../types/apollo";
import {
  CreateClosetProductBatchMutation,
  CreateClosetProductBatchMutationVariables,
  UpdateAlterationMutationFn,
  AlterationPolarity
} from "../../../apis/cims/generated/graphql-cims";
import { UseOktaUser } from "../../../store/useOktaUser";
import { recordError, recordGuid } from "../../../utils/logging";
import { queue } from "../../../utils/rmwcDialogQueue";
import {
  graphqlErrorAlert,
  networkErrorAlert
} from "../../../utils/errorAlerts";
import { closetProductsRefetchQuery } from "../../../apis/cims/hooks/ClosetProduct";
import {
  pendingAlterationRefetchQuery,
  AlterationLoading
} from "../../../apis/cims/hooks/Alteration";
import { UseAlterationProducts } from "../hooks/useAlterationProducts";

export const onClickCreateClosetProducts = (
  createClosetProductBatchMutation: MutationFn<
    CreateClosetProductBatchMutation,
    CreateClosetProductBatchMutationVariables
  >,
  alteration: AlterationLoading["data"] | null,
  alterationProducts: UseAlterationProducts["alterationProducts"]["data"],
  updateAlterationMutationFn: UpdateAlterationMutationFn,
  activeClosetId: string | null,
  oktaUser: UseOktaUser,
  setSubmitAlterationProductsPending: (t: boolean) => void,
  onStart: () => void,
  onSuccess: () => void
) => {
  const uponFailValue = (uuid: string, error?: any) => {
    if (error) {
      recordError(uuid, error);
    } else {
      recordGuid(uuid);
    }
    setSubmitAlterationProductsPending(false);
    return false;
  };
  const uponSucceedValue = () => {
    onSuccess();
    setSubmitAlterationProductsPending(false);
    return false;
  };
  const uponFailPromise = (uuid: string, error?: any): Promise<boolean> =>
    new Promise<boolean>(resolve => {
      resolve(uponFailValue(uuid));
    });

  return (): Promise<boolean> => {
    setSubmitAlterationProductsPending(true);
    onStart();

    if (alterationProducts.length < 1) {
      queue.alert({
        title: "No items to submit",
        body: "Please scan items first."
      });
      return uponFailPromise("295ad0bd-bd0c-49dc-a555-7bd9bf1e62c0");
    } else {
      if (alteration) {
        if (alterationProducts) {
          if (activeClosetId) {
            return createClosetProductBatchMutation({
              variables: {
                input: {
                  alterationId: alteration.id,
                }
              }
            })
              .then(result => {
                const numOfErrors = result?.data?.createClosetProductBatch?.errors?.length || 0
                if (numOfErrors > 0) {
                  // the alteration was partially submitted.
                  queue.alert({
                    title: "Partial Submission",
                    body: "Partial Submission"
                  });
                  return uponFailPromise(
                    "18f67d34-5c4d-411a-8519-76813cb6b66d"
                  );
                }
                if (result && result.errors) {
                  graphqlErrorAlert(result);
                  return uponFailPromise(
                    "18f67d34-5c4d-411a-8519-76813cb6b66d"
                  );
                } else {
                  if (alteration && alteration.id) {
                    return updateAlterationMutationFn({
                      variables: {
                        id: alteration && alteration.id,
                        pending: false,
                        alterationClosetId:
                          alteration.alterationClosetId ||
                          "no alterationClosetId",
                        userEmail:
                          (oktaUser.value && oktaUser.value.email) ||
                          "no userEmail",
                        polarity: alteration.polarity || AlterationPolarity.Add,
                        createdAt: alteration.createdAt || "no createdAt",
                        submittedAt: new Date().toISOString()
                      },
                      refetchQueries: [
                        closetProductsRefetchQuery(
                          (oktaUser.value && oktaUser.value.email) || ""
                        ),
                        closetProductsRefetchQuery(
                          oktaUser?.value?.email ?? "no email"
                        ),
                        pendingAlterationRefetchQuery(
                          oktaUser,
                          activeClosetId,
                          alteration.polarity || AlterationPolarity.Add
                        )
                      ].flat()
                    })
                      .then((result): boolean => {
                        if (
                          result &&
                          result.data &&
                          result.data.updateAlteration
                        ) {
                          if (result.data.updateAlteration.pending === false) {
                            return uponSucceedValue();
                          } else {
                            queue.alert({
                              title: "Partial Submission",
                              body: "Please resubmit remaining items."
                            });
                            return uponFailValue(
                              "12db5456-230f-4057-9253-59e604068f90"
                            );
                          }
                        } else {
                          return uponFailValue(
                            "590f4514-aedb-4dbc-8ae1-ea0337eab645"
                          );
                        }
                      })
                      .catch(error => {
                        return uponFailPromise(
                          "97ac39fe-2d0a-4217-a6ff-b11e3924b886",
                          error
                        );
                      });
                  } else {
                    return uponFailPromise(
                      "790bb181-6b19-47a7-9037-1de4300a827c",
                      alteration
                    );
                  }
                }
              })
              .catch(error => {
                const guid = "2bd7bfb4-58b1-47d3-9349-a0c71944ba96";
                networkErrorAlert(guid, error);
                return uponFailValue(guid);
              });
          } else {
            return uponFailPromise("bc4831f8-7410-4bb5-99b0-d7d0395af8ac");
          }
        } else {
          return uponFailPromise("af55acae-4561-421e-b2b6-a24db0fec295");
        }
      } else {
        return uponFailPromise("1ac25596-c191-4456-a478-3ea5a4612176");
      }
    }
  };
};
