// @ts-nocheck
import React, { Dispatch, SetStateAction } from "react";
import { useLazyQuery } from '@apollo/react-hooks';
import { TextField } from "../../../rmwc/textfield";
import { CircularProgress } from "@rmwc/circular-progress";
import { Select } from "../../../rmwc/select";
import { Button } from "../../../rmwc/button";
import { CpgProductResult } from "../../../apis/cpg/hooks/ProductPage";
import { useSizesFromStyleColor } from "../functions/sizesFromStyleColor";
import { useFindProductsByStyleColorLazyQuery } from "../../../apis/cpg/generated/graphql-cpg";
import { sortedProductSizesAvailableSelected } from "../functions/sortedProductSizesAvailableSelected";
import { onClickManualEntryStyleColor, GET_BULK_PRODUCTS_GQL } from "../functions/onClickManualEntryStyleColor";
const csv = require('csvtojson')

export function ManualEntry(props: {
  userInputError: string;
  setUserInputError: Dispatch<SetStateAction<string>>;
  isSelectSizeVisible: boolean;
  userInputStyleColor: string;
  setUserInputStyleColor: Dispatch<SetStateAction<string>>;
  sizesInClosetByInputStyleColor: string[] | undefined;
  setIsSelectSizeVisible: Dispatch<SetStateAction<boolean>>;
  tag: "Add" | "Remove";
}) {
  const [userInputSelectedSize, setUserInputSelectedSize] = React.useState("");
  const [bulkProductsCsv, setBulkProductsCsv] = React.useState('');
  const [productsJson, setProductsJson] = React.useState([]);
  const [erroredProducts, setErroredProducts] = React.useState([]);

  const [
    selectedSize,
    setSelectedSize
  ] = React.useState<CpgProductResult | null>(null);

  const [
    findProductsByStyleColorQuery,
    productsFromStyleColor
  ] = useFindProductsByStyleColorLazyQuery();
  const sizes = useSizesFromStyleColor(productsFromStyleColor);
  const productSizesAvailable =
    sizes &&
    sizes.product &&
    sizes.product.sizes?.map(size => {
      return size?.description || "";
    });

  // const products = [
  //   { "productCode": "DA6364-101", "size": "3.5" },
  //   { "productCode": "DA6364-101", "size": "7.5" },
  //   { "productCode": "CW2288-111", "size": "9.5" },
  // ];

  // const csvStr = `
  // "productCode", "size"
  // "DA6364-101", "3.5"
  // "DA6364-101", "7.5"
  // "CW2288-111", "9.5"
  // `;
  // let products: any[] = [];

  // csv().fromString(csvStr).then((r: any) => {
  //   console.log("result", r);
  //   products = r;
  //   return
  // })
  // console.log("Json products", products);

  const [runQuery, { called, loading, data }] = useLazyQuery(GET_BULK_PRODUCTS_GQL);

  return (
    <div
      className="manualEntry"
      style={{
        backgroundColor: "white",
        marginTop: window.screen.width < 500 ? 6 : 30,
        flexBasis: 1,
        flexGrow: props.userInputError ? 2 : 1,
        maxHeight: props.isSelectSizeVisible ? "" : 110,
        position: "relative"
      }}
    >

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15
        }}
      >
        <textarea
          outlined
          textarea
          label="Paste values from excel here..."
          style={{ minWidth: 300, height: 60 }}
          value={bulkProductsCsv}
          position="relative"
          onChange={(event: any) => {
            setBulkProductsCsv(event.target.value);
          }}
        />
        <Button
          raised
          onClick={() => {
            console.log("GetUPC")
            console.log("bulkProductsCsv", bulkProductsCsv)
            csv().fromString(bulkProductsCsv).then((productsJson: any) => {
              console.log("result", productsJson);
              console.log("data from cpg", data)
              setProductsJson(productsJson)
              runQuery({
                variables: { styleColor: productsJson.map(p=>p.productCode)}
              });
              
              
              // setBulkProductsCsv('');
              return
            })
          }}
          label="Submit Styles"
          style={{
            borderRadius: 20,
            fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
            fontSize: "16px",
            color: "#FFFFFF",
            background: "#111111",
            letterSpacing: 0,
            textAlign: "center",
            textTransform: "capitalize",
            marginBottom: "12px"
          }}
        />

        <Button
          raised
          onClick={() => {
            console.log("data after add entries button", data)
            let erroredProducts = [];

            if (data) {
              const allUPC = productsJson.map(p => {
                const thisProduct = data.findProducts.products.find((dp: any) => dp.productCode === p.productCode)
                console.log(thisProduct)
                if (thisProduct) {
                  const thisSize = thisProduct.sizes.find((dp: any) => dp.description == p.size)
                  console.log({ p: p.productCode, description: thisSize.description, upc: thisSize.upc })
                  return thisSize.upc
                } else {
                  erroredProducts.push(p.productCode)
                }
                return ''
              })

              const filteredUPC = allUPC.filter(x => x !== '')

              setErroredProducts(erroredProducts);

              let upc = filteredUPC.shift()
              window.dispatchEvent(
                new CustomEvent("ManualEntryUpc", { detail: upc }))
              localStorage.setItem('my-key', JSON.stringify(filteredUPC));
              // filteredUPC.map(upc => {
              //   console.log("Trigger event for upc: ", upc)
              //   window.dispatchEvent(
              //     new CustomEvent("ManualEntryUpc", { detail: upc }))
              //   return;
              // });
            }

          }}
          label="Add entries below"
          style={{
            borderRadius: 20,
            fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
            fontSize: "16px",
            color: "#FFFFFF",
            background: "#111111",
            letterSpacing: 0,
            textAlign: "center",
            textTransform: "capitalize",
            marginBottom: "12px"
          }}
        />

        {/* <textarea
        label="Enter CSV"
        style={{ minWidth: 270, height: 46 }}
        value={bulkProductsCsv}
        position="relative"
        onChange={(event: any) => {
          setBulkProductsCsv(event.target.value);
        }}

      /> */}


      </div>
      {erroredProducts.length > 0 && <ManualEntryErrorText userInputError={`Multiple Style-Color not recognized: ${erroredProducts}`} />}
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15
        }}
      >
        <TextField
          outlined
          data-testid="manual-entry-text-input"
          label="Enter Product Style-Color"
          style={{ minWidth: 270, height: 46 }}
          invalid={
            (productsFromStyleColor?.data?.findProducts?.products &&
              productsFromStyleColor.data.findProducts.products.length === 0) ||
              props.userInputError
              ? true
              : false
          }
          value={props.userInputStyleColor}
          onChange={(event: any) => {
            props.setUserInputStyleColor(event.target.value.toUpperCase());
          }}
          onKeyPress={(event: any) => {
            props.userInputError !== "" && props.setUserInputError("");
            event.key === "Enter" &&
              onClickManualEntryStyleColor(
                props.userInputStyleColor.toUpperCase(),
                props.setUserInputError,
                findProductsByStyleColorQuery,
                props.setUserInputStyleColor,
                props.setIsSelectSizeVisible
              );
          }}
          trailingIcon={{
            icon: productsFromStyleColor.loading ? (
              <CircularProgress />
            ) : (
              "add_circle"
            ),
            tabIndex: 0,
            onClick: () =>
              onClickManualEntryStyleColor(
                props.userInputStyleColor.toUpperCase(),
                props.setUserInputError,
                findProductsByStyleColorQuery,
                props.setUserInputStyleColor,
                props.setIsSelectSizeVisible
              )
          }}
        />

        {productsFromStyleColor?.data?.findProducts?.products &&
          productsFromStyleColor.data.findProducts.products.length === 0 ? (
          <ManualEntryErrorText userInputError="Style-Color not recognized" />
        ) : (
          props.userInputError && (
            <ManualEntryErrorText userInputError={props.userInputError} />
          )
        )}
      </div>

      {sizes?.product?.productCode && (
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        ></div>
      )}

      {props.isSelectSizeVisible &&
        productSizesAvailable &&
        !productsFromStyleColor.error &&
        sizes?.product?.sizes && (
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 10
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: 150,
                height: 42,
                paddingBottom: 15,
                float: "left",
                paddingRight: 15
              }}
            >
              <Select
                label="Select Size"
                defaultValue=""
                className="manualEntrySelect"
                outlined
                onChange={(event: any) => {
                  const foundSize = sizes?.product?.sizes?.find(
                    (size: any) => size?.description === event.target.value
                  );
                  setSelectedSize({
                    title: sizes.title,
                    product: sizes.product,
                    size: foundSize ?? null
                  });
                  setUserInputSelectedSize(event.target.value);
                }}
                options={(() => {
                  switch (props.tag) {
                    case "Add":
                      return sortedProductSizesAvailableSelected(
                        productSizesAvailable
                      )?.map((size: any) => {
                        return size || "";
                      });
                    case "Remove":
                      return sortedProductSizesAvailableSelected(
                        props.sizesInClosetByInputStyleColor
                      )?.map((size: any) => {
                        return size || "";
                      });
                  }
                })()}
                value={userInputSelectedSize}
                style={{ borderRadius: 0 }}
              />
            </div>

            <Button
              raised
              disabled={userInputSelectedSize === "" ? true : false}
              onClick={() => {
                if (
                  userInputSelectedSize.trim() === "" &&
                  props.userInputStyleColor.trim() === ""
                ) {
                  return props.setUserInputError("Enter Product Code and Size");
                } else if (userInputSelectedSize.trim() === "") {
                  return null;
                }
                window.dispatchEvent(
                  new CustomEvent("ManualEntryUpc", {
                    detail: selectedSize?.size?.upc
                  })
                );
                setUserInputSelectedSize("");
                props.setIsSelectSizeVisible(false);
                props.setUserInputStyleColor("");
                props.setUserInputError("");
              }}
              label="Add Size"
              style={{
                borderRadius: 20,
                fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                background: "#111111",
                letterSpacing: 0,
                textAlign: "center",
                textTransform: "capitalize",
                marginBottom: "12px"
              }}
            />
          </div>
        )}
    </div>
  );
}

export const ManualEntryErrorText = (props: { userInputError: string }) => {
  return (
    <div style={{ padding: 10, color: "#FE0000" }}>{props.userInputError}</div>
  );
};
