import { useEffect } from "react";
import Quagga from "quagga";
import { cpgProductByUpc } from "../../../../apis/cpg/hooks/ProductPage";
import { useApolloClient } from "@apollo/react-hooks";
import { queue } from "../../../../utils/rmwcDialogQueue";

const ManualEntry = {
  onDetected: (func: any) => {
    window.addEventListener("ManualEntryUpc", func, false);
  },
  offDetected: (func: any) => {
    window.removeEventListener("ManualEntryUpc", func, false);
  }
};

type QuaggaResultType = {
  codeResult?: { code: string };
  detail?: string;
};

function parseQuaggaResult(quaggaResult?: QuaggaResultType) {
  if (quaggaResult) {
    const code = quaggaResult.codeResult?.code;
    if (code) {
      return `00000000000000${code}`.substr(-14);
    }
    /**
     * Use any "UPC-A" barcode generator (e.g. https://www.free-barcode-generator.net/upc-a/).
     * Pass UPC without leading zeros to it.
     * E.g. if UPC=00193151522641, then generate the barcode using 193151522641
     */
    return quaggaResult.detail;
  }
  return undefined;
}

const productNotInApi = () => {
  queue.alert({
    title: "Product Not Found",
    body: "Please try again."
  });
};

export function useOnUpcDetected(
  onUpcDetected: (upc: string, productCode: string) => void,
  setLoading: (i: boolean) => void
) {
  const client = useApolloClient();

  useEffect(() => {
    const handleDetected = async (quaggaResult: any) => {
      const upc = parseQuaggaResult(quaggaResult);
      if (upc) {
        try {
          console.log("listener", upc)
          setLoading(true)

          const cpgResult = await cpgProductByUpc(client, upc);
          const productCode = cpgResult?.product?.productCode;
          if (productCode) {
            onUpcDetected(upc, productCode);
            // console.log("CPGResult", cpgResult, productCode, upc)
          } else {
            setLoading(false)
            productNotInApi();
          }
        } catch (err) {
          setLoading(false)
          productNotInApi();
        }
      }
    };

    Quagga.onDetected(handleDetected);
    ManualEntry.onDetected(handleDetected);
    return () => {
      Quagga.offDetected(handleDetected);
      ManualEntry.offDetected(handleDetected);
    };
  }, [client, onUpcDetected, setLoading]);
}
