import React, { useState, MutableRefObject, useEffect } from "react";
import { CircularProgress } from "@rmwc/circular-progress";
import { UseOktaUser } from "../../../store/useOktaUser";
import { EmptyStatePage } from "./EmptyStatePage";
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableBody,
  DataTableCell
} from "../../../rmwc/data-table";
import { CpgProduct } from "./CpgProduct";
import { CpgProductRow } from "../../Closet/components/CpgProductRow";
import { AlterationState } from "../../Closet/functions/alterationState";
import {
  ProductDetailsSummary,
  UpcProduct
} from "../../../apis/cims/hooks/UpcProduct";
import { Maybe, Closet } from "../../../apis/cims/generated/graphql-cims";
import SvgEquipment from "../../../icons/Equipment";
import "./CpgProducts.css";

interface DetailsProps {
  oktaUser: UseOktaUser;
  productDetailsSummarized: {
    value: ProductDetailsSummary<UpcProduct>;
    error: Error | undefined;
    loading: boolean;
  };
}

type AlterationDetailsIncomingProps = {
  tag: "AlterationDetailsIncoming";
} & DetailsProps;

type AlterationDetailsOutgoingProps = {
  tag: "AlterationDetailsOutgoing";
} & DetailsProps;

type AlterationDetailsIncomingRejectedProps = {
  tag: "AlterationDetailsIncomingRejected";
} & DetailsProps;

type AlterationDetailsOutgoingRejectedProps = {
  tag: "AlterationDetailsOutgoingRejected";
} & DetailsProps;

type ClosetDetailsProps = {
  tag: "ClosetDetails";
  closet: {
    loading: boolean;
    data: Maybe<
      {
        __typename?: "Closet" | undefined;
      } & {
        __typename?: "Closet" | undefined;
      } & Pick<Closet, "id" | "userEmail">
    >;
  };
  alterationStateAdd: AlterationState;
  isFixedHeader: boolean;
} & DetailsProps;

type AddProps = {
  tag: "Add";
  isFixedHeader: false;
} & DetailsProps;

type RemoveProps = {
  tag: "Remove";
  isFixedHeader: false;
} & DetailsProps;

function calculatedProps(
  props:
    | AlterationDetailsIncomingProps
    | AlterationDetailsOutgoingProps
    | AlterationDetailsIncomingRejectedProps
    | AlterationDetailsOutgoingRejectedProps
    | ClosetDetailsProps
    | AddProps
    | RemoveProps,
  checkboxHeadCellRef: MutableRefObject<HTMLTableHeaderCellElement | null>
) {
  switch (props.tag) {
    case "AlterationDetailsIncoming":
      return {
        checkboxHeadCell: null,
        noResults: (
          <EmptyStatePage
            tag="Text"
            headline="Transfer empty"
            body={<span>Press the top left back button to exit.</span>}
          />
        ),
        checkboxDataTableCell: null
      };
    case "AlterationDetailsOutgoing":
      return {
        checkboxHeadCell: null,
        noResults: (
          <EmptyStatePage
            tag="Text"
            headline="Transfer empty"
            body={<span>Press the top left back button to exit.</span>}
          />
        ),
        checkboxDataTableCell: null
      };
    case "AlterationDetailsIncomingRejected":
      return {
        checkboxHeadCell: null,
        noResults: (
          <EmptyStatePage
            tag="Text"
            headline="Transfer empty"
            body={<span>Press the top left back button to exit.</span>}
          />
        ),
        checkboxDataTableCell: null
      };
    case "AlterationDetailsOutgoingRejected":
      return {
        checkboxHeadCell: null,
        noResults: (
          <EmptyStatePage
            tag="Text"
            headline="Transfer empty"
            body={<span>Press the top left back button to exit.</span>}
          />
        ),
        checkboxDataTableCell: null
      };
    case "ClosetDetails":
      return {
        checkboxHeadCell: (
          <div
            className="rmwc-data-table__cell rmwc-data-table__head-cell"
            style={{ width: checkboxHeadCellRef.current?.clientWidth }}
          />
        ),
        noResults: (
          <>
            <div id="ClosetEmpty" />
            <EmptyStatePage
              tag="Svg"
              headline={
                <SvgEquipment
                  fill="black"
                />
              }
              body={"Your Closet is empty"}
            />
          </>
        ),
        checkboxDataTableCell: <DataTableCell />
      };
    case "Add":
      return {
        checkboxHeadCell: null,
        noResults: null
      };
    case "Remove":
      return {
        checkboxHeadCell: null,
        noResults: null
      };
  }
}

export const CpgProducts = (
  props:
    | AlterationDetailsIncomingProps
    | AlterationDetailsOutgoingProps
    | AlterationDetailsIncomingRejectedProps
    | AlterationDetailsOutgoingRejectedProps
    | ClosetDetailsProps
    | AddProps
    | RemoveProps
) => {
  const isLoading = props.productDetailsSummarized.loading;
  const checkboxHeadCellRef = React.useRef<HTMLTableHeaderCellElement | null>(
    null
  );
  const productNameHeadCellRef = React.useRef<HTMLTableHeaderCellElement | null>(
    null
  );
  const sizeHeadCellRef = React.useRef<HTMLTableHeaderCellElement | null>(null);
  const unitsHeadCellRef = React.useRef<HTMLTableHeaderCellElement | null>(
    null
  );

  const propsFromTag = calculatedProps(props, checkboxHeadCellRef);

  const isMenuSortingOn = false; // Switch to true for clickable Product Name sort. There are 2 headers now and only one is set up for sorting. Remember to implement sorting on the redundant table header.

  const productNameSortDirectionDefault = (props?.tag === "Add" || props?.tag === "Remove") ? "dscDate" : "ascProductName";

  const [productNameSortDirection, setProductNameSortDirection] = useState<
    "ascProductName" | "dscProductName" | "dscDate"
  >(productNameSortDirectionDefault);

  const isEmpty =
    props.productDetailsSummarized.loading === false &&
    props.productDetailsSummarized.value &&
    props.productDetailsSummarized.value.total === 0;

  useEffect(() => {
    const stringifiedfilteredUPC = localStorage.getItem('my-key');
    if (props.productDetailsSummarized.value.total === 0 || !stringifiedfilteredUPC || JSON.parse(stringifiedfilteredUPC).length === 0) {
      return;
    }
    const filteredUPC = JSON.parse(stringifiedfilteredUPC);
    const upc = filteredUPC.shift()
    localStorage.setItem('my-key', JSON.stringify(filteredUPC));
    window.dispatchEvent(
      new CustomEvent("ManualEntryUpc", { detail: upc }))
  }, [props.productDetailsSummarized.value.total]);

  const loader = (
    <CircularProgress
      size="large"
      style={{
        position: "absolute",
        left: "50%",
        marginLeft: -10, // guessed
        top: "50%",
        marginTop: 10 // guessed
      }}
    />
  );

  let itemProductCodeName: { [key: string]: string | undefined } = {};

  props?.productDetailsSummarized?.value?.productDetailsByCode.forEach(
    (item, k) => {
      const itemProductCode = item.code;
      return item?.productDetailsBySize.forEach((item, k) => {
        let itemName = item?.productDetails[k]?.title;
        //The sort seems to be thrown off when this is "undefined"
        if (!itemName) {
          //In every instance that this is the case, this alternative method of grabbing the title seems to work.
          itemName = item?.productDetails[0]?.title;
        }
        if (itemProductCode !== undefined) {
          itemProductCodeName[itemProductCode] = itemName;
        }
      });
    }
  );

  const sortedByProductTitle = Object.entries(itemProductCodeName)
    .sort(function (a: (string | undefined)[], b: (string | undefined)[]) {
      let itemOne = !a[1] ? "undefined" : a[1];
      let itemTwo = !b[1] ? "undefined" : b[1];
      return itemOne?.localeCompare(itemTwo || "") || 0;
    })
    ?.map(item => {
      return item[0];
    });

  const sortProductNameAlgo = (sortDirection: "ascProductName" | "dscProductName" | "dscDate") => {
    if (sortDirection === "dscDate") {
      return props?.productDetailsSummarized?.value?.productDetailsByCode.reverse();
    } else {
      return props?.productDetailsSummarized?.value?.productDetailsByCode.sort(function (
        a,
        b
      ) {
        switch (sortDirection) {
          case "dscProductName":
            return (
              sortedByProductTitle.indexOf(b.code) -
              sortedByProductTitle.indexOf(a.code)
            );
          case "ascProductName":
            return (
              sortedByProductTitle.indexOf(a.code) -
              sortedByProductTitle.indexOf(b.code)
            );
        }
      });
    }
  }

  const handleSortProductNameClick = (sortDirection: "dscProductName" | "ascProductName" | "dscDate") => {
    return (
      sortProductNameAlgo(sortDirection) &&
      setProductNameSortDirection(sortDirection)
    );
  };

  if (props.oktaUser.loading === false && props.oktaUser.value === null) {
    return (
      <div className="heightMinusTopAppBar">
        <EmptyStatePage
          tag="Text"
          headline="Oops!"
          body="Something went wrong. Please refresh the page."
        />
      </div>
    );
  } else if (props.productDetailsSummarized.loading) {
    return loader;
  } else if (isEmpty) {
    return (
      <div
        style={{
          height: "80%"
        }}
      >
        {propsFromTag.noResults}
      </div>
    );
  } else {
    return props.productDetailsSummarized ? (
      <div className="tableWrap" style={{}}>
        {(() => {
          switch (props.tag) {
            case "ClosetDetails":
            case "Add":
            case "Remove":
              const totalUnits = props.productDetailsSummarized.value.total;
              return (
                <div
                  className={props.isFixedHeader ? "closet-total-sticky" : ""}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: 12,
                      paddingBottom: 6,
                      paddingTop: 6
                    }}
                  >
                    <b>{totalUnits} Units</b>
                  </div>
                </div>
              );
            case "AlterationDetailsIncoming":
            case "AlterationDetailsIncomingRejected":
            case "AlterationDetailsOutgoing":
            case "AlterationDetailsOutgoingRejected":
              return null;
          }
        })()}
        <div style={{ flexBasis: 1, flexGrow: 1 }}>
          <div
            style={{
              border: "none",
              display: (() => {
                switch (props.tag) {
                  case "ClosetDetails":
                    return props.isFixedHeader ? "flex" : "none";
                  default:
                    return "none";
                }
              })()
            }}
            className={`rmwc-data-table__head${(() => {
              switch (props.tag) {
                case "ClosetDetails":
                  return props.isFixedHeader
                    ? " closet-table-headers-sticky"
                    : "";
                default:
                  return "";
              }
            })()}`}
          >
            {propsFromTag.checkboxHeadCell}
            <div
              style={{
                width: productNameHeadCellRef.current?.clientWidth,
                textAlign: "left"
              }}
              className="rmwc-data-table__cell rmwc-data-table__head-cell"
            >
              {!isMenuSortingOn ? (
                "Product Name1"
              ) : (
                <span
                  onClick={() => {
                    productNameSortDirection === "ascProductName"
                      ? handleSortProductNameClick("dscProductName")
                      : handleSortProductNameClick("ascProductName");
                  }}
                  style={{ cursor: `pointer` }}
                >
                  Product Name1{" "}
                  {productNameSortDirection === "ascProductName"
                    ? String.fromCharCode(9650)
                    : productNameSortDirection === "dscProductName" &&
                    String.fromCharCode(9660)}
                </span>
              )}
            </div>
            <div
              style={{
                width: sizeHeadCellRef.current?.clientWidth,
                textAlign: "left"
              }}
              className="rmwc-data-table__cell rmwc-data-table__head-cell"
            >
              Size
            </div>
            <div
              style={{
                width: unitsHeadCellRef.current?.clientWidth,
                textAlign: "right"
              }}
              className="rmwc-data-table__cell rmwc-data-table__head-cell"
            >
              Units
            </div>
          </div>
          <DataTable
            className={(() => {
              switch (props.tag) {
                case "ClosetDetails":
                  return props.isFixedHeader ? "closet-table-sticky" : "";
                default:
                  return "";
              }
            })()}
            stickyRows={(() => {
              switch (props.tag) {
                case "ClosetDetails":
                  return 0;
                case "AlterationDetailsIncoming":
                case "AlterationDetailsIncomingRejected":
                case "AlterationDetailsOutgoing":
                case "AlterationDetailsOutgoingRejected":
                case "Add":
                case "Remove":
                  return 1;
              }
            })()}
            style={{
              width: "100%",
              fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
              fontSize: "14px"
            }}
          >
            <DataTableContent style={{}}>
              <DataTableHead>
                <DataTableRow>
                  {(() => {
                    if (props.tag === "ClosetDetails") {
                      return (
                        <th
                          ref={checkboxHeadCellRef}
                          className="checkboxHeadCell rmwc-data-table__cell rmwc-data-table__head-cell rmwc-data-table__cell rmwc-data-table__head-cell"
                        ></th>
                      );
                    } else {
                      return null;
                    }
                  })()}
                  <th
                    ref={productNameHeadCellRef}
                    className="productNameHeadCell rmwc-data-table__cell rmwc-data-table__head-cell rmwc-data-table__cell rmwc-data-table__head-cell"
                  >
                    Product Name
                  </th>
                  <th
                    ref={sizeHeadCellRef}
                    className="sizeHeadCell rmwc-data-table__cell rmwc-data-table__head-cell rmwc-data-table__cell rmwc-data-table__head-cell"
                  >
                    Size
                  </th>
                  <th
                    ref={unitsHeadCellRef}
                    className="unitsHeadCell rmwc-data-table__cell rmwc-data-table__head-cell rmwc-data-table__cell rmwc-data-table__head-cell"
                  >
                    Units
                  </th>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody
                style={{
                  minWidth: "100%"
                }}
              >
                {sortProductNameAlgo(productNameSortDirection).flatMap(
                  byCode => {
                    return [
                      ...byCode.productDetailsBySize.map(bySize => {
                        return (() => {
                          const totalAmountOfProductsInAlteration = props.productDetailsSummarized.value.total

                          switch (props.tag) {
                            case "Add":
                              return (
                                <CpgProduct
                                  tag="Add"
                                  key={`${byCode.code}|${bySize.size}`}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                  totalAmountOfProductsInAlteration={totalAmountOfProductsInAlteration}
                                />
                              );
                            case "Remove":
                              return (
                                <CpgProduct
                                  tag="Remove"
                                  key={`${byCode.code}|${bySize.size}`}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                  totalAmountOfProductsInAlteration={totalAmountOfProductsInAlteration}
                                />
                              );
                            case "AlterationDetailsIncoming":
                              return (
                                <CpgProduct
                                  tag="AlterationDetailIncoming"
                                  key={`${byCode.code}|${bySize.size}`}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                />
                              );
                            case "AlterationDetailsIncomingRejected":
                              return (
                                <CpgProduct
                                  tag="AlterationDetailIncomingRejected"
                                  key={`${byCode.code}|${bySize.size}`}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                />
                              );
                            case "AlterationDetailsOutgoing":
                              return (
                                <CpgProduct
                                  tag="AlterationDetailOutgoing"
                                  key={`${byCode.code}|${bySize.size}`}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                />
                              );
                            case "AlterationDetailsOutgoingRejected":
                              return (
                                <CpgProduct
                                  tag="AlterationDetailIncomingRejected"
                                  key={`${byCode.code}|${bySize.size}`}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                />
                              );
                            case "ClosetDetails":
                              return (
                                <CpgProductRow
                                  key={`${byCode.code}|${bySize.size}`}
                                  closetId={
                                    (props.closet.data &&
                                      props.closet.data.id) ||
                                    ""
                                  }
                                  alterationStateAdd={props.alterationStateAdd}
                                  isProductDetailsSummarizedLoading={isLoading}
                                  codeValue={byCode}
                                  sizeValue={bySize}
                                />
                              );
                          }
                        })();
                      })
                    ];
                  }
                )}
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        </div>
      </div>
    ) : (
      <CircularProgress
        size="large"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: -10, // guessed
          top: "50%",
          marginTop: 10 // guessed
        }}
      />
    );
  }
};
