import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Security } from "@okta/okta-react";
import Amplify from "aws-amplify";
import aws_config from "./aws-exports";
//TECH DEBT: Alias with custom code and then styles and component will be imported together.
import "@rmwc/circular-progress/circular-progress.css";
import { getEnvironmentByHostname } from "./utils/getEnvironmentByHostname";
import { Routes } from "./routes/Routes";

Amplify.configure(aws_config);

// in the future, an environment variable set equal to these values would be preferable, not checking the api endpoint dynamically
export const env = getEnvironmentByHostname();

// seem to be official nike colors
export const themeProviderOptions = {
  primary: "#222",
  //secondary: "#fa5400",
  secondary: "#111111",
  textSecondaryOnBackground: "#8D8D8D"
};

/**
 * Represents entire React app and is mounted directly to HTML element with id equal to root.
 * Provides browser router.
 * Provides Okta integration.
 */
export default () => {
  return (
    <BrowserRouter>
      <Security
        issuer={env.oidc.issuer}
        client_id={env.oidc.clientId}
        redirect_uri={env.oidc.redirectUri}
        scopes={env.oidc.scopes}
      >
        <Routes />
      </Security>
    </BrowserRouter>
  );
};
