const record = (input: {
  name: string;
  attributes?: {
    message: string;
  };
}) => {
  // Analytics.record(input);
  if (input.attributes) {
    console.warn(`${input.attributes.message} [${input.name}]`);
  }
};

export const recordGuid = (guid: string) => {
  record({ name: guid });
};

export const recordError = (guid: string, obj: any) => {
  record({
    name: guid,
    attributes: { message: JSON.stringify(obj, null, 2) }
  });
};
