import React, { useState } from "react";
import "@rmwc/circular-progress/circular-progress.css";
import { RouteComponentProps } from "react-router-dom";
import "@material/checkbox/dist/mdc.checkbox.css";
import "@material/form-field/dist/mdc.form-field.css";
import "../AddRemove/AlterationShared.css";
import { DialogTitle, DialogContent } from "../../rmwc/dialog";

import "@material/linear-progress/dist/mdc.linear-progress.css";
import "@material/list/dist/mdc.list.css";
import { Radio } from "../../rmwc/radio";
import { TextField } from "../../rmwc/textfield";
import { EmptyStatePage } from "../shared/components/EmptyStatePage";
import {
  usePurposeType,
  usePurposeNote,
  useActiveClosetId
} from "../../store/useGlobalState";
import { Button } from "@rmwc/button";
import { Typography } from "@rmwc/typography";
import { PurposeType } from "../../apis/cims/generated/graphql-cims";
import { AlterationPurposeTransferDialog } from "./components/AlterationPurposeTransferDialog";
import { AlterationPurposeFab } from "./components/AlterationPurposeFab";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { switchOnPurposeMakeReadable } from "../AddRemove/functions/switchOnPurposeMakeReadable";
import { ClosetSelected } from "./types/ClosetSelected";
import { PageLayout } from "../shared/pageLayout/PageLayout";

/**
 * React props for page.
 * @interface ProductScanPageProps
 */
interface AlterationPurposePageProps {}

export const AlterationPurposePage = (
  props: AlterationPurposePageProps & RouteComponentProps
) => {
  const [activeClosetId] = useActiveClosetId();

  const [searchClosetsEmailValue, setSearchClosetsEmailValue] = useState("");

  const [closetSelected, setClosetSelected] = useState<ClosetSelected>(null);

  const [closetSelectedConfirmed, setClosetSelectedConfirmed] = useState<
    ClosetSelected
  >(null);

  const [purposeType, setPurposeType] = usePurposeType();
  const [purposeNote, setPurposeNote] = usePurposeNote();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const heightMinusTopAppBar = "calc(100% - 56px)";

  const fabProps = {
    activeClosetId,
    closetSelectedConfirmed,
    purposeType,
    purposeNote,
    setPurposeType,
    setPurposeNote,
    ...props
  };

  const renderContent = () => {
    if (!activeClosetId) {
      return (
        <div style={{ height: heightMinusTopAppBar }}>
          <EmptyStatePage
            tag="Text"
            headline="No closet selected"
            body="Please press the back button in the top left corner."
          />
        </div>
      );
    }

    return (
      <div style={{ overflowY: "scroll", height: "calc(92% - 72px)" }}>
        <div style={{ marginBottom: 120 }}>
          <DialogTitle
            style={{
              fontFamily: "'Helvetica Neue', 'Arial', sans-serif"
            }}
          >
            Select Purpose
          </DialogTitle>
          <DialogContent
            style={{
              fontFamily: "'Helivetica Neue', 'Arial', sans-serif"
            }}
          >
            {Object.values(PurposeType).map((item: PurposeType) => {
              return (
                <div key={item}>
                  <Radio
                    value={item}
                    checked={purposeType === item}
                    style={{
                      fontFamily: "'Helvetica Neue Medium',Arial, sans-serif",
                      color: "#111111"
                    }}
                    onChange={event => {
                      setPurposeType(event.currentTarget.value as PurposeType);
                      if (purposeType !== item) {
                        setIsDialogOpen(true);
                        setSearchClosetsEmailValue("");
                      }
                    }}
                  >
                    {switchOnPurposeMakeReadable(item)}
                  </Radio>
                  {PurposeType.Transfer === item && purposeType === item && (
                    <div style={{ marginLeft: 30 }}>
                      {closetSelectedConfirmed &&
                      closetSelectedConfirmed.userEmail ? null : (
                        <Button
                          unelevated
                          dense
                          onClick={() => {
                            setSearchClosetsEmailValue("");
                            setIsDialogOpen(true);
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 20,
                            fontFamily: "'Helvetica Neue', 'Arial', sans-serif"
                          }}
                        >
                          Select Destination
                        </Button>
                      )}
                      {closetSelectedConfirmed && (
                        <div>
                          <Typography
                            use="caption"
                            style={{
                              marginLeft: 15,
                              fontFamily:
                                "'Helvetica Neue', 'Arial', sans-serif"
                            }}
                          >
                            {closetSelectedConfirmed.userEmail}
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                  {PurposeType.Transfer === item && purposeType === item && (
                    <AlterationPurposeTransferDialog
                      key={String(isDialogOpen)}
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      searchClosetsEmailValue={searchClosetsEmailValue}
                      setClosetSelectedConfirmed={setClosetSelectedConfirmed}
                      setClosetSelected={setClosetSelected}
                      closetSelected={closetSelected}
                      setSearchClosetsEmailValue={setSearchClosetsEmailValue}
                    />
                  )}
                </div>
              );
            })}
            <div
              style={{
                marginTop: 10,
                display: "block"
              }}
            >
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPurposeNote(
                    event.target.value.replace(/[^a-z0-9 .?!]/gi, "")
                  );
                }}
                value={purposeNote || ""}
                textarea
                outlined
                fullwidth
                label="Please state your business purpose"
                rows={6}
                maxLength={140}
                characterCount
                helpText={{
                  persistent: true,
                  validationMsg: true,
                  children: "The field is required"
                }}
                style={{
                  borderColor: "#111111",
                  color: "#111111",
                  height: "100px"
                }}
              />
            </div>
          </DialogContent>
          <AlterationPurposeFab {...fabProps} />
        </div>
      </div>
    );
  };

  return (
    <PageLayout>
      <TopAppBarShared
        tag="LinkBack"
        title="Purpose"
        disabled={false}
        to="/remove"
      />
      <div />
      { renderContent() }
    </PageLayout>
  );
};
