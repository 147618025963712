import React from "react";
import { DataTableCell } from "../../../rmwc/data-table";
import "@rmwc/circular-progress/circular-progress.css";
import { Checkbox } from "../../../rmwc/checkbox";
import "@material/form-field/dist/mdc.form-field.css";
import { CircularProgress } from "@rmwc/circular-progress";
import { AlterationState } from "../functions/alterationState";
import { useIsAllowScan } from "../../../store/useAllowScan";
import { CpgProduct } from "../../shared/components/CpgProduct";
import {
  ProductDetailsSummaryByCode,
  ProductDetailsSummaryBySize
} from "../../../apis/cims/hooks/UpcProduct";
import {
  ClosetProduct,
  AlterationPolarity
} from "../../../apis/cims/generated/graphql-cims";
import { useAlterationProducts } from "../../AddRemove/hooks/useAlterationProducts";
import { useOnRowSelectedCpgProduct } from "../hooks/useOnRowSelectedCpgProduct";

export interface ClosetItemProps {
  closetId: string;
  isProductDetailsSummarizedLoading: boolean;
  alterationStateAdd: AlterationState;
  codeValue: ProductDetailsSummaryByCode<ClosetProduct>;
  sizeValue: ProductDetailsSummaryBySize<ClosetProduct>;
}

export const CpgProductRow = (props: ClosetItemProps) => {
  // Hard-coded to remove because add not supported on closet page, at least as of yet.
  const { alterationProducts, pendingAlteration } = useAlterationProducts(
    AlterationPolarity.Remove
  );

  const [
    isCreatingAlterationProducts,
    setIsCreatingAlterationProducts
  ] = React.useState(false);
  const [isAllowScan] = useIsAllowScan();

  const productDetail = props.sizeValue.productDetails.find(x => !!x);

  const alterationProductMatches = alterationProducts.data.filter(
    x =>
      !!x &&
      !!productDetail &&
      !!productDetail.size &&
      x.upc === productDetail.size.upc
  );

  const [
    isDeletingAlterationProducts,
    setIsDeletingAlterationProducts
  ] = React.useState(false);

  const onRowSelectedCpgProduct = useOnRowSelectedCpgProduct(
    setIsCreatingAlterationProducts,
    setIsDeletingAlterationProducts,
    alterationProducts,
    alterationProductMatches,
    productDetail,
    pendingAlteration,
    props.closetId
  );

  return (
    <CpgProduct
      tag="ClosetDetail"
      codeValue={props.codeValue}
      sizeValue={props.sizeValue}
      onRowSelected={onRowSelectedCpgProduct}
      activated={
        alterationProductMatches.length !== 0 &&
        props.alterationStateAdd === "empty"
      }
      checkboxCell={
        <DataTableCell
          style={{ paddingLeft: "0 0 0 15px" }}
          className="checkboxCell"
        >
          {alterationProducts.loading ||
          props.isProductDetailsSummarizedLoading ||
          isCreatingAlterationProducts ||
          isDeletingAlterationProducts ? (
            <CircularProgress style={{marginLeft: "-7px"}} />
          ) : (
            <Checkbox
              checked={alterationProductMatches.length !== 0}
              disabled={
                alterationProductMatches.length !== 0
                  ? false // always allow alteration product deletion
                  : !isAllowScan // only allow sequential alteration product creation
              }
            />
          )}
        </DataTableCell>
      }
    />
  );
};
