import React from "react";
import { Typography } from "../../../rmwc/typography";

type SharedProps = {
  headline: React.ReactNode | string;
  body: React.ReactNode | string;
};

type TextProps = {
  tag: "Text";
} & SharedProps;

type SvgProps = {
  tag: "Svg";
} & SharedProps;

/**
 * Renderable to page in case of no items or item to display.
 * @export
 * @param {{ headline: string; body: React.ReactNode | string }} props
 * @returns
 */
export const EmptyStatePage = (props: TextProps | SvgProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: 16,
        textAlign: "center",
        boxSizing: "border-box" // fixes scroll issue regarding height
      }}
    >
      <Typography use="headline5">{props.headline}</Typography>
      <Typography
        use={(() => {
          switch (props.tag) {
            case "Svg":
              return "headline3";
            case "Text":
              return "body3";
          }
        })()}
      >
        {props.body}
      </Typography>
    </div>
  );
};
