import { UseOktaUser } from "../../../store/useOktaUser";
import { recordGuid } from "../../../utils/logging";
import {
  errorsArrayAlert,
  graphqlErrorAlert,
  networkErrorAlert
} from "../../../utils/errorAlerts";
import { FetchResult } from "apollo-link";
import uuidv4 from "uuid/v4";
import { pendingAlterationRefetchQuery } from "../../../apis/cims/hooks/Alteration";
import {
  CreateAlterationProductBatchAddMutation,
  CreateAlterationProductBatchAddMutationVariables,
  RemoveCreateAlterationProductBatchMutation,
  RemoveCreateAlterationProductBatchMutationVariables,
  Alteration,
  CreateAlterationMutation,
  CreateAlterationMutationVariables,
  AlterationPolarity
} from "../../../apis/cims/generated/graphql-cims";
import { refetchQueryAlterationProducts } from "../../AddRemove/functions/refetchQueryAlterationProducts";
import { MutationFn } from "../../../types/apollo";

type CreateAlterationProductBatch = {
  upc: string;
  units: number;
  oktaUser: UseOktaUser | null;
  pendingAlteration: Pick<Alteration, "id"> | null;
  createAlteration: MutationFn<
    CreateAlterationMutation,
    CreateAlterationMutationVariables
  >;
  activeClosetId: string | null;
  alterationPolarity: AlterationPolarity;
  onStart: () => void;
  onFinish: () => void;
  onSuccess: () => void;
  setIsAllowScan: (x: boolean) => void;
};
type CreateAlterationProductBatchAdd = {
  tag: "Add";
  createAlterationProductBatchAddMutationFn: MutationFn<
    CreateAlterationProductBatchAddMutation,
    CreateAlterationProductBatchAddMutationVariables
  >;
} & CreateAlterationProductBatch;
type CreateAlterationProductBatchRemove = {
  tag: "Remove";
  removeCreateAlterationProductBatchMutationFn: MutationFn<
    RemoveCreateAlterationProductBatchMutation,
    RemoveCreateAlterationProductBatchMutationVariables
  >;
} & CreateAlterationProductBatch;

export const createAlterationProductBatch = async (
  input: CreateAlterationProductBatchAdd | CreateAlterationProductBatchRemove,
  ForcesLoadingFalse: () => "ForcesLoadingFalse"
): Promise<"ForcesLoadingFalse"> => {
  const failWithReallow = (guid: string) => {
    recordGuid(guid);
    input.setIsAllowScan(true);
    (window as any).allowScan = true;
    input.onFinish();
    return ForcesLoadingFalse();
  };
  const succeedWithReallowValue = () => {
    input.setIsAllowScan(true);
    (window as any).allowScan = true;
    input.onFinish();
    input.onSuccess();
    return ForcesLoadingFalse();
  };

  // if ((window as any).allowScan === false) {
  //   const failWithDisallow = () => {
  //     // (window as any).allowScan already disallowed upon invoking onDetected
  //     return ForcesLoadingFalse();
  //   };
  //   // silently block
  //   return failWithDisallow();
  // }

  input.onStart();
  (window as any).allowScan = false;
  input.setIsAllowScan(false); // disallow concurrent scans

  if (input.upc) {
    const processIfInStock = async () => {
      if (input.pendingAlteration === null) {
        // @ts-ignore
        if (input.createAlteration) {
          if (
            input.oktaUser &&
            input.oktaUser.value &&
            input.oktaUser.value.email
          ) {
            if (input.activeClosetId) {
              return createAlterationShared(
                input.createAlteration,
                input.alterationPolarity,
                input.oktaUser.value.email,
                input.activeClosetId,
                async (alterationId: string) => {
                  return await insertScannedItem(
                    alterationId,
                    input,
                    succeedWithReallowValue,
                    failWithReallow
                  );
                },
                failWithReallow
              );
            } else {
              return failWithReallow("3943f1a6-b408-42e0-b9e6-d4c8ee9b9f5f");
            }
          } else {
            return failWithReallow("67d5ac67-77a4-4aa9-ae32-523657b4644d");
          }
        } else {
          return failWithReallow("777c1154-703c-4af3-9fb8-929ca2277523");
        }
      } else {
        return insertScannedItem(
          input.pendingAlteration.id,
          input,
          succeedWithReallowValue,
          failWithReallow
        );
      }
    };

    if (input.activeClosetId) {
      // @ts-ignore
      return processIfInStock().catch(() => {
        return failWithReallow("3d043cc7-8980-4d51-af5d-381fd2e89581");
      });
    } else {
      return failWithReallow("a4869ebc-8920-4ffb-91e5-7c87c37f253d");
    }
  } else {
    return failWithReallow("3f921c07-be7e-4c13-8ca6-8bb07da5a091");
  }
};

function createAlterationShared(
  createAlteration: MutationFn<
    CreateAlterationMutation,
    CreateAlterationMutationVariables
  >,
  alterationPolarity: AlterationPolarity,
  oktaUserEmail: string,
  closetId: string,
  onSuccess: (p: any) => void,
  onError: (p: string) => void
) {
  const alterationId = uuidv4();

  return createAlteration({
    variables: {
      id: alterationId,
      polarity: alterationPolarity,
      pending: true,
      alterationClosetId: closetId,
      userEmail: oktaUserEmail,
      createdAt: new Date().toISOString()
    }
  })
    .then((result: void | FetchResult<CreateAlterationMutation>) => {
      if (result && result.data && result.data.createAlteration) {
        return onSuccess(result.data.createAlteration.id);
      } else {
        return onError("01392711-81a7-4a61-b323-ce323f96fe2e");
      }
    })
    .catch(() => {
      return onError("e4b6f4aa-fd58-48d1-ae4a-6a4475770233");
    });
}

function insertScannedItem(
  alterationId: string,
  input: CreateAlterationProductBatchAdd | CreateAlterationProductBatchRemove,
  onSuccess: () => void,
  onError: (p: string) => void
) {
  const handleError = (code: string) => {
    return onError(code)
  }
  if (alterationId) {
    const uponSuccessRefetchQueries = [
      refetchQueryAlterationProducts(alterationId),
      input.oktaUser
        ? pendingAlterationRefetchQuery(
          input.oktaUser,
          input.activeClosetId,
          input.alterationPolarity
        )
        : []
    ].flat();
    if (input.upc) {
      switch (input.tag) {
        case "Add":
          return input
            .createAlterationProductBatchAddMutationFn({
              variables: {
                input: {
                  alterationId,
                  upc: input.upc,
                  units: input.units
                }
              },
              refetchQueries: uponSuccessRefetchQueries
            })
            .then(result => {
              if (result && result.errors) {
                graphqlErrorAlert(result);
                return handleError("5a4287ac-65a2-4425-b4af-461f9821d314");
              } else if (result && result.data) {
                const errors = result.data.createAlterationProductBatchAdd?.errors
                if (errors && errors.length > 0) {
                  // we need to refresh actual quantities of products
                  console.error(errors)
                  errorsArrayAlert(["Unable to add/remove some alteration products."])
                  return handleError("3b076052-0991-4681-86d5-4913c7d1cf67");
                }
              }
              return onSuccess();
            })
            .catch(error => {
              const errorCode = "69181fbd-00cc-4d5d-bc6c-0323e118193f";
              const errHandler = getGraphQlErrorHandler(errorCode, handleError)
              return errHandler(error)
            });
        case "Remove":
          return input
            .removeCreateAlterationProductBatchMutationFn({
              variables: {
                input: {
                  alterationId,
                  upc: input.upc,
                  units: input.units,
                }
              },
              refetchQueries: uponSuccessRefetchQueries
            })
            .then(result => {
              if (result && result.errors) {
                graphqlErrorAlert(result);
                return handleError("2ff3d197-0c31-4af0-97e6-05ab82c4097d");
              } else if (result && result.data) {
                const errors = result.data.removeCreateAlterationProductBatch?.errors
                if (errors && errors.length > 0) {
                  console.error(errors)
                  errorsArrayAlert(["Unable to add/remove some alteration products."])
                  return handleError("06387275-56f8-4c1d-b40a-d2f7b7cc8975");
                }
              }
              return onSuccess();
            })
            .catch(error => {
              const errorCode = "7615864e-2555-48e6-b47d-fffb3f4fec3e";
              const errHandler = getGraphQlErrorHandler(errorCode, handleError)
              return errHandler(error)
            });
      }
    } else {
      return handleError("5891281a-fb6a-41e1-a905-ad0f34958b6a");
    }
  } else {
    return handleError("0c04a092-e194-47da-a7cf-b90c09b2cb32");
  }
}

function getGraphQlErrorHandler(errorCode: string, onError: (code: string) => void) {
  // when lambda throws unexpected error
  return (error?: any) => {
    if (error.message) {
      errorsArrayAlert([error.message])
    } else {
      networkErrorAlert(errorCode, error);
    }
    return onError(errorCode);
  }
}
