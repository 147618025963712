import React from "react";
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle
} from "../../../rmwc/top-app-bar";
import { Link, RouteComponentProps } from "react-router-dom";
import { IconButton } from "@rmwc/icon-button";

// TODO: Replace with TopAppBarShared
export function TransfersPageTopNav(
  props: Pick<RouteComponentProps<{}>, "location"> & { listType: string }
) {
  return (
    <TopAppBar fixed>
      <TopAppBarRow>
        <TopAppBarSection>
          <Link
            to={{
              pathname: "/closet",
              state: {
                prevPath: props.location && props.location.pathname
              }
            }}
            style={{
              textDecoration: "none"
            }}
          >
            <IconButton
              icon="arrow_back"
              style={{
                color: "var(--mdc-theme-on-primary)"
              }}
            />
          </Link>
          <TopAppBarTitle>{props.listType} Transfers</TopAppBarTitle>
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  );
}
