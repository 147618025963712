import React, { useState } from "react";
import { useActiveClosetId } from "../../../store/useGlobalState";
import { ProductDetailsLoading } from "../../../apis/cims/hooks/Closet";
import { usePendingAlteration } from "../../../apis/cims/hooks/Alteration";
import { AlterationPolarity } from "../../../apis/cims/generated/graphql-cims";
import { Fab } from "@rmwc/fab";
import { CircularProgress } from "@rmwc/circular-progress";
import { useHistory } from "react-router-dom";
import { onClickClosetFab } from "../functions/onClickClosetFab";
import { bool } from "aws-sdk/clients/signer";

export type ClosetFabsProps = {
  isClosetEmptyOrNoCloset: boolean;
  isClosetDetailsLoading: boolean;
  closetId: string | null
};

export const ClosetFabs = (props: ClosetFabsProps) => {
  const history = useHistory();

  const [productScanPageIsLoading, setProductScanPageIsLoading] = useState(
    false
  );
  const [, setActiveClosetId] = useActiveClosetId();

  // isClosetEmptyOrNoCloset
  // closetId
  // isClosetLoading
  // isClosetDetailsLoading

  const closetId = props.closetId


  const pendingAlterationRemove = usePendingAlteration(
    closetId,
    // Hard-coded to remove because add not supported on closet page, at least as of yet.
    AlterationPolarity.Remove
  );

  const removeIsLoading =
    pendingAlterationRemove.loading ||
    productScanPageIsLoading ||
    props.isClosetDetailsLoading;
  const addIsLoading =
    pendingAlterationRemove.loading ||
    productScanPageIsLoading ||
    props.isClosetDetailsLoading;
  const removeIsDisabled = removeIsLoading;
  const addIsDisabled = addIsLoading;
  return (
    <div>
      {props.isClosetEmptyOrNoCloset ? null : (
        <Fab
          className="removeFab"
          onClick={onClickClosetFab(
            closetId,
            setActiveClosetId,
            // Hard-coded to remove because add not supported on closet page, at least as of yet.
            AlterationPolarity.Remove,
            history.push,
            setProductScanPageIsLoading
          )}
          style={{
            position: "fixed",
            right: 86,
            bottom: 16,
            display: !removeIsLoading
              ? undefined // show
              : "none",
            color: removeIsDisabled
              ? "#eee"
              : "var(--mdc-theme-on-primary, #fff)",
            background: removeIsDisabled ? "#aaa" : "var(--mdc-theme-primary)",
            borderRadius: 50
          }}
          icon={removeIsLoading ? <CircularProgress /> : "remove"}
          disabled={removeIsDisabled}
        />
      )}
      <Fab
        className="addFab"
        onClick={onClickClosetFab(
          closetId,
          setActiveClosetId,
          AlterationPolarity.Add,
          history.push,
          setProductScanPageIsLoading
        )}
        style={{
          position: "fixed",
          right: 16,
          bottom: 16,
          display: !addIsLoading
            ? undefined // show
            : "none",
          color: addIsDisabled ? "#eee" : "var(--mdc-theme-on-primary, #fff)",
          background: addIsDisabled ? "#aaa" : "var(--mdc-theme-primary)",
          borderRadius: 50
        }}
        icon={addIsLoading ? <CircularProgress /> : "add"}
        disabled={addIsDisabled}
      />
    </div>
  );
};
