import React from "react";
import { DataTableRow, DataTableCell } from "../../../rmwc/data-table";
import { useHistory, useLocation } from "react-router-dom";
import { History } from "history";
import { encode } from "int-encoder";
import {
  UpcProduct,
  ProductDetailsSummaryByCode,
  ProductDetailsSummaryBySize
} from "../../../apis/cims/hooks/UpcProduct";
import { ListItemPrimaryText, ListItemSecondaryText } from "../../../rmwc/list";
import { SelectUnits } from "../../AddRemove/components/SelectUnits";

type DetailProps = {
  codeValue: ProductDetailsSummaryByCode<UpcProduct>;
  sizeValue: ProductDetailsSummaryBySize<UpcProduct>;
};

type AlterationDetailIncomingProps = {
  tag: "AlterationDetailIncoming";
} & DetailProps;

type AlterationDetailOutgoingProps = {
  tag: "AlterationDetailOutgoing";
} & DetailProps;

type AlterationDetailIncomingRejectedProps = {
  tag: "AlterationDetailIncomingRejected";
} & DetailProps;

type AlterationDetailOutgoingRejectedProps = {
  tag: "AlterationDetailOutgoingRejected";
} & DetailProps;

type AddProps = {
  tag: "Add";
  totalAmountOfProductsInAlteration: number
} & DetailProps;

type RemoveProps = {
  tag: "Remove";
  totalAmountOfProductsInAlteration: number
} & DetailProps;

type ClosetDetailProps = {
  tag: "ClosetDetail";
  onRowSelected: () => void;
  activated: boolean;
  checkboxCell: React.ReactNode;
} & DetailProps;

export type CpgProductAddRemoveProps = AddProps | RemoveProps;

export type CpgProductProps =
  | AlterationDetailIncomingProps
  | AlterationDetailOutgoingProps
  | AlterationDetailIncomingRejectedProps
  | AlterationDetailOutgoingRejectedProps
  | ClosetDetailProps
  | AddProps
  | RemoveProps;

function calculatedProps(
  props: CpgProductProps,
  history: History<unknown>,
  transferId: string,
  upc: string
) {
  switch (props.tag) {
    case "ClosetDetail":
      return {
        onRowSelected: props.onRowSelected,
        activated: props.activated,
        checkboxCell: props.checkboxCell
      };
    case "AlterationDetailIncoming":
      return {
        onRowSelected: () => {
          const upcAsInt = parseInt(upc);
          const upcEncoded = encode(upcAsInt);
          history.push(`/reconcile?transfer=${transferId}&upc=${upcEncoded}`);
        },
        activated: undefined,
        checkboxCell: null
      };
    case "AlterationDetailOutgoing":
    case "AlterationDetailIncomingRejected":
    case "AlterationDetailOutgoingRejected":
    case "Add":
    case "Remove":
      return {
        onRowSelected: () => {},
        activated: undefined,
        checkboxCell: null
      };
  }
}

export const CpgProduct = (props: CpgProductProps) => {
  let history = useHistory();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const firstProduct = props.sizeValue.productDetails.find(x => !!x) || null;
  const upc =
    (firstProduct &&
      firstProduct.cpgProduct &&
      firstProduct.upcProduct &&
      firstProduct.upcProduct.upc) ||
    "NO_UPC";
  const transferId = urlSearchParams.get("id") || "NO_TRANSFER_ID";

  const propsFromTag = calculatedProps(props, history, transferId, upc);

  const productDetail = props.sizeValue.productDetails.find(x => !!x);

  return (
    <DataTableRow
      onClick={propsFromTag.onRowSelected}
      activated={propsFromTag.activated}
    >
      {propsFromTag.checkboxCell}
      <DataTableCell
        className="productNameCell"
        style={{ paddingLeft: 5, backgroundColor: "white" }}
      >
        <ListItemPrimaryText className="title">
          <span>{(productDetail && productDetail.title) || "no name"}</span>
        </ListItemPrimaryText>
        <ListItemSecondaryText
          className="styleColor"
          style={{
            fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
            fontSize: "14px"
          }}
        >
          <span>{props.codeValue.code}</span>
        </ListItemSecondaryText>
      </DataTableCell>
      <DataTableCell className="sizeCell">
        <span>{props.sizeValue.size}</span>
      </DataTableCell>
      <DataTableCell className="unitsCell">
        {(function() {
          switch (props.tag) {
            //If the tag is "Add" or "Remove", provide a dropdown to allow the user to edit the number of units.
            case "Add":
              return <SelectUnits {...props} upc={upc} tag="Add" isNumInput={true} />;
            case "Remove":
              return <SelectUnits {...props} upc={upc} tag="Remove" isNumInput={true} />;
            //Otherwise, the total number of the upc is shown.
            case "AlterationDetailIncoming":
            case "AlterationDetailOutgoing":
            case "AlterationDetailIncomingRejected":
            case "AlterationDetailOutgoingRejected":
            case "ClosetDetail":
              return props.sizeValue.total;
          }
        })()}
      </DataTableCell>
    </DataTableRow>
  );
};
