import {
  TransfersDataIncomingProps,
  TransfersDataOutgoingProps
} from "../components/TransfersDataTable";
import { filterAlterations } from "./filterAlterations";

export function switchOnListTypeAlterations(
  props: TransfersDataIncomingProps | TransfersDataOutgoingProps
) {
  switch (props.listType) {
    case "Incoming":
      const incoming =
        (props &&
          props.transfersQueryResult &&
          props.transfersQueryResult.data &&
          props.transfersQueryResult.data
            .listAlterationsByAlterationToClosetId &&
          props.transfersQueryResult.data.listAlterationsByAlterationToClosetId
            .items) ||
        [];
      const incomingPending = filterAlterations(incoming, "Pending");
      const incomingDisputed = filterAlterations(incoming, "Rejected");
      return {
        items: {
          pending: incomingPending,
          disputed: incomingDisputed
        }
      };
    case "Outgoing":
      const outgoing =
        (props &&
          props.transfersQueryResult &&
          props.transfersQueryResult.data &&
          props.transfersQueryResult.data
            .listAlterationsByAlterationFromClosetId &&
          props.transfersQueryResult.data
            .listAlterationsByAlterationFromClosetId.items) ||
        [];
      const outgoingPending = filterAlterations(outgoing, "Pending");
      const outgoingDisputed = filterAlterations(outgoing, "Rejected");

      return {
        items: {
          pending: outgoingPending,
          disputed: outgoingDisputed
        }
      };
  }
}
