import { useEffect, useState } from "react";

export function useStore<T>(initialValue: T) {
  let value = initialValue;
  let forceUpdates: React.Dispatch<T>[] = [];

  return () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, forceUpdate] = useState();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!forceUpdates.includes(forceUpdate)) {
        forceUpdates.push(forceUpdate);
      }
      return () => {
        forceUpdates = forceUpdates.filter(x => x !== forceUpdate);
      };
    }, [forceUpdate]);

    const returnTuple: [T, (t: T) => void] = [
      value,
      (newValue: T) => {
        value = newValue;
        forceUpdates.forEach(x => x(newValue));
      }
    ];
    return returnTuple;
  };
}
