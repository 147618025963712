import { MutationFn } from "../../../types/apollo";
import { UseOktaUser } from "../../../store/useOktaUser";
import ApolloClient from "apollo-client";
import { UsePendingAlteration } from "../../Closet/functions/alterationState";
import {
  UpdateClosetProductMutation,
  UpdateClosetProductMutationVariables,
  DeleteAlterationProductMutation,
  DeleteAlterationProductMutationVariables,
  CreateAlterationMutation,
  CreateAlterationMutationVariables,
  CreateAlterationProductBatchAddMutation,
  CreateAlterationProductBatchAddMutationVariables,
  RemoveCreateAlterationProductBatchMutation,
  RemoveCreateAlterationProductBatchMutationVariables,
  AlterationPolarity
} from "../../../apis/cims/generated/graphql-cims";
import { switchOnPolarityAdjustAlterationProducts } from "./switchOnPolarityAdjustAlterationProducts";
import { ProductDetail } from "../../../apis/cims/hooks/UpcProduct";
import { DeletableAlterationProduct } from "../../shared/types/DeletableAlterationProduct";
import {
  assertAlterationProductAmountLimits
} from "../../shared/limitsService";

type OnClickQuantitySelected = {
  updateClosetProduct: MutationFn<
    UpdateClosetProductMutation,
    UpdateClosetProductMutationVariables
  >;
  deleteAlterationProduct: MutationFn<
    DeleteAlterationProductMutation,
    DeleteAlterationProductMutationVariables
  >;
  createAlteration: MutationFn<
    CreateAlterationMutation,
    CreateAlterationMutationVariables
  >;
  upc: string;
  oktaUser: UseOktaUser;
  quantity: number;
  client: ApolloClient<unknown>;
  pendingAlteration: UsePendingAlteration;
  //setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  alterationPolarity: AlterationPolarity;
  //setIsUpdatingQuantity: React.Dispatch<React.SetStateAction<boolean>>;
  setIsScanAllowed: (x: boolean) => void;
  alterationProductTotal: number;
  closetId: string;
  upcTotal: number;
  singleUpcAmountInCloset: number;
};
export type OnClickQuantitySelectedAdd = {
  tag: "Add";
  createAlterationProductBatchAddMutation: MutationFn<
    CreateAlterationProductBatchAddMutation,
    CreateAlterationProductBatchAddMutationVariables
  >;
} & OnClickQuantitySelected;
export type OnClickQuantitySelectedRemove = {
  tag: "Remove";
  removeCreateAlterationProductBatchMutation: MutationFn<
    RemoveCreateAlterationProductBatchMutation,
    RemoveCreateAlterationProductBatchMutationVariables
  >;
} & OnClickQuantitySelected;

export const onClickQuantitySelected = async (
  input: OnClickQuantitySelectedAdd | OnClickQuantitySelectedRemove,
  ForcesLoadingFalse: () => "ForcesLoadingFalse"
): Promise<"ForcesLoadingFalse"> => {
  const singleUpcAmountNew = input.quantity;
  const singleUpcAmountCurrent = input.upcTotal;
  const hasChanges = singleUpcAmountNew !== singleUpcAmountCurrent;
  if (!hasChanges) {
    return ForcesLoadingFalse();
  }

  try {
    const alterationProductsTotal = input.alterationProductTotal;
    const singleUpcAmountInCloset = input.singleUpcAmountInCloset
    assertAlterationProductAmountLimits(
      input.tag,
      alterationProductsTotal,
      singleUpcAmountCurrent,
      singleUpcAmountNew,
      singleUpcAmountInCloset,
    );

    // We ask Lambda function to adjust (add or remove) the alteration products,
    // so that the total amount of them is equal to "units" entered by user.
    return switchOnPolarityAdjustAlterationProducts(
      input,
      input.quantity,
      ForcesLoadingFalse
    );
  } catch (err) {
    return ForcesLoadingFalse();
  }
};
