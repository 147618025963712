import React, { useState } from "react";
import slugid from "slugid";
import { RouteComponentProps, useLocation } from "react-router-dom";
// import { useIsAllowScan } from "../shared/hooks/useAllowScan";
import intEncoder from "int-encoder";
import { ReconcilePageDialog } from "./components/DisputeDialog";
import { useShowTransferProductSubmitSuccess } from "../../store/useGlobalState";
import { AlterationProductStatus } from "../../apis/cims/generated/graphql-cims";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { NonCameraView } from "./components/NonCameraView";
import { useAlterationProducts } from "./hooks/useAlterationProducts";
import { PageLayout } from "../shared/pageLayout/PageLayout";

export const ReconcilePage = (
  props: Pick<RouteComponentProps<{}>, "history">
) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const upc = urlSearchParams.get("upc");
  const upcDecoded =
    (upc &&
      intEncoder
        .decode(upc)
        .toString()
        .padStart(14, "0")) ||
    "NO_UPC";
  const transferIdEncoded = urlSearchParams.get("transfer");
  const transferId = transferIdEncoded && slugid.decode(transferIdEncoded);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = (open: boolean) => {
    setIsDialogOpen(open);
  };

  const { alterationProducts } = useAlterationProducts(transferId);

  const pendingItems = alterationProducts?.filter(
    product =>
      product?.status === AlterationProductStatus.Pending &&
      product?.upc === upcDecoded
  );

  const [, setIsShowSuccessSnackbar] = useShowTransferProductSubmitSuccess();

  const [quantity, setQuantity] = React.useState(0);

  const slicedPendingsItems = pendingItems.slice(0, quantity) || [];

  return (
    <PageLayout>
      <TopAppBarShared tag="OnClickBack" title="Reconcile" />
      <div
        className="heightMinusTopAppBar"
        style={{
          display: "flex",
          flexDirection: "column-reverse"
        }}
      >
        <NonCameraView
          {...{
            setQuantity,
            quantity,
            setIsDialogOpen,
            transferId,
            upcDecoded,
            alterationProducts,
            pendingItems
          }}
          history={props.history}
        />
        {isDialogOpen && (
          <ReconcilePageDialog
            handleOpen={handleOpen}
            open={isDialogOpen}
            pendingItems={slicedPendingsItems}
            transferId={transferId}
            setIsShowSuccessSnackbar={setIsShowSuccessSnackbar}
          />
        )}
      </div>
    </PageLayout>
  );
};
