import { groupWith } from "ramda";
import { AlterationProduct, ClosetProduct } from "../generated/graphql-cims";
import { Product as CpgProduct, Size } from "../../cpg/generated/graphql-cpg";

export interface ProductDetail<T> {
  // TODO: Replace Partial with Pick
  upcProduct: Partial<T> | null;
  cpgProduct: CpgProduct | null;
  size: Size | null;
  title: string;
}

export interface ProductDetailsSummaryBySize<T> {
  size: string;
  total: number;
  // TODO: Replace Partial with Pick
  productDetails: Partial<ProductDetail<T> | null>[];
}
export interface ProductDetailsSummaryByCode<T> {
  code: string;
  total: number;
  productDetailsBySize: Array<ProductDetailsSummaryBySize<T>>;
}
export interface ProductDetailsSummary<T> {
  total: number;
  productDetailsByCode: Array<ProductDetailsSummaryByCode<T>>;
}

export interface UpcProduct {
  // id enables deletion of alteration products, temporary hack due to poor generic code
  id: string;
  upc: string;
}

export interface UpcProductAlteration extends UpcProduct {
  createdAt: string;
}

function codeFromProductDetail(
  productDetail: ProductDetail<UpcProduct> | null
) {
  const code =
    (productDetail &&
      productDetail.cpgProduct &&
      productDetail.cpgProduct.productCode) ||
    "no code";
  return code;
}

function sizeFromProductDetail(
  productDetail: ProductDetail<UpcProduct> | null
) {
  const size =
    productDetail &&
    productDetail.cpgProduct &&
    productDetail.cpgProduct.sizes &&
    productDetail.cpgProduct.sizes.find(size => {
      return (
        !!size &&
        size.upc ===
        ((productDetail &&
          productDetail.upcProduct &&
          productDetail.upcProduct.upc) ||
          "no upc")
      );
    });
  return (size && size.description) || "no size";
}

export interface ProductDetails<T> {
  value: {
    items: (ProductDetail<T> | null)[] | null;
  };
  error: Error | undefined;
  loading: boolean;
}

type AlterationProductsIncoming = {
  tag: "AlterationProductsIncoming";
} & ProductDetails<AlterationProduct>;

type AlterationProductsOutgoing = {
  tag: "AlterationProductsOutgoing";
} & ProductDetails<AlterationProduct>;

type AlterationProductsIncomingRejected = {
  tag: "AlterationProductsIncomingRejected";
} & ProductDetails<AlterationProduct>;

type AlterationProductsOutgoingRejected = {
  tag: "AlterationProductsOutgoingRejected";
} & ProductDetails<AlterationProduct>;

type ClosetProducts = {
  tag: "ClosetProducts";
} & ProductDetails<ClosetProduct>;

type AlterationProductsAdd = {
  tag: "AlterationProductsAdd";
} & ProductDetails<AlterationProduct>;

type AlterationProductsRemove = {
  tag: "AlterationProductsRemove";
} & ProductDetails<AlterationProduct>;

function itemsFiltered(
  productDetails:
    | ClosetProducts
    | AlterationProductsOutgoing
    | AlterationProductsIncoming
    | AlterationProductsIncomingRejected
    | AlterationProductsOutgoingRejected
    | AlterationProductsAdd
    | AlterationProductsRemove
) {
  switch (productDetails.tag) {
    case "AlterationProductsIncoming":
    case "AlterationProductsOutgoing":
      return (
        (productDetails &&
          productDetails.value &&
          productDetails.value.items &&
          productDetails.value.items.filter(
            x => x && x.upcProduct && x.upcProduct.status === "PENDING"
          )) ||
        []
      );
    case "AlterationProductsIncomingRejected":
    case "AlterationProductsOutgoingRejected":
      return (
        (productDetails &&
          productDetails.value &&
          productDetails.value.items &&
          productDetails.value.items.filter(
            x => x && x.upcProduct && x.upcProduct.status === "REJECTED"
          )) ||
        []
      );
    case "ClosetProducts":
    case "AlterationProductsAdd":
    case "AlterationProductsRemove":
      return productDetails.value.items || [];
  }
}

export type ProductDetailsSummaryLoading = {
  value: ProductDetailsSummary<UpcProduct>;
  error: Error | undefined;
  loading: boolean;
};

export function productDetailsSummary(
  productDetails:
    | ClosetProducts
    | AlterationProductsOutgoing
    | AlterationProductsIncoming
    | AlterationProductsIncomingRejected
    | AlterationProductsOutgoingRejected
    | AlterationProductsAdd
    | AlterationProductsRemove
): ProductDetailsSummaryLoading {
  const items = itemsFiltered(productDetails);
  const sortedByCode = items.sort(
    (
      a: ProductDetail<UpcProduct> | null,
      b: ProductDetail<UpcProduct> | null
    ) => {
      return codeFromProductDetail(a) > codeFromProductDetail(b) ? 1 : -1;
    }
  );
  const groupedWithCode:
    | (ProductDetail<UpcProduct> | null)[]
    | (ProductDetail<UpcProduct> | null)[][] = groupWith(
      (
        a: ProductDetail<UpcProduct> | null,
        b: ProductDetail<UpcProduct> | null
      ) => {
        return codeFromProductDetail(a) === codeFromProductDetail(b);
      },
      sortedByCode as any
    );

  const groupedWithCodeAndSize: Array<ProductDetailsSummaryByCode<
    UpcProduct
  >> = groupedWithCode.map(products => {
    const sortedBySize = (products || []).sort((a, b) => {
      return sizeFromProductDetail(a) > sizeFromProductDetail(b) ? 1 : -1;
    });
    const groupedWithSize = groupWith((a, b) => {
      return sizeFromProductDetail(a) === sizeFromProductDetail(b);
    }, sortedBySize);

    const firstProduct = products.find(x => !!x) || null;

    return {
      code: codeFromProductDetail(firstProduct),
      total: products.length,
      productDetailsBySize: groupedWithSize.map(products => {
        const firstProduct = products.find(x => !!x) || null;
        return {
          size: sizeFromProductDetail(firstProduct),
          total: products.length,
          productDetails: products
        };
      })
    };
  });

  /*
    - Compare alteration products for most recently updated items on Add/Remove pages.
    - Outputs array of product data objects sorted by most recent, including grouped sizes sorted by most recent.
    - If an existing stylecolor/size is updated, it does not update stylecolor order, but does update sizes order.
  */

  let groupedWithCodeAndSizeAlteration: ProductDetailsSummaryByCode<UpcProduct>[] = [...groupedWithCodeAndSize];

  if ((productDetails.tag === "AlterationProductsAdd") || (productDetails.tag === "AlterationProductsRemove")) {

    // sort each stylecolor's sizes by most recently created alteration
    groupedWithCodeAndSizeAlteration?.forEach((productSize: ProductDetailsSummaryByCode<UpcProduct>) => {
      productSize?.productDetailsBySize?.sort((
        a: ProductDetailsSummaryBySize<UpcProductAlteration>,
        b: ProductDetailsSummaryBySize<UpcProductAlteration>) => {
        const productSizeCreatedAtA = a?.productDetails?.[0]?.upcProduct?.createdAt || "";
        const productSizeCreatedAtB = b?.productDetails?.[0]?.upcProduct?.createdAt || "";
        return productSizeCreatedAtB?.localeCompare(productSizeCreatedAtA);
      })
    });

    // sort each group of stylecolors by most recently created alteration
    groupedWithCodeAndSizeAlteration?.sort((a: ProductDetailsSummaryByCode<UpcProduct>, b: ProductDetailsSummaryByCode<UpcProduct>) => {
      const mostRecentDateA = a?.productDetailsBySize?.map((product: ProductDetailsSummaryBySize<UpcProductAlteration>) => product?.productDetails?.[0]?.upcProduct?.createdAt).sort().reverse() || "";
      const mostRecentDateB = b?.productDetailsBySize?.map((product: ProductDetailsSummaryBySize<UpcProductAlteration>) => product?.productDetails?.[0]?.upcProduct?.createdAt).sort().reverse() || "";
      return String(mostRecentDateA[0])?.localeCompare(String(mostRecentDateB[0]));
    });
  }

  return {
    error: productDetails.error,
    loading: productDetails.loading,
    value: {
      total: items.length,
      productDetailsByCode: productDetails.tag === "AlterationProductsAdd" || productDetails.tag === "AlterationProductsRemove" ? groupedWithCodeAndSizeAlteration : groupedWithCodeAndSize
    }
  };
}

