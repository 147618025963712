import "./css/page-layout.css";
import React from "react";

type PageLayoutPropsType = {
  children: React.ReactNode;
};

PageLayout.displayName = "PageLayout";

export function PageLayout(props: PageLayoutPropsType) {
  const { children } = props;

  return <div className="c-page-layout">{children}</div>;
}
