import {
  AlterationProductsQueryVariables,
  AlterationProductsDocument
} from "../../../apis/cims/generated/graphql-cims";
import { MAX_AWS_PARSABLE_DATE } from "../../../utils/constants";
import { DocumentNode } from "graphql";

export interface AlterationProductsRefetchQuery {
  query: DocumentNode;
  variables: AlterationProductsQueryVariables;
}

export const refetchQueryAlterationProducts = (
  pendingAlterationId: string
): AlterationProductsRefetchQuery => {
  return {
    query: AlterationProductsDocument,
    variables: {
      alterationProductAlterationId: pendingAlterationId || "",
      createdAt: MAX_AWS_PARSABLE_DATE
    }
  };
};
