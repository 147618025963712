import {
  IncomingProps,
  OutgoingProps,
  IncomingRejectedProps,
  OutgoingRejectedProps
} from "../TransferDetailPage";
import { productDetailsSummary } from "../../../apis/cims/hooks/UpcProduct";
import { UseFetchProductsInfo } from "../../../apis/cpg/hooks/ProductPage";

export const switchOnPropTagSummary = (
  props:
    | IncomingProps
    | OutgoingProps
    | IncomingRejectedProps
    | OutgoingRejectedProps,
  productsInfo: UseFetchProductsInfo
) => {
  const productsInfoLoadingState = {
    value: { items: productsInfo.value || [] },
    error: undefined,
    loading: productsInfo.loading
  };

  switch (props.tag) {
    case "Incoming":
      return productDetailsSummary({
        tag: "AlterationProductsIncoming",
        ...productsInfoLoadingState
      });
    case "Outgoing":
      return productDetailsSummary({
        tag: "AlterationProductsOutgoing",
        ...productsInfoLoadingState
      });
    case "IncomingRejected":
      return productDetailsSummary({
        tag: "AlterationProductsIncomingRejected",
        ...productsInfoLoadingState
      });
    case "OutgoingRejected":
      return productDetailsSummary({
        tag: "AlterationProductsOutgoingRejected",
        ...productsInfoLoadingState
      });
  }
};
