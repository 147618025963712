import { PurposeType } from "../../../apis/cims/generated/graphql-cims";

export function switchOnPurposeMakeReadable(enumValue: PurposeType) {
  switch (enumValue) {
    case PurposeType.AthleteService:
      return "Athlete Service";
    case PurposeType.CharitableDonation:
      return "Charitable Donation";
    case PurposeType.Event:
      return "Event";
    case PurposeType.GovernmentDonation:
      return "Government Official";
    case PurposeType.PhotoShoot:
      return "Photo Shoot";
    case PurposeType.Recognition:
      return "Recognition";
    case PurposeType.Shred:
      return "Shred";
    case PurposeType.Transfer:
      return "Transfer";
    case PurposeType.Van:
      return "The vAn";
    case PurposeType.Lost:
      return "Lost/Stolen";
    case PurposeType.Other:
      return "Other";
  }
}
