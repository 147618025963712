import React from "react";
import { DataTableRow, DataTableCell } from "../../../rmwc/data-table";
import { useHistory } from "react-router-dom";
import slugid from "slugid";
import { FilteredAlterationProduct } from "../types/FilteredAlterationProduct";

export default function TransfersDataContent(props: {
  items: Array<FilteredAlterationProduct>;
  listType: "Incoming" | "Outgoing";
  disputed: boolean;
}) {
  const history = useHistory();
  return (
    <>
      {props.items.map(transfer => {
        const localSubmittedAt = transfer && new Date(transfer.submittedAt);
        const submittedAtYear =
          localSubmittedAt && localSubmittedAt.getFullYear();
        const submittedAtMonth =
          localSubmittedAt &&
          (localSubmittedAt.getMonth() + 1).toString().padStart(2, "0");
        const submittedAtDay = localSubmittedAt && localSubmittedAt.getDate();
        const submittedAtFull = `${submittedAtYear}-${submittedAtMonth}-${submittedAtDay}`;

        const toClosetEmail =
          transfer && transfer.toCloset && transfer.toCloset.userEmail;

        const transferIdEncoded =
          (transfer && transfer.id && slugid.encode(transfer.id)) ||
          "NO_TRANSFER_ID";

        let detailPageUrl = "";

        // TODO: Replace ternary with exhaustive switch statement
        if (props.disputed && props.listType === "Incoming") {
          detailPageUrl = "incoming-disputed";
        } else if (!props.disputed && props.listType === "Incoming") {
          detailPageUrl = "incoming-pending";
        } else if (props.disputed && props.listType === "Outgoing") {
          detailPageUrl = "outgoing-disputed";
        } else {
          detailPageUrl = "outgoing-pending";
        }

        return (
          transfer &&
          transfer.id && (
            <DataTableRow
              key={transfer.id + transfer.submittedAt}
              style={{ height: 20 }}
              onClick={() => {
                history.push(
                  `/transfer-detail/${detailPageUrl}?id=${transferIdEncoded}`
                );
              }}
            >
              <DataTableCell>{transfer.id.slice(0, 8)}</DataTableCell>
              <DataTableCell>
                {/* TODO: Replace ternary with exhaustive switch statement */}
                {props.listType === "Outgoing"
                  ? toClosetEmail
                  : transfer.userEmail}
              </DataTableCell>
              <DataTableCell>{submittedAtFull}</DataTableCell>
            </DataTableRow>
          )
        );
      })}
    </>
  );
}
