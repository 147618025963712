import React from "react";
import { Typography } from "@rmwc/typography";
import { Link } from "react-router-dom";

export default function TransfersDataTableError(props: {
  children: React.ReactNode;
}) {
  return <div style={{ marginTop: 30 }}>{props.children}</div>;
}

export const TransfersErrorNoCloset = () => {
  return (
    <TransfersDataTableError>
      <Typography use="body1">No active closet.</Typography>
      <br />
      <br />
      <Typography use="body2">
        Scan/add products on the
        <br />
        <Link to="/closet">Closet page</Link>
      </Typography>
    </TransfersDataTableError>
  );
};

export const TransfersErrorNoPending = () => {
  return (
    <TransfersDataTableError>
      <Typography use="subtitle1">No pending transfers.</Typography>
      {/* <br />
      <Typography use="body2">Please check back later.</Typography> */}
    </TransfersDataTableError>
  );
};

export const TransfersErrorNoDisputed = () => {
  return (
    <TransfersDataTableError>
      <Typography use="subtitle1">No disputed transfers.</Typography>
      {/* <br />
      <Typography use="body2">Please check back later.</Typography> */}
    </TransfersDataTableError>
  );
};
