import { useStore } from "./useStore";

const useIsAllowScan_ = useStore<boolean>(true);
export const useIsAllowScan: () => [boolean, (t: boolean) => void] = () => {
  const [isAllowScan, setIsAllowScan] = useIsAllowScan_();
  return [
    isAllowScan,
    (value: boolean) => {
      (window as any).allowScan = value;
      setIsAllowScan(value);
    }
  ];
};
