import React from "react";
import { AlterationLoading } from "../../../apis/cims/hooks/Alteration";
import { UseOktaUser } from "../../../store/useOktaUser";
import { useHistory } from "react-router-dom";
import {
  useUpdateAlterationMutation,
  useCreateClosetProductBatchMutation
} from "../../../apis/cims/generated/graphql-cims";
import { CSSProperties } from "react";
import { Fab } from "../../../rmwc/fab";
import { CircularProgress } from "@rmwc/circular-progress";
import { onClickCreateClosetProducts } from "../functions/onClickCreateClosetProducts";
import { UseAlterationProducts } from "../hooks/useAlterationProducts";
import { useStoreIsUpdateQuantity } from "../../../store/features/AddRemove/AddRemoveStore";

export const SwitchOnPolarityFab = (props: {
  tag: "Add" | "Remove";
  alterationProducts: UseAlterationProducts["alterationProducts"];
  pageLoading: boolean;
  pendingAlteration: AlterationLoading;
  activeClosetId: string | null;
  oktaUser: UseOktaUser;
  submitAlterationProductsPending: boolean;
  setSubmitAlterationProductsPending: (t: boolean) => void;
}) => {
  // third party things
  const history = useHistory();

  // mutations for submit
  const [updateAlterationMutation] = useUpdateAlterationMutation();
  const [
    createClosetProductBatchMutation
  ] = useCreateClosetProductBatchMutation();

  const [isUpdatingQuantity] = useStoreIsUpdateQuantity();

  const fabStyle: CSSProperties = {
    display: props.alterationProducts.data.length < 1 ? "none" : "flex",
    position: "fixed",
    right: 16,
    bottom: 16,
    zIndex: 99,
    backgroundColor: "var(--mdc-theme-primary)",
    borderRadius: 20,
    fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
    fontSize: "16px",
    color: "#FFFFFF",
    background: "#111111",
    letterSpacing: 0,
    textAlign: "center",
    textTransform: "capitalize"
  };
  switch (props.tag) {
    case "Remove":
      return (
        <Fab
          className="nextFab"
          onClick={() => {
            history.push("/purpose");
          }}
          disabled={isUpdatingQuantity || props.pageLoading}
          label="Next"
          style={fabStyle}
        />
      );
    case "Add":
      return (
        <Fab
          className="submitFab"
          onClick={onClickCreateClosetProducts(
            createClosetProductBatchMutation,
            props.pendingAlteration && props.pendingAlteration.data,
            props.alterationProducts.data || [],
            updateAlterationMutation,
            props.activeClosetId,
            props.oktaUser,
            props.setSubmitAlterationProductsPending,
            () => {},
            () => {
              history.goBack();
            }
          )}
          disabled={
            isUpdatingQuantity ||
            props.submitAlterationProductsPending ||
            props.pageLoading
          }
          icon={
            props.submitAlterationProductsPending || props.pageLoading ? (
              <CircularProgress />
            ) : null
          }
          label="Add"
          style={fabStyle}
        />
      );
  }
};
