import { queue } from "./rmwcDialogQueue";
import { ExecutionResult } from "@apollo/react-common";

const verbose = true;

export function errorsArrayAlert<T>(errors: (string | null)[]) {
  if (errors && errors.length > 0) {
    queue.alert({
      title: "Error",
      body: errors
    });
  }
}

export function graphqlErrorAlert<T>(result: ExecutionResult<T>) {
  if (result.errors) {
    const firstError = result.errors.find(x => x);
    queue.alert({
      title: "Network Error",
      body: firstError?.message ?? "error message missing"
    });
    if (verbose) {
      console.warn("graphqlErrorAlert", result);
    }
  } else {
    console.warn("graphqlError called without graphql error");
  }
}

export const networkErrorAlert = (guid: string, error: null) => {
  queue.alert({
    title: "Network Error",
    body: `Error Code: ${guid.substring(0, 8).toUpperCase()}`
  });
  if (verbose) {
    console.warn("networkErrorAlert", error);
  }
};
