export const MAX_UNITS = 2000;

/**
 * MAX_UNITS_TOTAL restricts how many alteration products could
 * be added to a single alteration (REMOVE alteration or ADD alteration).
 * It's done for performance reasons.
 *
 * we need to keep it in sync with "limit" defined here:
 * src/apis/cims/documents/AlterationProduct.graphql
 */
export const MAX_UNITS_TOTAL = 2000;

export const MAX_AWS_PARSABLE_DATE = new Date(99999999999999).toISOString(); // not maximum, 8640000000000000 is per spec, but that wasn't processed aws-side properly

//NARAD-1681 - Remove region filter to fix "no code ,no name" issue
export const CPG_REGIONS = [];
