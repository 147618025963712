import React, { useState } from "react";
import {
  DialogButton,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent
} from "@rmwc/dialog";
import { Typography } from "@rmwc/typography";
import "./TermsDialog.css";
import { useActiveClosetId, useUserAcceptedEula } from "../../../store/useGlobalState";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { useApolloClient } from "@apollo/react-hooks";
import { updateCloset } from "../functions/updateCloset";
import { useHistory } from "react-router-dom";
import { recordGuid, recordError } from "../../../utils/logging";
import { queue } from "../../../utils/rmwcDialogQueue";
import { createCloset } from "../functions/createCloset";

export const TermsDialog = (props: any) => {
  const [activeClosetId, setActiveClosetId] = useActiveClosetId();
  const [,setUserAcceptedEula] = useUserAcceptedEula();
  const [oktaUser,] = useOktaUserStore();
  const client = useApolloClient();
  const history = useHistory();
  const [disableAccept, setDisableAccept] = useState(false);

  const onAcceptEulaUpdateCloset = async (activeClosetId: string, hasAcceptedEula: boolean ) => {
    if (oktaUser && oktaUser.value && oktaUser.value.email) {
      try {
        const result = await updateCloset(client)(
          activeClosetId,
          oktaUser.value.email,
          hasAcceptedEula
        );
        if (result?.data?.updateCloset?.id) {
          setActiveClosetId(result.data.updateCloset.id);
          history.push('/closet');
        } else {
          recordGuid("5ed2d430-77f1-446d-88c7-8d695381a2d8");
        }
      } catch (error) {
        recordError("a8984cb2-fdd0-416f-b555-7087e3bac2e4", error);
        queue.alert({title: "Error", body: "There was a problem updating your closet. a8984cb2"});
      } finally {
        setDisableAccept(false);
      }
    } else {
      recordGuid("3f84385e-4ae6-408b-82ee-c38c513b7a5c");
    }
  };

  const onAcceptEulaCreateCloset = async (hasAcceptedEula: boolean) => {
    if (oktaUser && oktaUser.value && oktaUser.value.email) {
      try {
        const result = await createCloset(client)(oktaUser.value.email, hasAcceptedEula);
        if (result?.data?.createCloset?.id) {
          setActiveClosetId(result.data.createCloset.id);
          history.push('/closet');
        } else {
          recordGuid("718af2bb-994a-4973-b6ce-bc44308ba8e9");
        }
      } catch (error) {
        recordError("03cc831b-eead-4f55-b0ad-f9aa40baf02b", error);
        queue.alert({title: "Error", body: "There was a problem creating your closet. 03cc831b"});
      } finally {
        setDisableAccept(false);
      }
    } else {
      recordGuid("0b63a015-89e0-494a-89d4-9d7c89da3b7d");
    }
  };

  const onClickTermsAccepted = () => {
    //userAcceptedEula is used by the closet page to avoid redirecting to the ...
    //eula dialog page after history.pushing to the closet page.
    setUserAcceptedEula(true);
    setDisableAccept(true);
    const hasAcceptedEula = true;

    // "activeClosetId" represents the existence of a closet
    // If the closet exists then update it
    // Otherwise create the closet
    if (activeClosetId) {
      //update closet
      onAcceptEulaUpdateCloset(activeClosetId, hasAcceptedEula);
    } else {
      //create closet
      onAcceptEulaCreateCloset(hasAcceptedEula);
    }
  };

  const onClickTermsCanceled = () => {
    console.log("Cancel, terms were not accepted.");
    props.setIsDialogOpen(false);
  };

  return (
    <>
      <div style={{ zIndex: 9999, color: "black" }} className="terms-dialog">
        <Dialog open={props.isDialogOpen}>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: 0,
              margin: 0,
              marginRight: 10,
              marginTop: 5,
              backgroundColor: "#fff"
            }}
          >
            <span
              style={{ cursor: "pointer", color: "#8d8d8d" }}
              onClick={onClickTermsCanceled}
            >
              {" "}
              X{" "}
            </span>
          </div>
          <DialogTitle>
            <Typography use="headline3">CIMS User Agreement</Typography>
            <hr style={{ marginTop: 0 }} />
          </DialogTitle>
          <DialogContent style={{color: "black", fontFamily: "'Helvetica Neue', 'Arial', sans-serif"}}>
            <p className="terms__body">
              Please read these terms carefully before proceeding and using the
              CIMS Application.
            </p>
            <p className="terms__heading">Summary</p>

            <p className="terms__body">
              Nike collects and processes your Personal Data throughout your
              employment with Nike to manage the terms and conditions of your
              employment and to comply with legal obligations to which we are
              subject. We also process your Personal Data to pursue the
              legitimate business interests of Nike. If you have further
              questions about how your data is being handled, please consult the
              Nike Employee Privacy Policy.
            </p>

            <p className="terms__heading">What is the CIMS App?</p>

            <p className="terms__body">
              Comp Inventory Management Solution aka CIMS is used by comp
              product managers and policy holders to monitor and detect the
              chain of custody for comp products.
            </p>

            <p className="terms__heading">
              What Types of Personal Data are Collected?
            </p>

            <p className="terms__body">
              Employee Email Address: In order use CIMS, a user must
              authenticate using Nike’s Okta Single-Sign On procedure. As a
              result, your employee email address will be captured and tied to
              reports and scans created within the Application.
            </p>

            <p className="terms__heading">How do we Use the Data Collected?</p>

            <p className="terms__body">
              As mentioned above, the data collected in this App will be used to
              assist Nike with detecting the chain of custody of comp products.
              The employee email address is tied together in Reports created by
              the Special Assets Team to inform teams at Nike, including
              Managers and other Brand Protection Agents, about how and where
              comp product is being used. This ultimately helps Nike employees
              make guided decisions to maximize the impact of comp product and
              do the right thing with comp product once it’s business use is
              fulfilled.
            </p>

            <p className="terms__heading">How is this Data Shared?</p>

            <p className="terms__body">
              This data is being collected for Nike’s sole use and will not be
              shared with any other Third Parties for any purposes other than
              providing services to Nike.
            </p>
            <p>
          Please contact special.assets@nike.com with any questions or concerns.
        </p>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-around",
              margin: "0 40px 20px 40px"
            }}
          >
            <div style={{marginTop: "20px", display: "flex", width: "100%", justifyContent: "space-around"}}>

            <DialogButton
              className="dialogButton"
              style={{
                padding: "5px 20px",
                borderRadius: 20,
                border: "1px solid black",
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                textTransform: "capitalize",
                fontSize: 16,
                letterSpacing: 0,
                textAlign: "center",
                backgroundColor: "var(--mdc-theme-on-primary)",
                color: "black"
              }}
              onClick={onClickTermsCanceled}
            >
              Cancel
            </DialogButton>
            <DialogButton
              className="dialogButton"
              style={{
                padding: "5px 20px",
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                textTransform: "capitalize",
                borderRadius: 20,
                border: "1px solid black",
                fontSize: 16,
                letterSpacing: 0,
                textAlign: "center",
                backgroundColor: "var(--mdc-theme-primary)",
                color: "var(--mdc-theme-on-primary)"
              }}
              disabled={disableAccept}
              action="accept"
              isDefaultAction
              onClick={onClickTermsAccepted}
            >
              Agree
            </DialogButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
