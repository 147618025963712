import { AlterationProductStatus } from "../../../apis/cims/generated/graphql-cims";
import { FilteredAlterationProduct } from "../types/FilteredAlterationProduct";

export function filterAlterations(
  alterations: FilteredAlterationProduct[],
  status: "Pending" | "Rejected"
) {
  let alterationStatus = "";
  switch (status) {
    case "Pending":
      alterationStatus = AlterationProductStatus.Pending;
      break;
    case "Rejected":
      alterationStatus = AlterationProductStatus.Rejected;
      break;
  }

  return alterations.filter(alteration => {
    return (
      alteration &&
      alteration.products &&
      alteration.products.items &&
      alteration.products.items.some(product => {
        return product && product.status === alterationStatus;
      })
    );
  });
}
