import { selectQuantity } from "./selectQuantity";
import { Dispatch, SetStateAction } from "react";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { useApolloClient } from "@apollo/react-hooks";
import { usePendingAlteration } from "../../../apis/cims/hooks/Alteration";
import {
  AlterationPolarity,
  useCreateAlterationMutation,
  useDeleteAlterationProductMutation,
  useUpdateClosetProductMutation,
  useCreateAlterationProductBatchAddMutation,
  useRemoveCreateAlterationProductBatchMutation
} from "../../../apis/cims/generated/graphql-cims";
import { useIsAllowScan } from "../../../store/useAllowScan";
import { useActiveClosetId } from "../../../store/useGlobalState";
import { onClickQuantitySelected } from "../../AddRemove/functions/onClickQuantitySelected";

export function useAddRemoveOnQuantityChange(
  setQuantity: Dispatch<SetStateAction<number>>,
  setIsUpdatingQuantity: (t: boolean) => void,
  tag:
    | "AlterationDetailIncoming"
    | "AlterationDetailOutgoing"
    | "AlterationDetailIncomingRejected"
    | "AlterationDetailOutgoingRejected"
    | "Add"
    | "Remove"
    | "ClosetDetail",
  alterationProductTotal: number,
  upc: string,
  totalAmountOfProductsInAlteration: number,
  singleUpcAmountInCloset: number,
) {
  const [oktaUser] = useOktaUserStore();
  const client = useApolloClient();
  const [activeClosetId] = useActiveClosetId();
  const pendingAlteration = usePendingAlteration(
    activeClosetId,
    (() => {
      switch (tag) {
        case "Add":
          return AlterationPolarity.Add;
        case "Remove":
          return AlterationPolarity.Remove;
        case "AlterationDetailIncoming":
        case "AlterationDetailIncomingRejected":
        case "AlterationDetailOutgoing":
        case "AlterationDetailOutgoingRejected":
        case "ClosetDetail":
          return AlterationPolarity.Add;
      }
    })()
  );
  const [createAlteration] = useCreateAlterationMutation();
  const [deleteAlterationProduct] = useDeleteAlterationProductMutation();
  const [updateClosetProduct] = useUpdateClosetProductMutation();
  const [, setIsAllowScan] = useIsAllowScan();
  const commonInput = {
    updateClosetProduct,
    deleteAlterationProduct,
    createAlteration,
    upc,
    oktaUser,
    client,
    pendingAlteration,
    setIsUpdatingQuantity,
    setIsScanAllowed: setIsAllowScan,
    //This is the current number of alteration product in the database
    upcTotal: alterationProductTotal,
    alterationProductTotal: totalAmountOfProductsInAlteration,
    closetId: activeClosetId || "no closet id"
  };
  const [
    createAlterationProductBatchAddMutation
  ] = useCreateAlterationProductBatchAddMutation();
  const [
    removeCreateAlterationProductBatchMutation
  ] = useRemoveCreateAlterationProductBatchMutation();
  return function(
    event: React.ChangeEvent<HTMLSelectElement>
  ): Promise<"ForcesLoadingFalse"> {
    // set loading indicator visible
    setIsUpdatingQuantity(true);

    // set loading indicator invisble, using typescript compiler to help
    function ForcesLoadingFalse(): "ForcesLoadingFalse" {
      setIsUpdatingQuantity(false);
      return "ForcesLoadingFalse";
    }

    //This is the user-selected number we will attempt to update the database
    const quantity = Number(event.target.value);
    selectQuantity(setQuantity)(event);

    switch (tag) {
      case "Add":
        return onClickQuantitySelected(
          {
            tag: "Add",
            ...commonInput,
            createAlterationProductBatchAddMutation,
            alterationPolarity: AlterationPolarity.Add,
            quantity,
            singleUpcAmountInCloset
          },
          ForcesLoadingFalse
        );
      case "Remove":
        return onClickQuantitySelected(
          {
            tag: "Remove",
            ...commonInput,
            removeCreateAlterationProductBatchMutation,
            alterationPolarity: AlterationPolarity.Remove,
            quantity,
            singleUpcAmountInCloset
          },
          ForcesLoadingFalse
        );
      case "AlterationDetailIncoming":
      case "AlterationDetailIncomingRejected":
      case "AlterationDetailOutgoing":
      case "AlterationDetailOutgoingRejected":
      case "ClosetDetail":
        return Promise.resolve(ForcesLoadingFalse());
    }
  };
}
