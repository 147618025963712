import React from "react";
import { TransfersDataTable } from "./components/TransfersDataTable";
import { TransfersPageTopNav } from "./components/TransfersPageTopNav";
import slugid from "slugid";
import { RouteComponentProps } from "react-router-dom";
import {
  useTransfersIncomingQuery,
  useTransfersOutgoingQuery
} from "../../apis/cims/generated/graphql-cims";
import { transfersOutgoingRefetchQuery } from "./functions/transfersOutgoingRefetchQuery";
import { PageLayout } from "../shared/pageLayout/PageLayout";

type TransfersPageProps = Pick<RouteComponentProps<{}>, "location">;

type TransfersPageComponentPropsIncoming = {
  listType: "Incoming";
} & TransfersPageProps;

type TransfersPageComponentPropsOutgoing = {
  listType: "Outgoing";
} & TransfersPageProps;

export const TransfersPageComponent = (
  props:
    | TransfersPageComponentPropsIncoming
    | TransfersPageComponentPropsOutgoing
) => {
  const { listType } = props;

  const qsClosetMatch =
    props &&
    props.location &&
    props.location.search &&
    props.location.search.match(/closet=([^&]*)/);

  const qsCloset = qsClosetMatch && qsClosetMatch[1];

  const activeClosetId = (qsCloset && slugid.decode(qsCloset)) || null;

  const transfersQueryResultIncoming = useTransfersIncomingQuery({
    variables: {
      closetId: activeClosetId || "no active closet"
    },
    fetchPolicy: "no-cache",
    skip: !activeClosetId || listType === "Outgoing"
  });

  const transfersQueryResultOutgoing = useTransfersOutgoingQuery({
    variables: transfersOutgoingRefetchQuery(activeClosetId).variables,
    fetchPolicy: "no-cache",
    skip: !activeClosetId || listType === "Incoming"
  });

  return (
    <PageLayout>
      <TransfersPageTopNav listType={listType} {...props} />

      <div className="topAppBarHeight" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "calc(100% - 60px)",
          padding: 10,
          textAlign: "center"
        }}
      >
        {/* TODO: Replace ternary with exhaustive switch statement */}
        {listType === "Incoming" ? (
          <TransfersDataTable
            transfersQueryResult={transfersQueryResultIncoming}
            listType="Incoming"
            activeClosetId={activeClosetId}
          />
        ) : (
          <TransfersDataTable
            transfersQueryResult={transfersQueryResultOutgoing}
            listType="Outgoing"
            activeClosetId={activeClosetId}
          />
        )}
      </div>
    </PageLayout>
  );
};
