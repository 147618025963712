import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useProductDetailsFetchClosetId } from "../../apis/cims/hooks/Closet";
import slugid from "slugid";
import { useApolloClient } from "@apollo/react-hooks";
import { Snackbar, SnackbarAction } from "@rmwc/snackbar";
import { useShowTransferProductSubmitSuccess } from "../../store/useGlobalState";
import { useFetchProductsInfo } from "../../apis/cpg/hooks/ProductPage";
import { useAlterationDetailQuery } from "../../apis/cims/generated/graphql-cims";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { SwitchOnPropTagCpgProducts } from "./components/SwitchOnPropTagCpgProducts";
import { enforceUserClosetIdRedirect } from "./functions/enforceUserClosetIdRedirect";
import { alterationDetailRefetchQuery } from "../shared/functions/alterationDetailRefetchQuery";
import "./TransferDetailPage.css";
import { PageLayout } from "../shared/pageLayout/PageLayout";

type CommonProps = Pick<RouteComponentProps, "history">;

export type IncomingProps = {
  tag: "Incoming";
} & CommonProps;

export type OutgoingProps = {
  tag: "Outgoing";
} & CommonProps;

export type IncomingRejectedProps = {
  tag: "IncomingRejected";
} & CommonProps;

export type OutgoingRejectedProps = {
  tag: "OutgoingRejected";
} & CommonProps;

export const AlterationDetailPage = (
  props:
    | IncomingProps
    | OutgoingProps
    | IncomingRejectedProps
    | OutgoingRejectedProps
) => {
  const search = new URLSearchParams(useLocation().search);

  const encodedAlterationId = search.get("id");
  const decodedAlterationId = encodedAlterationId
    ? slugid.decode(encodedAlterationId)
    : null;
  const alterationIdShortened = decodedAlterationId
    ? decodedAlterationId.split("-")[0]
    : null;

  const alterationDetailResult = useAlterationDetailQuery({
    variables: alterationDetailRefetchQuery(decodedAlterationId).variables,
    fetchPolicy: "no-cache"
  });

  const alterationProducts =
    (alterationDetailResult.data &&
      alterationDetailResult.data.listAlterationProductsByAlterationId &&
      alterationDetailResult.data.listAlterationProductsByAlterationId.items) ||
    [];

  const client = useApolloClient();
  const productsInfo = useFetchProductsInfo(
    { items: alterationProducts, loading: alterationDetailResult.loading },
    client
  );

  const [
    isShowSuccessSnackbar,
    setIsShowSuccessSnackbar
  ] = useShowTransferProductSubmitSuccess();

  // Get the toCloset ID of the transfer and the current authenticated user.
  // If the two don't match, redirect to the outgoing version of the transfer page.
  const activeUserClosetId = useProductDetailsFetchClosetId();
  const alterationToCloset =
    alterationDetailResult.data &&
    alterationDetailResult.data.getAlteration &&
    alterationDetailResult.data.getAlteration.toCloset &&
    alterationDetailResult.data.getAlteration.toCloset.id;
  const currentPage =
    props.history.location.pathname + props.history.location.search;
  if (alterationToCloset) {
    enforceUserClosetIdRedirect(
      currentPage,
      props,
      activeUserClosetId,
      alterationToCloset
    );
  }

  return (
    <PageLayout>
      <TopAppBarShared
        tag="OnClickBack"
        title={`Transfer ID: ${
          alterationIdShortened ? alterationIdShortened : "NONE"
        }`}
      />
      <SwitchOnPropTagCpgProducts {...props} productsInfo={productsInfo} />
      <Snackbar
        open={isShowSuccessSnackbar}
        onClose={() => {
          setIsShowSuccessSnackbar(false);
        }}
        message="Successful submission"
        action={<SnackbarAction label="Ok" />}
      />
    </PageLayout>
  );
};
