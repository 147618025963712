import React from "react";
import { AlterationState, alterationState } from "./functions/alterationState";
import { useOktaUserStore } from "../../store/useOktaUser";
import {
  useShowAlterationSubmitSuccess,
  useActiveClosetId,
  useUserAcceptedEula
} from "../../store/useGlobalState";
import { useDrawerOpen } from "../../store/useGlobalState";
import { usePendingAlteration } from "../../apis/cims/hooks/Alteration";
import { AlterationPolarity } from "../../apis/cims/generated/graphql-cims";
import { useUnsupportedBrowser } from "./hooks/useUnsupportedBrowser";
import { useClosetProductsSummary } from "../shared/hooks/useClosetProductsSummary";
import { ClosetPageView } from "./ClosetPageView";

ClosetPage.displayName = "ClosetPage";

export function ClosetPage() {
  const [userAcceptedEula] = useUserAcceptedEula();
  const [oktaUser] = useOktaUserStore();
  const [activeClosetId] = useActiveClosetId();
  const userEmail = oktaUser.value?.email;
  const [drawerOpen, setDrawerOpen] = useDrawerOpen();

  const {
    closetProductsSummarized,
    closetProductDetails,
    closet
  } = useClosetProductsSummary();

  const pendingAlterationAdd = usePendingAlteration(
    (closet.data && closet.data.id) || null,
    AlterationPolarity.Add
  );

  const alterationStateAdd: AlterationState = alterationState(
    pendingAlterationAdd
  );

  const [
    isShowSuccessSnackbar,
    setIsShowSuccessSnackbar
  ] = useShowAlterationSubmitSuccess();

  useUnsupportedBrowser();

  const hasAcceptedEula = closet?.data?.hasAcceptedEula || userAcceptedEula;
  const newUser = !closet.loading && closet.data === null;
  const canSeeProducts = Boolean(activeClosetId || newUser);
  const isClosetEmpty =
    (closetProductDetails?.value?.items === null) ||
    (closetProductDetails.loading === false &&
      closetProductDetails?.value?.items?.length < 1);
  const isNoCloset =
    closet.loading === false && closet.data === null;
  const isClosetEmptyOrNoCloset = isClosetEmpty || isNoCloset
  const isClosetDetailsLoading = closet.loading ||
  !closetProductDetails ||
  closetProductDetails.loading

  return (
    <ClosetPageView
      isClosetEmptyOrNoCloset={isClosetEmptyOrNoCloset}
      isClosetDetailsLoading={isClosetDetailsLoading}
      alterationStateAdd={alterationStateAdd}
      canSeeProducts={canSeeProducts}
      closet={closet}
      closetProductDetails={closetProductDetails}
      closetProductsSummarized={closetProductsSummarized}
      drawerOpen={drawerOpen}
      hasAcceptedEula={hasAcceptedEula}
      isShowSuccessSnackbar={isShowSuccessSnackbar}
      oktaUser={oktaUser}
      setDrawerOpen={setDrawerOpen}
      setIsShowSuccessSnackbar={setIsShowSuccessSnackbar}
      userEmail={userEmail}
    />
  );
}
