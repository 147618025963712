import "@material/typography/dist/mdc.typography.css";
import {
  ClosetsByUserEmailQueryVariables,
  ClosetsByUserEmailDocument
} from "../generated/graphql-cims";
import { MAX_AWS_PARSABLE_DATE } from "../../../utils/constants";
import { DocumentNode } from "graphql";

export const closetProductsRefetchQuery = (
  email: string
): {
  query: DocumentNode;
  variables: ClosetsByUserEmailQueryVariables;
} => {
  return {
    query: ClosetsByUserEmailDocument,
    variables: {
      userEmail: email,
      createdAt: MAX_AWS_PARSABLE_DATE
    }
  };
};

export const useClosetProductsRefetchQuery = (email: string) => {
  return {
    value: closetProductsRefetchQuery(email)
  };
};
