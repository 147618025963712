import { Alteration } from "../../../apis/cims/generated/graphql-cims";

export interface UsePendingAlteration {
  loading: boolean;
  data: Pick<Alteration, "id" | "products"> | null;
}

export type AlterationState = "loading" | "empty" | "items";
export const alterationState = (
  pendingAlteration: UsePendingAlteration
): AlterationState => {
  if (pendingAlteration.loading) {
    return "loading";
  } else if (
    !(
      pendingAlteration.data &&
      pendingAlteration.data.products &&
      pendingAlteration.data.products.items &&
      pendingAlteration.data.products.items.length > 0
    )
  ) {
    return "empty";
  } else {
    return "items";
  }
};
