import React, { useState } from "react";
import { ClosetDrawer } from "../Closet/components/ClosetDrawer";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { useDrawerOpen } from "../../store/useGlobalState";
import { useProductDetailsFetchClosetId } from "../../apis/cims/hooks/Closet";

export const EULA = () => {
  const closetId = useProductDetailsFetchClosetId();
  const [drawerOpen, setDrawerOpen] = useDrawerOpen();
  const [agreedToTerms] = useState(true);

  return (
    <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
      <ClosetDrawer {...{ closetId, drawerOpen, setDrawerOpen }} />
      <TopAppBarShared
        tag="MenuOut"
        title="User Agreement"
        onClick={() => {
          agreedToTerms ? setDrawerOpen(true) : setDrawerOpen(false);
        }}
        fixed
      />
      <div style={{ padding: "1em" }}>
        <h2>CIMS User Agreement</h2>
        <p>
          Please read these terms carefully before proceeding and using the CIMS
          Application.
        </p>

        <h3>Summary</h3>

        <p>
          Nike collects and processes your Personal Data throughout your
          employment with Nike to manage the terms and conditions of your
          employment and to comply with legal obligations to which we are
          subject. We also process your Personal Data to pursue the legitimate
          business interests of Nike. If you have further questions about how
          your data is being handled, please consult the Nike Employee Privacy
          Policy.
        </p>

        <h3>What is the CIMS App?</h3>

        <p>
          Comp Inventory Management Solution aka CIMS is used by comp product
          managers and policy holders to monitor and detect the chain of custody
          for comp products.
        </p>

        <h3>What Types of Personal Data are Collected?</h3>

        <p>
          Employee Email Address: In order use CIMS, a user must authenticate
          using Nike’s Okta Single-Sign On procedure. As a result, your employee
          email address will be captured and tied to reports and scans created
          within the Application.
        </p>

        <h3>How do we Use the Data Collected?</h3>

        <p>
          As mentioned above, the data collected in this App will be used to
          assist Nike with detecting the chain of custody of comp products. The
          employee email address is tied together in Reports created by the
          Special Assets Team to inform teams at Nike, including Managers and
          other Brand Protection Agents, about how and where comp product is
          being used. This ultimately helps Nike employees make guided decisions
          to maximize the impact of comp product and do the right thing with
          comp product once it’s business use is fulfilled.
        </p>

        <h3>How is this Data Shared?</h3>

        <p>
          This data is being collected for Nike’s sole use and will not be
          shared with any other Third Parties for any purposes other than
          providing services to Nike.
        </p>

        <p>
          Please contact special.assets@nike.com with any questions or concerns.
        </p>

        <h3>Comp Product Policy</h3>
        <p>I have read and understand the <a href="https://nikehr.nike.com/?q=page/comp-product-policy-16349" target="_blank" rel="noopener noreferrer">Comp Product Policy</a>, in accordance with the Comp Product policy I agree to manage the product I am responsible for.  Any unauthorized use of comp product is subject to disciplinary action. </p>
      </div>
    </div>
  );
};
