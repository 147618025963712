import {
  AlterationDetailQueryVariables,
  AlterationDetailDocument
} from "../../../apis/cims/generated/graphql-cims";
import { MAX_AWS_PARSABLE_DATE } from "../../../utils/constants";
import { DocumentNode } from "graphql";

export const alterationDetailRefetchQuery = (
  transferId: string | null
): {
  query: DocumentNode;
  variables: AlterationDetailQueryVariables;
} => {
  return {
    query: AlterationDetailDocument,
    variables: {
      id: transferId || "no decodedAlterationId",
      createdAt: MAX_AWS_PARSABLE_DATE
    }
  };
};
