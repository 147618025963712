import { AlterationPolarity } from "../../../apis/cims/generated/graphql-cims";
import { useAlterationProducts } from "../../AddRemove/hooks/useAlterationProducts";
import { useFetchProductsInfo } from "../../../apis/cpg/hooks/ProductPage";
import { productDetailsSummary } from "../../../apis/cims/hooks/UpcProduct";
import { useApolloClient } from "@apollo/react-hooks";

export function useAlterationProductsSummary(polarity: AlterationPolarity) {
  const client = useApolloClient();

  const { alterationProducts, pendingAlteration } = useAlterationProducts(
    polarity
  );

  const productsInfo = useFetchProductsInfo(
    { items: alterationProducts.data, loading: alterationProducts.loading },
    client
  );


  const productsInfoLoadingState = {
    value: { items: productsInfo.value || [] },
    error: undefined,
    loading: productsInfo.loading
  };
  const alterationProductsSummarized = (() => {
    switch (polarity) {
      case AlterationPolarity.Add:
        return productDetailsSummary({
          ...productsInfoLoadingState,
          tag: "AlterationProductsAdd"
        });
      case AlterationPolarity.Remove:
        return productDetailsSummary({
          ...productsInfoLoadingState,
          tag: "AlterationProductsRemove"
        });
    }
  })();

  return {
    alterationProductsSummarized,
    alterationProducts,
    pendingAlteration
  };
}
