import { useOktaUserStore } from "../../../store/useOktaUser";
import {
  useCreateAlterationMutation,
  useRemoveCreateAlterationProductBatchMutation,
  AlterationPolarity,
  ClosetProduct
} from "../../../apis/cims/generated/graphql-cims";
import { useIsAllowScan } from "../../../store/useAllowScan";
import { Dispatch, SetStateAction } from "react";
import { createAlterationProductBatch } from "../../shared/functions/createAlterationProductBatch";
import { recordGuid } from "../../../utils/logging";
import { UseAlterationProducts } from "../../AddRemove/hooks/useAlterationProducts";
import { ProductDetail } from "../../../apis/cims/hooks/UpcProduct";
import { AlterationLoading } from "../../../apis/cims/hooks/Alteration";

export const useOnRowSelectedCpgProduct = (
  setIsCreatingAlterationProducts: Dispatch<SetStateAction<boolean>>,
  setIsDeletingAlterationProducts: Dispatch<SetStateAction<boolean>>,
  alterationProducts: UseAlterationProducts["alterationProducts"],
  alterationProductMatches: UseAlterationProducts["alterationProducts"]["data"],
  // TODO: Replace Partial with Pick
  productDetail: Partial<ProductDetail<ClosetProduct>> | null | undefined,
  pendingAlteration: AlterationLoading,
  closetId: string
) => {
  const [oktaUser] = useOktaUserStore();
  const [createAlteration] = useCreateAlterationMutation();
  const [, setIsAllowScan] = useIsAllowScan();

  const upc =  productDetail?.upcProduct?.upc || '';

  const [
    createAlterationProductBatchRemoveMutation
  ] = useRemoveCreateAlterationProductBatchMutation();

  const handleAdjustAlterationProductsToSpecificAmount = (adjustToAmount: number) => {
    return createAlterationProductBatch(
      {
        tag: "Remove",
        upc,
        removeCreateAlterationProductBatchMutationFn: createAlterationProductBatchRemoveMutation,
        units: adjustToAmount,
        oktaUser,
        pendingAlteration: pendingAlteration && pendingAlteration.data,
        createAlteration,
        activeClosetId: closetId,
        // Hard-coded to remove because add not supported on closet page, at least as of yet.
        alterationPolarity: AlterationPolarity.Remove,
        onStart: () => {
          setIsCreatingAlterationProducts(true);
        },
        onFinish: () => {
          setIsCreatingAlterationProducts(false);
        },
        onSuccess: () => {},
        setIsAllowScan
      },
      () => {
        return "ForcesLoadingFalse";
      }
    );
  }

  return async () => {
    if (alterationProductMatches.length === 0) {
      if (upc !== null) {
        await handleAdjustAlterationProductsToSpecificAmount(1)
      } else {
        recordGuid("7ef7d654-0df6-491e-a15a-c692c2a2e97a");
      }
    } else {
      await handleAdjustAlterationProductsToSpecificAmount(0)
    }
  };
};
