import { TransfersOutgoingDocument } from "../../../apis/cims/generated/graphql-cims";

export const transfersOutgoingRefetchQuery = (
  activeClosetId: string | null
) => {
  return {
    variables: {
      fromClosetId: activeClosetId || "no active closet"
    },
    query: TransfersOutgoingDocument
  };
};
