import { MAX_UNITS_TOTAL, MAX_UNITS } from "../../utils/constants";
import { queue } from "../../utils/rmwcDialogQueue";

const ERRORS = {
  unitsExceeded: "Units Exceeded",
  productNotInCloset: "Product Not in Closet",
  youMayOnlyRemoveItemsAlreadyInCloset:
    "You may only remove items already in your closet.",
  youCannotRemoveMoreUnitsThanInCloset:
    "You cannot remove more units than in closet.",
  youCannotAddMoreThanAmountUnitsTotal: (amount: number) =>
    `You cannot add more than ${amount} units total.`,
  youCannotAddMoreThanAmountUnitsAtATime: (amount: number) =>
    `You cannot add more than ${amount} units at a time.`,
  youCannotRemoveMoreThanAmountUnitsTotal: (amount: number) =>
    `You cannot remove more than ${amount} units total.`,
  youCannotRemoveMoreThanAmountUnitsAtATime: (amount: number) =>
    `You cannot remove more than ${amount} units at a time.`
};

/**
 *
 * @param alterationPolarity
 * @param alterationProductsTotal
 * @param singleUpcAmountInAlteration
 * @param singleUpcAmountInAlterationNew
 * @param singleUpcAmountInCloset - amount in closet, needed only for alterationPolarity = "Remove"
 */
export function checkAlterationProductsUpcLimits(
  alterationPolarity: "Add" | "Remove",
  alterationProductsTotal: number,
  singleUpcAmountInAlteration: number,
  singleUpcAmountInAlterationNew: number,
  singleUpcAmountInCloset: number
) {
  const singleUpcAmountToAdd =
    singleUpcAmountInAlterationNew - singleUpcAmountInAlteration;
  const alterationProductTotalNew =
    alterationProductsTotal + singleUpcAmountToAdd;
  const isTotalLimitExceeded = alterationProductTotalNew > MAX_UNITS_TOTAL;
  const isUpcLimitExceeded = singleUpcAmountInAlterationNew > MAX_UNITS;
  const isTryingToRemoveMoreThanExistInCloset =
    alterationPolarity === "Remove" &&
    singleUpcAmountInCloset < singleUpcAmountInAlterationNew;
  const isTryingToRemoveNonExistingInCloset =
    alterationPolarity === "Remove" && singleUpcAmountInCloset === 0;

  let errorMsgTitle = undefined;
  let errorMsg = undefined;
  if (isTotalLimitExceeded) {
    errorMsgTitle = ERRORS.unitsExceeded;
    errorMsg =
      alterationPolarity === "Add"
        ? ERRORS.youCannotAddMoreThanAmountUnitsTotal(MAX_UNITS_TOTAL)
        : ERRORS.youCannotRemoveMoreThanAmountUnitsTotal(MAX_UNITS_TOTAL);
  } else if (isUpcLimitExceeded) {
    errorMsgTitle = ERRORS.unitsExceeded;
    errorMsg =
      alterationPolarity === "Add"
        ? ERRORS.youCannotAddMoreThanAmountUnitsAtATime(MAX_UNITS)
        : ERRORS.youCannotRemoveMoreThanAmountUnitsAtATime(MAX_UNITS);
  } else if (isTryingToRemoveNonExistingInCloset) {
    errorMsgTitle = ERRORS.productNotInCloset;
    errorMsg = ERRORS.youMayOnlyRemoveItemsAlreadyInCloset;
  } else if (isTryingToRemoveMoreThanExistInCloset) {
    errorMsgTitle = ERRORS.unitsExceeded;
    errorMsg = ERRORS.youCannotRemoveMoreUnitsThanInCloset;
  }
  return {
    isValid: !errorMsg,
    errorMsg,
    errorMsgTitle
  };
}

/**
 *
 * @param alterationPolarity
 * @param alterationProductTotal
 * @param singleUpcAmountCurrent
 * @param singleUpcAmountNew
 * @param singleUpcAmountInCloset
 */
export function assertAlterationProductAmountLimits(
  alterationPolarity: "Add" | "Remove",
  alterationProductTotal: number,
  singleUpcAmountCurrent: number,
  singleUpcAmountNew: number,
  singleUpcAmountInCloset: number
) {
  const { isValid, errorMsg, errorMsgTitle } = checkAlterationProductsUpcLimits(
    alterationPolarity,
    alterationProductTotal,
    singleUpcAmountCurrent,
    singleUpcAmountNew,
    singleUpcAmountInCloset
  );

  if (!isValid) {
    const title = errorMsgTitle;
    const body = errorMsg;

    queue.alert({
      title,
      body
    });

    throw new Error(body);
  }
}

/**
 * "ADD" alteration specific
 *
 * @param alterationProductsTotal - total persisted amount of products in alteration
 * @param singleUpcAmountCurrent - current persisted quantity
 */
export function getAddAlterationQuantityRange(
  alterationProductsTotal: number,
  singleUpcAmountCurrent: number
): [number, number] {
  const alterationProductsTotalWithoutThisUpc =
    alterationProductsTotal - singleUpcAmountCurrent;
  const maxQuantityForThisUpc =
    MAX_UNITS_TOTAL - alterationProductsTotalWithoutThisUpc;
  const maxQuantityForThisUpcEffective = Math.min(
    maxQuantityForThisUpc,
    MAX_UNITS
  );

  if (maxQuantityForThisUpcEffective > 0) {
    return [0, maxQuantityForThisUpcEffective];
  }
  return [0, 0];
}

/**
 * "ADD" alteration specific
 *
 * @param alterationProductsTotal
 * @param singleUpcAmountCurrent
 * @param singleUpcAmountInCloset - amount in closet
 */
export function getRemoveAlterationQuantityRange(
  alterationProductsTotal: number,
  singleUpcAmountCurrent: number,
  singleUpcAmountInCloset: number
): [number, number] {
  const unitsMaxLimit =
    singleUpcAmountInCloset > MAX_UNITS ? MAX_UNITS : singleUpcAmountInCloset;
  const canAddMaxAmount =
    MAX_UNITS_TOTAL - (alterationProductsTotal - singleUpcAmountCurrent);
  const unitsMaxLimitEffective =
    canAddMaxAmount < unitsMaxLimit ? canAddMaxAmount : unitsMaxLimit;

  return [0, unitsMaxLimitEffective];
}
