import React from "react";

//This page allows DevOps to confirm that the intended build has been deployed.

export const MetaPage = () => {
  const buildEl = document.getElementById("build");
  return (
    <div>
      <div>Build:</div>
      <div>{(buildEl && buildEl.getAttribute("data-build")) || "error"}</div>
    </div>
  );
};
