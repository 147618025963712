import gql from 'graphql-tag';

const ProductPagePartsFragmentDoc = gql`
    fragment ProductPageParts on ProductPage {
      cursor {
        after
        __typename
      }
  products {
    id
    productCode
    region
    copy {
      primaryName
      secondaryName
    }
    sizes {
      upc
      description
      productSizeDisplays {
        sizeDisplay
        sizeTypeDescription
      }
    }
    genderAge
    category
  }
}
    `;

    // added size variable as 200 as per latest CPG query,the default size is 10 that doesnt work for bulk import products
export const GET_BULK_PRODUCTS_GQL = gql`
   query FindProductsByStyleColor($styleColor: [String]!) {
  findProducts(codes: $styleColor, regions: ["USA"], size: 200) {
    ...ProductPageParts
  }
  }
  ${ProductPagePartsFragmentDoc}`;


export const GET_BULK_PRODUCTS_UPC = gql`
  query FindProductsByUpc($upc: [String]!) {
findProductsByUpc(upc: $upc) {
  ...ProductPageParts
}
}
  ${ProductPagePartsFragmentDoc}`;

// set state & formatting on the user's stylecolor input
export const onClickManualEntryStyleColor = (
  userInputStyleColor: string,
  setUserInputError: any,
  findProductsByStyleColorQuery: any,
  setUserInputStyleColor: any,
  setIsSelectSizeVisible: any
) => {
  let userInputStyleColorFormatted = userInputStyleColor.toUpperCase().trim();

  userInputStyleColorFormatted = userInputStyleColorFormatted.replace(/ /g, "");

  if (userInputStyleColorFormatted.indexOf("-") < 0) {
    userInputStyleColorFormatted = userInputStyleColorFormatted.replace(
      /(\w{6})(\w{3})/,
      "$1-$2"
    );
  }
  if (userInputStyleColorFormatted.trim() === "") {
    return setUserInputError("Style-color required for manual entry");
  } else if (userInputStyleColorFormatted.length !== 10) {
    return setUserInputError("Style-color was incorrect format");
  }

  findProductsByStyleColorQuery({
    variables: { styleColor: userInputStyleColorFormatted }
  });

  setUserInputStyleColor(userInputStyleColorFormatted);
  setIsSelectSizeVisible(true);
  setUserInputError("");
};
