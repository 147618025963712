export function parseInputValue(str: string, min: number, max: number) {
  const { num = 0, err } = convertStrToNum(str);

  if (err) {
    return {
      err
    };
  } else {
    const { err } = checkIntegerInRange(num, min, max);
    if (err) {
      return {
        err
      };
    }
  }

  return { num, err: undefined };
}

export function getInputNumValue(inputElem: any, min: number, max: number) {
  const valueInnerCurrent = inputElem?.value || "0"
  const { num, err } = parseInputValue(valueInnerCurrent, min, max);
  return { num, err }
}

export function className(obj: {[key: string]: boolean }) {
  const arr = Object.keys(obj).reduce<string[]>((acc, key) => {
    if (obj[key]) {
      acc.push(key)
    }
    return acc
  }, [])

  if (arr.length > 0) {
    return arr.join(' ')
  }
  return undefined
}

const ERRORS = {
  SHOULD_BE_INTEGER: 'The value must be an integer',
  SHOULD_BE_EQ_OR_GREATER: 'The value must be equal or greater than {min}',
  SHOULD_BE_EQ_OR_LESS: 'The value must be equal or less than {max}'
}
function checkIntegerInRange(num: number, min: number, max: number) {
  if (Number.isInteger(num)) {
    if (num < min) {
      return {
        err: ERRORS.SHOULD_BE_EQ_OR_GREATER.replace('{min}', String(min))
      }
    } else if (num > max) {
      return {
        err: ERRORS.SHOULD_BE_EQ_OR_LESS.replace('{max}', String(max))
      }
    }
  } else {
    return {
      err: ERRORS.SHOULD_BE_INTEGER
    }
  }
  return {
    err: undefined
  };
}

function convertStrToNum(str: string) {
  const num = Number(str);
  if (isNaN(num)) {
    return {
      err: `"${str}" is not a number`
    };
  }
  return {
    num
  };
}

