import React from "react";
import { Fab } from "@rmwc/fab";
import { deleteClosetProductsOnClick } from "../functions/onClickDeleteClosetProducts";
import {
  useSubmitAlterationProductsPending,
  useShowAlterationSubmitSuccess
} from "../../../store/useGlobalState";
import { CircularProgress } from "@rmwc/circular-progress";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { useAlterationProducts } from "../../AddRemove/hooks/useAlterationProducts";
import {
  AlterationPolarity,
  useUpdateAlterationProductStatusMutation,
  useDeleteClosetProductBatchMutation,
  useUpdateAlterationMutation,
  PurposeType
} from "../../../apis/cims/generated/graphql-cims";
import { RouteComponentProps } from "react-router-dom";
import { ClosetSelected } from "../types/ClosetSelected";

type AlterationPageRouteProps = Pick<RouteComponentProps<{}>, "history">;

export const AlterationPurposeFab = (
  props: {
    activeClosetId: string | null;
    closetSelectedConfirmed: ClosetSelected;
    purposeType: PurposeType | null;
    purposeNote: string | null;
    setPurposeType: (t: PurposeType | null) => void;
    setPurposeNote: (t: string | null) => void;
  } & AlterationPageRouteProps
) => {
  const [oktaUser] = useOktaUserStore();

  const [
    updateAlterationProductStatusMutation
  ] = useUpdateAlterationProductStatusMutation();
  const [
    deleteClosetProductBatchMutation
  ] = useDeleteClosetProductBatchMutation();
  const [updateAlterationMutation] = useUpdateAlterationMutation();

  const { alterationProducts, pendingAlteration } = useAlterationProducts(
    AlterationPolarity.Remove
  );

  const [
    submitAlterationProductsPending,
    setSubmitAlterationProductsPending
  ] = useSubmitAlterationProductsPending();
  const loading = alterationProducts.loading || pendingAlteration.loading;


  const [, setIsShowSuccessSnackbar] = useShowAlterationSubmitSuccess();

  return (
    <Fab
      className="submitFab"
      onClick={deleteClosetProductsOnClick(
        props.closetSelectedConfirmed,
        updateAlterationProductStatusMutation,
        deleteClosetProductBatchMutation,
        pendingAlteration && pendingAlteration.data,
        alterationProducts.data || [],
        updateAlterationMutation,
        props.activeClosetId,
        oktaUser,
        setSubmitAlterationProductsPending,
        props.purposeType,
        props.purposeNote,
        () => {},
        () => {
          props.setPurposeType(null);
          props.setPurposeNote(null);
          props.history.go(-2); // go back 2 to closet page
          setIsShowSuccessSnackbar(true);
        }
      )}
      label="Remove"
      style={{
        position: "fixed",
        right: 16,
        bottom: 16,
        display: "flex",
        textTransform: "capitalize",
        zIndex: 1
      }}
      icon={
        submitAlterationProductsPending || loading ? <CircularProgress /> : null
      }
    />
  );
};
