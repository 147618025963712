import { RouteComponentProps } from "react-router-dom";
import React, { useState, Dispatch, SetStateAction } from "react";
import {
  useUpdateAlterationProductBatchMutation,
  AlterationProductStatus,
  Maybe,
  AlterationProduct
} from "../../../apis/cims/generated/graphql-cims";
import { useApolloClient } from "@apollo/react-hooks";
import { useFetchProductsInfo } from "../../../apis/cpg/hooks/ProductPage";
import { useShowTransferProductSubmitSuccess } from "../../../store/useGlobalState";
import { CircularProgress } from "@rmwc/circular-progress";
import { closetProductsRefetchQuery } from "../../../apis/cims/hooks/ClosetProduct";
import { Button } from "@rmwc/button";
import { Typography } from "@rmwc/typography";
import { selectQuantity } from "../../shared/functions/selectQuantity";
import { Select } from "../../../rmwc/select";
import { useAlterationProducts } from "../hooks/useAlterationProducts";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { alterationDetailRefetchQuery } from "../../shared/functions/alterationDetailRefetchQuery";
import "../Reconcile.css";
import { NumInput } from "../../../rmwc/numInput/numInput";

type Props = {
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  transferId: string | null;
  upcDecoded: string;
  alterationProducts: ProductPick;
  pendingItems: ProductPick;
} & Pick<RouteComponentProps<{}>, "history">;
type ProductPick = Maybe<
  Pick<
    AlterationProduct,
    "id" | "upc" | "status" | "alterationProductClosetProductId"
  >
>[];

type SelectQuantityViewPropsType = {
  isNumInput: boolean;
  pendingItems: any[];
  disabled: boolean;
  quantity: number;
  onChange: (newValue: any) => void;
};
SelectQuantityView.displayName = "SelectQuantityView";
function SelectQuantityView(props: SelectQuantityViewPropsType) {
  const { disabled, isNumInput, pendingItems, onChange, quantity } = props;
  const pending = pendingItems ? pendingItems.length : 0;

  const unitSelectOptions: any = pending
    ? Array.from(Array(pending + 1).keys()).map(x => String(x))
    : null;

  const handleChangeSelect = (e: any) => {
    onChange(e.target.value)
  }
  const handleChangeNum = (v: any) => {
    onChange(v)
  }

  if (!unitSelectOptions) {
    return <>{"Uh oh, something went wrong!"}</>;
  }

  if (isNumInput) {
    return (
      <NumInput
        min={0}
        max={pending}
        disabled={disabled}
        value={quantity}
        onChange={handleChangeNum}
      />
    )
  }

  return (
    <Select
      className="units-select"
      options={unitSelectOptions}
      disabled={disabled}
      onChange={handleChangeSelect}
      value={String(quantity)}
      style={{
        backgroundColor: "var(--mdc-theme-on-primary)",
        border: "1px solid black",
        fontSize: 16,
        fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        lineHeight: 1,
        borderRadius: 5
      }}
      data-testid="select"
    />
  );
}

export const NonCameraView = (props: Props) => {
  const [oktaUser] = useOktaUserStore();

  const { alterationProducts, alteration, loading } = useAlterationProducts(
    props.transferId
  );

  const upcProduct = alterationProducts
    .filter(product => {
      return product?.upc === props.upcDecoded;
    })
    .find(x => !!x);

  const client = useApolloClient();

  const productsInfo = useFetchProductsInfo(
    {
      items: upcProduct ? [upcProduct] : [],
      loading
    },
    client
  );

  const productDetail = productsInfo?.value?.find(x => !!x) || null;

  const [
    updateAlterationProductBatchMutation,
    updateAlterationProductBatchData
  ] = useUpdateAlterationProductBatchMutation();


  const [, setIsShowSuccessSnackbar] = useShowTransferProductSubmitSuccess();

  const [submitErrorMessage, setSubmitErrorMessage] = useState<Array<
    string
  > | null>(null);

  const slicedPendingsItems = props.pendingItems.slice(0, props.quantity) || [];

  const loadingUpdate =
    loading ||
    productsInfo.loading ||
    updateAlterationProductBatchData.loading

  return (
    <div
      style={{
        flexBasis: 1,
        flexGrow: 3,
        overflow: "auto",
        position: "relative",
        zIndex: 9,
        background: "var(--mdc-theme-on-primary)"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: loadingUpdate ? "center" : "flex-start",
          height: "100%"
        }}
      >
        {(() => {
          if (loadingUpdate) {
            return (
              <div>
                <CircularProgress size="large" />
              </div>
            );
          } else {
            return (
              <div style={{ position: "relative", padding: ".5em" }}>
                <p
                  style={{
                    paddingBottom: 10,
                    paddingTop: 7,
                    width: "60%",
                    margin: "0 auto",
                    color: "#333333"
                  }}
                ></p>

                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: 3,
                    paddingTop: 3
                  }}
                >
                  <Typography
                    use="headline6"
                    style={{
                      fontWeight: "bold",
                      lineHeight: "1.4",
                      fontFamily:
                        "'Helvetica Neue', Helvetica, Arial, sans-serif"
                    }}
                  >
                    {productDetail && productDetail.title}
                  </Typography>
                </div>

                <div
                  style={{
                    paddingTop: ".1em",
                    paddingBottom: ".5em",
                    width: "75%",
                    margin: "0 auto",
                    fontSize: 20,
                    lineHeight: 1.5
                  }}
                >
                  <p>
                    <span style={{ fontWeight: "bold" }}>Style Color: </span>
                    {productDetail?.cpgProduct?.productCode}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Category: </span>
                    {productDetail?.cpgProduct?.category}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Gender: </span>
                    {productDetail?.cpgProduct?.genderAge}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Size: </span>
                    {productDetail?.size?.description}
                  </p>
                  <div
                    className="units-reconcile"
                    style={{
                      display: "flex",
                      width: 135,
                      alignItems: "center"
                    }}
                  >
                    <span style={{ paddingRight: 10, fontWeight: 800 }}>
                      Units:
                    </span>
                    <SelectQuantityView
                      disabled={loading}
                      isNumInput={true}
                      pendingItems={props.pendingItems}
                      quantity={props.quantity}
                      onChange={newValue => {
                        const arg = {
                          target: {
                            value: newValue
                          }
                        };
                        // @ts-ignore
                        selectQuantity(props.setQuantity)(arg);
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      position: "fixed",
                      bottom: "20px",
                      padding: "0 10%"
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (props.quantity < 1) {
                          setSubmitErrorMessage(["No pending items!"]);
                        } else {
                          props.setIsDialogOpen(true);
                        }
                      }}
                      style={{
                        backgroundColor:
                          props.quantity < 1
                            ? "rgba(250,84,0,0.20)"
                            : "#FA5400",
                        color:
                          props.quantity < 1
                            ? "#CCCCCC"
                            : "var(--mdc-theme-on-primary)",
                        marginBottom: 7,
                        fontFamily:
                          "'Helvetica Neue', Helvetica, Arial, sans-serif",
                        border:
                          props.quantity < 1
                            ? "1px solid silver"
                            : "1px solid black",
                        borderRadius: 20,
                        textTransform: "capitalize",
                        fontSize: 16,
                        letterSpacing: 0,
                        textAlign: "center",
                        padding: "5px 20px",
                        margin: "0 10px"
                      }}
                      disabled={props.quantity < 1}
                    >
                      Dispute
                    </Button>
                    <Button
                      style={{
                        backgroundColor:
                          props.quantity < 1
                            ? "#F5F5F5"
                            : "var(--mdc-theme-primary)",
                        color:
                          props.quantity < 1
                            ? "#CCCCCC"
                            : "var(--mdc-theme-on-primary)",
                        marginBottom: 7,
                        fontFamily:
                          "'Helvetica Neue', Helvetica, Arial, sans-serif",
                        border:
                          props.quantity < 1
                            ? "1px solid silver"
                            : "1px solid black",
                        borderRadius: 20,
                        textTransform: "capitalize",
                        fontSize: 16,
                        letterSpacing: 0,
                        textAlign: "center",
                        padding: "5px 20px",
                        margin: "0 10px"
                      }}
                      disabled={props.quantity < 1}
                      onClick={() => {
                        if (props.quantity) {
                          const itemsToSubmit = slicedPendingsItems;
                          updateAlterationProductBatchMutation({
                            awaitRefetchQueries: true,
                            refetchQueries: [
                              alterationDetailRefetchQuery(props.transferId),
                              closetProductsRefetchQuery(
                                oktaUser?.value?.email ?? "no email"
                              )
                            ],
                            variables: {
                              input1: itemsToSubmit.map(item => {
                                return {
                                  id: item?.id || "no id",
                                  // id: "no id",
                                  status: AlterationProductStatus.Accepted,
                                  pending: false,
                                  receiverScannerUserEmail:
                                    oktaUser?.value?.email ??
                                    "NO RECEIVER EMAIL"
                                };
                              }),
                              input2: itemsToSubmit.map(item => {
                                return {
                                  // id: "none",
                                  id:
                                    item?.alterationProductClosetProductId ||
                                    "no id",
                                  closetProductClosetId:
                                    alteration?.alterationToClosetId ||
                                    "NoClosetId",
                                  closetProductAlterationProductId: "null"
                                };
                              })
                            }
                          })
                            .then(res => {
                              let errorMessages = [];
                              const updateAlterationProductBatch =
                                res?.data?.updateAlterationProductBatch || null;
                              const updateClosetProductBatch =
                                res?.data?.updateClosetProductBatch || null;
                              const updateSuccess =
                                updateAlterationProductBatch?.success &&
                                updateClosetProductBatch?.success;

                              if (updateSuccess) {
                                setIsShowSuccessSnackbar(true);
                                props.history.goBack();
                              } else {
                                updateAlterationProductBatch?.message &&
                                  errorMessages.push(
                                    updateAlterationProductBatch.message
                                  );
                                updateClosetProductBatch?.message &&
                                  errorMessages.push(
                                    updateClosetProductBatch.message
                                  );
                                errorMessages &&
                                  setSubmitErrorMessage(errorMessages);
                              }
                            })
                            .catch(err => {
                              setSubmitErrorMessage([
                                "There was a network error and your request failed."
                              ]);
                            });
                        } else {
                          setSubmitErrorMessage(["No pending items!"]);
                        }
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                </div>

                {submitErrorMessage?.map((errorMsg: string) => (
                  <p key={errorMsg} style={{ color: "red" }}>
                    {errorMsg}
                  </p>
                ))}
              </div>
            );
          }
        })()}
      </div>
    </div>
  );
};
