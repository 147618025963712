import { useState, useEffect } from "react";
import { Env } from "./useMergedApolloClient";
import { useOktaUserStore, OktaUser } from "../../store/useOktaUser";
import { recordGuid } from "../../utils/logging";
import { WithAuth } from "../../types/okta";

export function useUserIsAuthenticated(env: Env, auth: WithAuth["auth"]) {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const [oktaUser, setOktaUser] = useOktaUserStore();
  useEffect(() => {
    auth.isAuthenticated().then((isAuthenticated: any) => {
      if (isAuthenticated !== authenticated) {
        setAuthenticated(isAuthenticated);
      }
    });
  });
  useEffect(() => {
    // User must be authenticated through Okta to get user information.
    // don't refetch once user has a fetched value
    if (authenticated && !oktaUser.value) {
      auth
        .getUser()
        .then((oktaUser: OktaUser | null) => {
          if (oktaUser && oktaUser.email) {
            // redundant to useOktaUserStore getter, but insures initial lowercase
            oktaUser.email = oktaUser.email.toLowerCase().trim();
          }
          setOktaUser({ value: oktaUser, loading: false });
        })
        .catch((_error: any) => {
          recordGuid("9f3b427d-df10-4860-8e06-22a76bda611f");
          setOktaUser({ value: null, loading: false });
        });
    }
  }, [authenticated, auth, setOktaUser, oktaUser.value]);
  return { authenticated };
}
