import React from "react";
import { IconButton } from "../../../rmwc/icon-button";
import { Link, useHistory } from "react-router-dom";
import {
  TopAppBarRow,
  TopAppBarSection,
  TopAppBar,
  TopAppBarTitle
} from "../../../rmwc/top-app-bar";

type Props = {
  title: string;
  fixed?: boolean;
};

type LinkBackProps = {
  tag: "LinkBack";
  to: string;
  disabled: boolean;
} & Props;
type MenuOutProps = {
  tag: "MenuOut";
  onClick: () => void;
} & Props;
type OnClickBackProps = {
  tag: "OnClickBack";
} & Props;

export const TopAppBarShared = (
  props: LinkBackProps | MenuOutProps | OnClickBackProps
) => {
  return (
    <>
      <TopAppBar fixed={props.fixed}>
        <TopAppBarRow>
          <TopAppBarSection>
            <SwitchOnTagIconButton {...props} />
            <TopAppBarTitle
              style={{
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                paddingLeft: 5,
                fontSize: 20,
                textTransform: "capitalize"
              }}
            >
              {props.title}
            </TopAppBarTitle>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <div className="topAppBarHeight" />
    </>
  );
};

export const SwitchOnTagIconButton = (
  props: LinkBackProps | MenuOutProps | OnClickBackProps
) => {
  const history = useHistory();

  switch (props.tag) {
    case "LinkBack":
      return (
        <Link
          to={props.to}
          style={{
            textDecoration: "none",
            pointerEvents: props.disabled ? "none" : undefined
          }}
        >
          <IconButton
            icon={{ icon: "chevron_left" }}
            disabled={props.disabled}
            style={{
              color: props.disabled ? "#aaa" : "var(--mdc-theme-on-primary)",
              fontSize: 34,
              height: 58,
              padding: "0 5px"
            }}
          />
        </Link>
      );
    case "MenuOut":
      return <IconButton icon="menu" onClick={props.onClick} />;
    case "OnClickBack":
      return (
        <IconButton
          icon={{ icon: "chevron_left" }}
          onClick={() => {
            history.goBack();
          }}
          style={{
            fontSize: 34,
            height: 58,
            padding: "0 5px"
          }}
        />
      );
  }
};
