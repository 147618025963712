import React from "react";

function SvgBarcode(props) {
  return (
    <svg width="160" height="120" viewBox="0 0 37 10" {...props}>
      <defs>
        <path
          d="M19 18v-3h2v4.25a.75.75 0 01-.75.75H16v-2h3zm0-12h-3V4h4.25a.75.75 0 01.75.75V9h-2V6zM5 6v3H3V4.75A.75.75 0 013.75 4H8v2H5zm0 12h3v2H3.75a.75.75 0 01-.75-.75V15h2v3zM7.25 8h1.5a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-7.5A.25.25 0 017.25 8zm4 0h1.5a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-7.5a.25.25 0 01.25-.25zm4 0h1.5a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-7.5a.25.25 0 01.25-.25z"
          id="Barcode_svg__a"
        />
      </defs>
      <g transform="translate(-3 -4)" fill="none" fillRule="evenodd">
        <mask id="Barcode_svg__b" fill="#fff">
          <use xlinkHref="#Barcode_svg__a" />
        </mask>
        <use fill="#111" xlinkHref="#Barcode_svg__a" />
        <g mask="url(#Barcode_svg__b)" fill="#111">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgBarcode;
