import React from "react";

export const selectQuantity = (
  setQuantity: React.Dispatch<React.SetStateAction<number>>
) => {
  return (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const quantity = event.target.value;
    setQuantity(Number(quantity));
  };
};
