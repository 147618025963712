import React, { useMemo, useEffect } from "react";
import "@material/list/dist/mdc.list.css";
import "@rmwc/circular-progress/circular-progress.css";
import { EmptyStatePage } from "../shared/components/EmptyStatePage";
import "@material/checkbox/dist/mdc.checkbox.css";
import "@material/form-field/dist/mdc.form-field.css";
import "./AlterationShared.css";
import { useOktaUserStore } from "../../store/useOktaUser";
import {
  useActiveClosetId,
  useSubmitAlterationProductsPending
} from "../../store/useGlobalState";
import { AlterationPolarity } from "../../apis/cims/generated/graphql-cims";
import { useQuaggaDetected } from "./hooks/useQuaggaDetected/useQuaggaDetected";
import { SwitchOnPolarityFab } from "./components/SwitchOnPolarityFab";
import { TopAppBarShared } from "../shared/components/TopAppBarShared";
import { CircularProgress } from "@rmwc/circular-progress";
import Barcode from "../../icons/Barcode";
import { ManualEntry } from "./components/ManualEntry";
import { CpgProducts } from "../shared/components/CpgProducts";
import { useClosetProductsSummary } from "../shared/hooks/useClosetProductsSummary";
import { useAlterationProductsSummary } from "../shared/hooks/useAlterationProductsSummary";
import "./AddRemove.css";
import BarcodeScanner from "../Dynamsoft/BarcodeScanner";
import { PageLayout } from "../shared/pageLayout/PageLayout";
type Props = {};
type PropsAdd = { tag: "Add" } & Props;
type PropsRemove = { tag: "Remove" } & Props;

export const AddRemovePage = (props: PropsAdd | PropsRemove) => {
  // global state
  const [oktaUser] = useOktaUserStore();
  const [activeClosetId] = useActiveClosetId();
  const [
    submitAlterationProductsPending,
    setSubmitAlterationProductsPending
  ] = useSubmitAlterationProductsPending();

  // scanning session and scanned products
  const {
    alterationProductsSummarized,
    alterationProducts,
    pendingAlteration
  } = useAlterationProductsSummary(
    (() => {
      switch (props.tag) {
        case "Add":
          return AlterationPolarity.Add;
        case "Remove":
          return AlterationPolarity.Remove;
      }
    })()
  );

  // initialize scanner and detect barcodes
  const [isScannerLoaded, setIsScannerLoaded] = React.useState(false);
  const alterationProductTotal = useMemo(() => {
    return alterationProducts.data.filter((alterationProduct: any) => {
      return (
        alterationProduct && alterationProduct.upc && alterationProduct.pending
      );
    }).length;
  }, [alterationProducts]);
  const detectedResult = useQuaggaDetected(
    props.tag,
    alterationProductsSummarized,
    alterationProductTotal,
    pendingAlteration,
    activeClosetId,
  );

  // manual entry of StyleColor
  const [userInputStyleColor, setUserInputStyleColor] = React.useState("");
  const [userInputError, setUserInputError] = React.useState("");
  const [isSelectSizeVisible, setIsSelectSizeVisible] = React.useState(false);

  const [userDeniedScanner, setUserDeniedScanner] = React.useState(false);

  const { closetProductsSummarized } = useClosetProductsSummary();

  const pageLoading =
    alterationProducts.loading ||
    pendingAlteration.loading ||
    detectedResult.loading;

  // Get the manually input stylecolor's product details
  const productDetailsBySelectedStyleColor = closetProductsSummarized.value.productDetailsByCode.find(
    item => item.code === userInputStyleColor
  );

  // Get sizes of manually input stylecolor currently in closet and filter out size if all units in closet are already in alteration list
  const sizesInClosetByInputStyleColor =
    productDetailsBySelectedStyleColor &&
    productDetailsBySelectedStyleColor.productDetailsBySize
      .filter(item => {
        const productDetailsUpc = item.productDetails[0]?.upcProduct?.upc;
        const unitsSelectedSizeInAlterationList = alterationProductsSummarized.value.productDetailsByCode
          .map((ofCode: { productDetailsBySize: any[] }) => {
            return ofCode.productDetailsBySize.filter(
              (ofSize: { productDetails: any[] }) => {
                return !!ofSize.productDetails.find(
                  x => x?.upcProduct?.upc === productDetailsUpc
                );
              }
            );
          })
          .flat(Infinity).length;

        // remove sizes if # of units is alteration exceeds available in closet
        if (Number(unitsSelectedSizeInAlterationList) >= Number(item.total)) {
          return false;
        }
        return true;
      })
      .map(item => {
        return item.size;
      });

  // on Remove page, hide the size select box and show error if manually input stylecolor's closet size is unavailable for dropdown due to max units reached and there are no more sizes left in closet for stylecolor.
  const removePageSizesUnavailableInDropdown =
    props.tag === "Remove" &&
    userInputStyleColor !== "" &&
    isSelectSizeVisible === true &&
    sizesInClosetByInputStyleColor &&
    sizesInClosetByInputStyleColor.length < 1;
  if (removePageSizesUnavailableInDropdown) {
    setUserInputError("You cannot remove more units than in closet.");
    setIsSelectSizeVisible(false);
  }

  useEffect(() => { }, [isScannerLoaded]);

  const renderContent = () => {
    if (!activeClosetId) {
      return (
        <>
          <div className="heightMinusTopAppBar" data-testid="noActiveClosetId">
            <EmptyStatePage
              tag="Text"
              headline="No closet selected"
              body="Please press the back button in the top left corner."
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div
          className="heightMinusTopAppBar"
          style={{
            display: "flex",
            flexDirection: "column-reverse"
          }}
        >
          <div
            className="addRemoveWrap"
            style={{
              backgroundColor: "white",
              flexBasis: 1,
              flexGrow: 2,
              position: "relative",
              height: "60%"
            }}
          >
            {(() => {
              if (pageLoading) {
                return (
                  <div
                    className="addRemove"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%"
                    }}
                  >
                    <CircularProgress size="large" />
                  </div>
                );
              } else if (
                alterationProducts.data &&
                alterationProducts.data.length > 0
              ) {
                // console.log("alterrationProductsSummerized", alterationProductsSummarized)
                if (alterationProductsSummarized.value) {
                  const commonProps = {
                    productDetailsSummarized: alterationProductsSummarized,
                    oktaUser,
                    isFixedHeader: false as false
                  };
                  switch (props.tag) {
                    case "Add":
                      return <CpgProducts tag="Add" {...commonProps} />;
                    case "Remove":
                      return <CpgProducts tag="Remove" {...commonProps} />;
                  }
                } else {
                  return (
                    <EmptyStatePage
                      tag="Text"
                      headline="No selected products"
                      body="Please select a product from the previous page."
                    />
                  );
                }
              } else {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                  >
                    <div
                      style={{
                        margin: "auto",
                        width: "80px"
                      }}
                    >
                      <Barcode />
                    </div>
                    <EmptyStatePage
                      tag="Text"
                      headline="Scan Product or Enter Style-Color Code."
                      body="Products will appear here."
                    />
                  </div>
                );
              }
            })()}
          </div>
          <ManualEntry
            {...{
              userInputError,
              isSelectSizeVisible,
              userInputStyleColor,
              setUserInputError,
              sizesInClosetByInputStyleColor,
              setIsSelectSizeVisible,
              setUserInputStyleColor,
              tag: props.tag
            }}
          />
          {isScannerLoaded ? (
            <BarcodeScanner setIsScannerLoaded={setIsScannerLoaded} />
          ) : (
            <div>
              <div
                style={{
                  top: userDeniedScanner ? "18%" : "25%",
                  position: "fixed",
                  width: "100%",
                  textAlign: "center",
                  zIndex: -1
                }}
              >
                {!userDeniedScanner && (
                  <CircularProgress
                    size="large"
                    style={{
                      visibility: userDeniedScanner ? "hidden" : "visible",
                      zIndex: 9999
                    }}
                  />
                )}

                {userDeniedScanner && (
                  <div style={{ zIndex: 9999 }}>
                    <p>Scanner permission was denied.</p>
                    <p>Reload the page to use the barcode scanner.</p>
                  </div>
                )}
              </div>

              <BarcodeScanner
                style={{
                  visibility: userDeniedScanner ? "hidden" : "visible",
                  display: "none"
                }}
                setUserDeniedScanner={setUserDeniedScanner}
                setIsScannerLoaded={setIsScannerLoaded}
              />
            </div>
          )}
        </div>
        <div
          style={{
            height: 72,
            width: "100%",
            position: "fixed",
            bottom: 0,
            zIndex: 99,
            background: "white"
          }}
        >
          <SwitchOnPolarityFab
            {...{
              tag: props.tag,
              alterationProducts,
              pageLoading,
              pendingAlteration,
              activeClosetId,
              oktaUser,
              submitAlterationProductsPending,
              setSubmitAlterationProductsPending
            }}
          />
        </div>
      </>
    );
  };

  return (
    <PageLayout>
      <TopAppBarShared
        tag="LinkBack"
        to="/closet"
        disabled={submitAlterationProductsPending}
        title={(() => {
          switch (props.tag) {
            case "Add":
              return "Add";
            case "Remove":
              return "Remove";
          }
        })()}
      />
      {renderContent()}
    </PageLayout>
  );
};
