import ApolloClient from "apollo-client";
import {
  CreateClosetMutation,
  CreateClosetMutationVariables,
  CreateClosetDocument
} from "../../../apis/cims/generated/graphql-cims";
import { closetProductsRefetchQuery } from "../../../apis/cims/hooks/ClosetProduct";
import { recordError } from "../../../utils/logging";
import uuidv4 from "uuid/v4";

export const createCloset = (client: ApolloClient<any>) => {
  return async (userEmail: string, hasAcceptedEula?: boolean) => {
    const newClosetId = uuidv4();
    return client
      .mutate<CreateClosetMutation, CreateClosetMutationVariables>({
        mutation: CreateClosetDocument,
        variables: {
          id: newClosetId,
          userEmail: userEmail,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          hasAcceptedEula: hasAcceptedEula,
          acceptedEulaAt: new Date().toISOString()
        } as CreateClosetMutationVariables,
        refetchQueries: [closetProductsRefetchQuery(userEmail)]
      })
      .then(result => {
        if (result) {
          return result;
        } else {
          recordError("1c382aed-c7b8-487f-a271-c2e0341fff32", result);
          return null;
        }
      })
      .catch(error => {
        recordError("a1d398e9-e89f-4c3f-a962-48aac1f23adf", error);
        return null;
      });
  };
};
