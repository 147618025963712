import React from "react";
import TransfersDataContent from "./TransfersDataTableContent";
import { CircularProgress } from "@rmwc/circular-progress";
import { Typography } from "@rmwc/typography";
import {
  TransfersErrorNoCloset,
  TransfersErrorNoPending
} from "./TransfersDataTableError";
import { EmptyStateDataTableRow } from "../../shared/components/EmptyStateDataTableRow";
import { QueryResult } from "@apollo/react-common";
import {
  TransfersIncomingQuery,
  TransfersIncomingQueryVariables,
  TransfersOutgoingQuery,
  TransfersOutgoingQueryVariables
} from "../../../apis/cims/generated/graphql-cims";
import { switchOnListTypeAlterations } from "../functions/switchOnListTypeAlterations";
import { TableWrapper } from "./TableWrapper";

type TransfersDataProps = {
  activeClosetId: string | null;
};

export type TransfersDataIncomingProps = {
  listType: "Incoming";
  transfersQueryResult: QueryResult<
    TransfersIncomingQuery,
    TransfersIncomingQueryVariables
  >;
} & TransfersDataProps;

export type TransfersDataOutgoingProps = {
  listType: "Outgoing";
  transfersQueryResult: QueryResult<
    TransfersOutgoingQuery,
    TransfersOutgoingQueryVariables
  >;
} & TransfersDataProps;

export const TransfersDataTable = (
  props: TransfersDataIncomingProps | TransfersDataOutgoingProps
) => {
  const { listType } = props;
  const propsFromListType = switchOnListTypeAlterations(props);

  const items = propsFromListType.items;
  const itemsPendingReversed = [...items.pending].reverse(); // reverses server sort to desc by submittedAt date
  const itemsDisputedReversed = [...items.disputed].reverse();

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: "scroll",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 3,
          textAlign: "center",
          marginTop: 60
        }}
      >
        {/* TODO: Replace nested ternary with statement */}
        {props.transfersQueryResult.loading ? (
          <TableWrapper
            statusType="Pending"
            listType={listType}
            loading={props.transfersQueryResult.loading}
          >
            <EmptyStateDataTableRow>
              <Typography use="subtitle1">Loading transfers...</Typography>
              <br />
              <CircularProgress size="large" />
            </EmptyStateDataTableRow>
          </TableWrapper>
        ) : !props.activeClosetId ||
          props.transfersQueryResult.data === null ? (
          <TransfersErrorNoCloset />
        ) : (
          <>
            {/* {itemsPendingReversed.length > 0 && ( */}
            <TableWrapper
              statusType="Pending"
              listType={listType}
              loading={props.transfersQueryResult.loading}
            >
              <TransfersDataContent
                items={itemsPendingReversed}
                listType={listType}
                disputed={false}
              />
            </TableWrapper>
            {/* )} */}
            {itemsPendingReversed.length < 1 && <TransfersErrorNoPending />}

            {itemsDisputedReversed.length > 0 && (
              <TableWrapper
                statusType="Disputed"
                listType={listType}
                loading={props.transfersQueryResult.loading}
              >
                <TransfersDataContent
                  items={itemsDisputedReversed}
                  listType={listType}
                  disputed={true}
                />
              </TableWrapper>
            )}
            {/* {itemsDisputedReversed.length < 1 && <TransfersErrorNoDisputed />} */}
          </>
        )}
      </div>
    </div>
  );
};
