import React, { useState } from "react";
import { Select } from "@rmwc/select";
import { NumInput } from "../../../rmwc/numInput/numInput";
import {
  getUnitSelectRange,
  switchOnPolarityUnitSelectOptions
} from "../functions/switchOnPolarityUnitSelectOptions";
import { CpgProductAddRemoveProps } from "../../shared/components/CpgProduct";
import { useAddRemoveOnQuantityChange } from "../../shared/functions/addRemoveOnQuantityChange";
import { useStoreIsUpdateQuantity } from "../../../store/features/AddRemove/AddRemoveStore";
import { useClosetProductsSummary } from "../../shared/hooks/useClosetProductsSummary";

export const SelectUnits = (
  props: CpgProductAddRemoveProps & { upc: string; isNumInput: boolean }
) => {
  const [
    isUpdatingQuantity,
    setIsUpdatingQuantity
  ] = useStoreIsUpdateQuantity();
  const [quantity, setQuantity] = useState(props.sizeValue.total);

  const { closetProductDetails } = useClosetProductsSummary();

  const arrayOfAllProductsInCloset = closetProductDetails.value.items;
  const arrayOfSpecificUpcProductsInCloset = arrayOfAllProductsInCloset
    ? arrayOfAllProductsInCloset.filter(x => x?.upcProduct?.upc === props.upc)
    : [];
  const singleUpcAmountInCloset =
    props.tag === "Add" ? 0 : arrayOfSpecificUpcProductsInCloset.length;

  const addRemoveRemoveOnQuantityChange = useAddRemoveOnQuantityChange(
    setQuantity,
    setIsUpdatingQuantity,
    props.tag,
    props.sizeValue.total,
    props.upc,
    props.totalAmountOfProductsInAlteration,
    singleUpcAmountInCloset
  );

  const handleChangeNum = React.useCallback(
    value => {
      const event = {
        target: {
          value
        }
      };
      // @ts-ignore
      return addRemoveRemoveOnQuantityChange(event);
    },
    [addRemoveRemoveOnQuantityChange]
  );

  if (props.isNumInput) {
    const alterationProductTotal = props.totalAmountOfProductsInAlteration;
    const singleUpcAmountCurrent = props.sizeValue.total;

    const [min, max] = getUnitSelectRange(
      props.tag,
      singleUpcAmountCurrent,
      alterationProductTotal,
      singleUpcAmountInCloset
    );
    return (
      <NumInput
        min={min}
        max={max}
        disabled={isUpdatingQuantity}
        value={quantity}
        onChange={handleChangeNum}
      />
    );
  }

  const unitSelectOptions = switchOnPolarityUnitSelectOptions(
    props.tag,
    arrayOfSpecificUpcProductsInCloset
  );
  return (
    <Select
      disabled={isUpdatingQuantity}
      className="units-select"
      outlined
      options={unitSelectOptions}
      value={String(quantity)}
      onChange={addRemoveRemoveOnQuantityChange}
    />
  );
};
