import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router";
import { SecureRoute, ImplicitCallback } from "@okta/okta-react";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { useUserIsAuthenticated } from "./hooks/useUserIsAuthenticated";
import { useMergedApolloClient } from "./hooks/useMergedApolloClient";
import { ThemeProvider } from "@rmwc/theme";
import { themeProviderOptions, env } from "../App";
import { DialogQueue } from "@rmwc/dialog";
import { queue } from "../utils/rmwcDialogQueue";
import { MetaPage } from "../features/Meta/MetaPage";
import { CircularProgress } from "@rmwc/circular-progress";
import { EmptyStatePage } from "../features/shared/components/EmptyStatePage";
import { ApolloProvider } from "@apollo/react-hooks";
import { ClosetPage } from "../features/Closet/ClosetPage";
import { AlterationDetailPage } from "../features/TransferDetail/TransferDetailPage";
import { AddRemovePage } from "../features/AddRemove/AddRemovePage";
import { TransfersPageComponent } from "../features/Transfers/TransfersPage";
import { AlterationPurposePage } from "../features/AlterationPurpose/AlterationPurposePage";
import { ReconcilePage } from "../features/Reconcile/ReconcilePage";
import { withAuth } from "@okta/okta-react";
import { EULA } from "../features/EULA/EULA";
import { UserAgreement } from "../features/EULA/UserAgreement";

/**
 * Provides GraphQL endpoint for main API.
 * Provides Apollo Hooks. An unofficial library for React Apollo that alleviated developer pain.
 * Provides Material Design theme.
 */
export const Routes = withAuth(function(props: any) {
  const { authenticated } = useUserIsAuthenticated(env, props.auth);

  const { client } = useMergedApolloClient(
    env,
    props.auth.getAccessToken,
    authenticated
  );
  return (
    <ThemeProvider style={{ height: "100%" }} options={themeProviderOptions}>
      <DialogQueue dialogs={queue.dialogs} preventOutsideDismiss />
      <Switch>
        <Route path="/implicit/callback" component={ImplicitCallback} />
        <Route path="/meta" component={MetaPage} />
        {(() => {
          if (client.loading) {
            return (
              <SecureRoute
                path="/"
                exact
                component={() => (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CircularProgress size="xlarge" />
                  </div>
                )}
              />
            );
          } else if (client.value === null) {
            return (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <EmptyStatePage
                  tag="Text"
                  headline="Off Network"
                  body="Please use a secured Nike network."
                />
              </div>
            );
          } else {
            return (
              <ApolloProvider client={client.value}>
                <ApolloHooksProvider client={client.value}>
                  <SecureRoute path="/eula" exact component={EULA} />
                  <SecureRoute
                    path="/accept-eula"
                    exact
                    component={UserAgreement}
                  />
                  <SecureRoute path="/closet" exact component={ClosetPage} />
                  <SecureRoute path="/" exact component={ClosetPage} />
                  <SecureRoute
                    path="/transfer-detail/incoming-pending"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <AlterationDetailPage
                        tag="Incoming"
                        history={props.history}
                      />
                    )}
                  />
                  <SecureRoute
                    path="/transfer-detail/outgoing-pending"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <AlterationDetailPage
                        tag="Outgoing"
                        history={props.history}
                      />
                    )}
                  />
                  <SecureRoute
                    path="/transfer-detail/incoming-disputed"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <AlterationDetailPage
                        tag="IncomingRejected"
                        history={props.history}
                      />
                    )}
                  />
                  <SecureRoute
                    path="/transfer-detail/outgoing-disputed"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <AlterationDetailPage
                        tag="OutgoingRejected"
                        history={props.history}
                      />
                    )}
                  />
                  <SecureRoute
                    path="/add"
                    exact
                    render={() => <AddRemovePage tag="Add" />}
                  />
                  <SecureRoute
                    path="/remove"
                    exact
                    render={() => <AddRemovePage tag="Remove" />}
                  />
                  <SecureRoute
                    path="/transfers/incoming/"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <TransfersPageComponent
                        listType="Incoming"
                        location={props.location}
                      />
                    )}
                  />
                  <SecureRoute
                    path="/transfers/outgoing/"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <TransfersPageComponent
                        listType="Outgoing"
                        location={props.location}
                      />
                    )}
                  />
                  <SecureRoute
                    path="/purpose"
                    exact
                    component={AlterationPurposePage}
                  />
                  <SecureRoute
                    path="/reconcile"
                    exact
                    render={(props: RouteComponentProps<{}>) => (
                      <ReconcilePage history={props.history} />
                    )}
                  />
                  <Route path="/meta" component={MetaPage} />
                </ApolloHooksProvider>
              </ApolloProvider>
            );
          }
        })()}
      </Switch>
    </ThemeProvider>
  );
});
