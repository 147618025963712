import { useIsAllowScan } from "../../../../store/useAllowScan";
import {
  AlterationPolarity,
  useCreateAlterationMutation,
  useCreateAlterationProductBatchAddMutation,
  useRemoveCreateAlterationProductBatchMutation
} from "../../../../apis/cims/generated/graphql-cims";
import { createAlterationProductBatch } from "../../../shared/functions/createAlterationProductBatch";
import { useOktaUserStore } from "../../../../store/useOktaUser";
import { ProductDetailsSummaryLoading } from "../../../../apis/cims/hooks/UpcProduct";
import { AlterationLoading } from "../../../../apis/cims/hooks/Alteration";
import React from "react";
import { useClosetProductsSummary } from "../../../shared/hooks/useClosetProductsSummary";
import { useOnUpcDetected } from "./useOnUpcDetected";
import { assertAlterationProductAmountLimits } from "../../../shared/limitsService";

export const useQuaggaDetected = (
  tag: "Add" | "Remove",
  alterationProductsSummaryResult: ProductDetailsSummaryLoading,
  alterationProductTotal: number,
  pendingAlteration: AlterationLoading,
  activeClosetId: string | null
): { loading: boolean } => {
  const [loading, setLoading] = React.useState(false);
  const returnUpcInputEvent = React.useCallback((): "MustAlwaysInvokeReturnUpcInputEvent" => {
    setLoading(false);
    return "MustAlwaysInvokeReturnUpcInputEvent";
  }, []);
  const returnUpcInputEventPromise = React.useCallback(() => {
    const returnValue = returnUpcInputEvent();
    return new Promise<"MustAlwaysInvokeReturnUpcInputEvent">(res => {
      res(returnValue);
    });
  }, [returnUpcInputEvent]);

  const [oktaUser] = useOktaUserStore();
  const [, setIsAllowScan] = useIsAllowScan();

  const [
    createAlterationProductBatchAddMutation
  ] = useCreateAlterationProductBatchAddMutation();
  const [
    createAlterationProductBatchRemoveMutation
  ] = useRemoveCreateAlterationProductBatchMutation();

  const [createAlteration] = useCreateAlterationMutation();

  const { closetProductsSummarized } = useClosetProductsSummary();

  const getAlterationProductTotalByUpc = React.useCallback(
    (upcFromScan: string) => {
      let alterationProductTotalByUpc = 0;
      alterationProductsSummaryResult.value.productDetailsByCode.forEach(
        ofCode => {
          ofCode.productDetailsBySize.map(ofSize =>
            ofSize.productDetails.map(detail => {
              return upcFromScan === detail?.upcProduct?.upc
                ? alterationProductTotalByUpc++
                : null;
            })
          );
        }
      );
      return alterationProductTotalByUpc;
    },
    [alterationProductsSummaryResult.value.productDetailsByCode]
  );

  const getUnitsInCloset = React.useCallback(
    (upcFromScan: string, productCode: string) => {
      const productDetailByCode =
        (closetProductsSummarized.value &&
          closetProductsSummarized.value.productDetailsByCode.find(
            productDetail => {
              return productDetail?.code === productCode;
            }
          )) ||
        null;

      const unitsInCloset = productDetailByCode?.productDetailsBySize.find(
        item => {
          return item?.productDetails[0]?.upcProduct?.upc === upcFromScan;
        }
      )?.total;

      return unitsInCloset || 0;
    },
    [closetProductsSummarized.value]
  );

  const handleUpcDetected = React.useCallback(
    (
      upcFromScan: string,
      productCode: string
    ): Promise<"MustAlwaysInvokeReturnUpcInputEvent"> => {
      setLoading(true);

      const alterationProductTotalByUpc = getAlterationProductTotalByUpc(
        upcFromScan
      );
      console.log("alterationProductTotalByUpc", upcFromScan, alterationProductTotalByUpc)
      const unitsInCloset = getUnitsInCloset(upcFromScan, productCode);
      const singleUpcAmountInAlterationNew = alterationProductTotalByUpc + 1;

      try {
        assertAlterationProductAmountLimits(
          tag,
          alterationProductTotal,
          alterationProductTotalByUpc,
          singleUpcAmountInAlterationNew,
          unitsInCloset
        );
      } catch (err) {
        return returnUpcInputEventPromise();
      }

      const commonBatchInput = {
        upc: upcFromScan,
        units: singleUpcAmountInAlterationNew,
        oktaUser: oktaUser,
        pendingAlteration: pendingAlteration && pendingAlteration.data,
        createAlteration,
        activeClosetId,
        onStart: () => { },
        onFinish: () => { },
        onSuccess: () => { },
        setIsAllowScan
      };

      // console.log("commonBatchInput ", upcFromScan, commonBatchInput)

      switch (tag) {
        case "Add":
          return createAlterationProductBatch(
            {
              tag: "Add",
              ...commonBatchInput,
              createAlterationProductBatchAddMutationFn: createAlterationProductBatchAddMutation,
              alterationPolarity: AlterationPolarity.Add
            },
            () => {
              return "ForcesLoadingFalse";
            }
          )
            .then((res) => {
              console.log("returnUpcInputEvent ", upcFromScan, res)
              return returnUpcInputEvent();
            })
            .catch(() => {
              return returnUpcInputEvent();
            });
        case "Remove":
          return createAlterationProductBatch(
            {
              tag: "Remove",
              ...commonBatchInput,
              upc: upcFromScan,
              removeCreateAlterationProductBatchMutationFn: createAlterationProductBatchRemoveMutation,
              alterationPolarity: AlterationPolarity.Remove
            },
            () => {
              return "ForcesLoadingFalse";
            }
          )
            .then(() => {
              return returnUpcInputEvent();
            })
            .catch(() => {
              return returnUpcInputEvent();
            });
      }
    },
    [
      activeClosetId,
      alterationProductTotal,
      createAlteration,
      createAlterationProductBatchAddMutation,
      createAlterationProductBatchRemoveMutation,
      getAlterationProductTotalByUpc,
      getUnitsInCloset,
      oktaUser,
      pendingAlteration,
      returnUpcInputEvent,
      returnUpcInputEventPromise,
      setIsAllowScan,
      tag
    ]
  );

  useOnUpcDetected(handleUpcDetected, setLoading);

  return { loading };
};
