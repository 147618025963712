import { useStore } from "./useStore";
import { PurposeType } from "../apis/cims/generated/graphql-cims";

export const useDrawerOpen = useStore(false);
export const usePurposeType = useStore<PurposeType | null>(null); // transfer purpose persisted across pages
export const usePurposeNote = useStore<string | null>(null);
export const useShowAlterationSubmitSuccess = useStore<boolean>(false);
export const useShowTransferProductSubmitSuccess = useStore<boolean>(false);
export const useSubmitAlterationProductsPending = useStore<boolean>(false);
export const useActiveClosetId = useStore<string | null>(null);
export const useUserAcceptedEula = useStore<boolean>(false);