import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  AWSDateTime: any,
};

export type Alteration = {
  __typename?: 'Alteration',
  id: Scalars['ID'],
  polarity: AlterationPolarity,
  pending: Scalars['Boolean'],
  products?: Maybe<ModelAlterationProductConnection>,
  closet: Closet,
  toCloset?: Maybe<Closet>,
  userEmail: Scalars['String'],
  purposeType?: Maybe<PurposeType>,
  purposeNote?: Maybe<Scalars['String']>,
  alterationClosetId: Scalars['ID'],
  alterationToClosetId?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  submittedAt?: Maybe<Scalars['AWSDateTime']>,
};


export type AlterationProductsArgs = {
  createdAt?: Maybe<ModelStringKeyConditionInput>,
  filter?: Maybe<ModelAlterationProductFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export enum AlterationPolarity {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type AlterationProduct = {
  __typename?: 'AlterationProduct',
  id: Scalars['ID'],
  pending?: Maybe<Scalars['Boolean']>,
  upc: Scalars['String'],
  alteration: Alteration,
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  closetProduct?: Maybe<ClosetProduct>,
  alterationProductClosetProductId?: Maybe<Scalars['ID']>,
  alterationProductAlterationId: Scalars['ID'],
  status?: Maybe<AlterationProductStatus>,
  statusNote?: Maybe<Scalars['String']>,
  changes?: Maybe<ModelAlterationProductChangeConnection>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
};


export type AlterationProductChangesArgs = {
  createdAt?: Maybe<ModelStringKeyConditionInput>,
  filter?: Maybe<ModelAlterationProductChangeFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type AlterationProductBatchResult = {
  __typename?: 'AlterationProductBatchResult',
  items?: Maybe<Array<Maybe<AlterationProduct>>>,
  success: Scalars['Boolean'],
  message?: Maybe<Scalars['String']>,
};

export type AlterationProductChange = {
  __typename?: 'AlterationProductChange',
  id: Scalars['ID'],
  alterationProduct: AlterationProduct,
  status: AlterationProductStatus,
  statusNote?: Maybe<Scalars['String']>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
  alterationProductChangeAlterationProductId: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
};

export enum AlterationProductStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  AutoAccepted = 'AUTO_ACCEPTED',
  Rejected = 'REJECTED',
  RejectedApproved = 'REJECTED_APPROVED'
}


export type Closet = {
  __typename?: 'Closet',
  id: Scalars['ID'],
  alterations?: Maybe<ModelAlterationConnection>,
  alterationsIncoming?: Maybe<ModelAlterationConnection>,
  products1?: Maybe<ModelClosetProductConnection>,
  userEmail: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  hasAcceptedEula?: Maybe<Scalars['Boolean']>,
  acceptedEulaAt?: Maybe<Scalars['AWSDateTime']>,
};


export type ClosetAlterationsArgs = {
  submittedAt?: Maybe<ModelStringKeyConditionInput>,
  filter?: Maybe<ModelAlterationFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ClosetAlterationsIncomingArgs = {
  filter?: Maybe<ModelAlterationFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ClosetProducts1Args = {
  filter?: Maybe<ModelClosetProductFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type ClosetProduct = {
  __typename?: 'ClosetProduct',
  id: Scalars['ID'],
  closet: Closet,
  userEmail: Scalars['String'],
  upc: Scalars['String'],
  alterationProduct?: Maybe<AlterationProduct>,
  closetProductAlterationProductId?: Maybe<Scalars['ID']>,
  closetProductClosetId: Scalars['ID'],
};

export type ClosetProductBatchResult = {
  __typename?: 'ClosetProductBatchResult',
  items?: Maybe<Array<Maybe<ClosetProduct>>>,
  success: Scalars['Boolean'],
  message?: Maybe<Scalars['String']>,
};

export type CreateAlterationInput = {
  id?: Maybe<Scalars['ID']>,
  polarity: AlterationPolarity,
  pending: Scalars['Boolean'],
  userEmail: Scalars['String'],
  purposeType?: Maybe<PurposeType>,
  purposeNote?: Maybe<Scalars['String']>,
  alterationClosetId: Scalars['ID'],
  alterationToClosetId?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  submittedAt?: Maybe<Scalars['AWSDateTime']>,
};

export type CreateAlterationProductBatchAddInput = {
  alterationId: Scalars['String'],
  upc: Scalars['String'],
  units: Scalars['Int'],
};

export type CreateAlterationProductBatchAddResult = {
  __typename?: 'CreateAlterationProductBatchAddResult',
  processedCreated?: Maybe<Array<Maybe<AlterationProduct>>>,
  processedDeleted?: Maybe<Array<Maybe<AlterationProduct>>>,
  errors?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type CreateAlterationProductChangeInput = {
  id?: Maybe<Scalars['ID']>,
  status: AlterationProductStatus,
  statusNote?: Maybe<Scalars['String']>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
  alterationProductChangeAlterationProductId: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
};

export type CreateAlterationProductInput = {
  id?: Maybe<Scalars['ID']>,
  pending?: Maybe<Scalars['Boolean']>,
  upc: Scalars['String'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  alterationProductClosetProductId?: Maybe<Scalars['ID']>,
  alterationProductAlterationId: Scalars['ID'],
  status?: Maybe<AlterationProductStatus>,
  statusNote?: Maybe<Scalars['String']>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
};

export type CreateClosetInput = {
  id?: Maybe<Scalars['ID']>,
  userEmail: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  hasAcceptedEula?: Maybe<Scalars['Boolean']>,
  acceptedEulaAt?: Maybe<Scalars['AWSDateTime']>,
};

export type CreateClosetProductBatchInput = {
  alterationId: Scalars['ID'],
};

export type CreateClosetProductBatchResult = {
  __typename?: 'CreateClosetProductBatchResult',
  processed?: Maybe<Array<Maybe<ClosetProduct>>>,
  errors?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type CreateClosetProductInput = {
  id?: Maybe<Scalars['ID']>,
  userEmail: Scalars['String'],
  upc: Scalars['String'],
  closetProductAlterationProductId?: Maybe<Scalars['ID']>,
  closetProductClosetId: Scalars['ID'],
};

export type DeleteAlterationInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteAlterationProductChangeInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteAlterationProductInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteClosetInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteClosetProductBatchInput = {
  alterationId: Scalars['ID'],
  purposeType?: Maybe<PurposeType>,
};

export type DeleteClosetProductBatchResult = {
  __typename?: 'DeleteClosetProductBatchResult',
  processed?: Maybe<Array<Maybe<ClosetProduct>>>,
  errors?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type DeleteClosetProductInput = {
  id?: Maybe<Scalars['ID']>,
};

export type ModelAlterationConnection = {
  __typename?: 'ModelAlterationConnection',
  items?: Maybe<Array<Maybe<Alteration>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelAlterationFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  polarity?: Maybe<ModelAlterationPolarityFilterInput>,
  pending?: Maybe<ModelBooleanFilterInput>,
  userEmail?: Maybe<ModelStringFilterInput>,
  purposeType?: Maybe<ModelPurposeTypeFilterInput>,
  purposeNote?: Maybe<ModelStringFilterInput>,
  alterationClosetId?: Maybe<ModelIdFilterInput>,
  alterationToClosetId?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  submittedAt?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelAlterationFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelAlterationFilterInput>>>,
  not?: Maybe<ModelAlterationFilterInput>,
};

export type ModelAlterationPendingAlterationCompositeKeyConditionInput = {
  eq?: Maybe<ModelAlterationPendingAlterationCompositeKeyInput>,
  le?: Maybe<ModelAlterationPendingAlterationCompositeKeyInput>,
  lt?: Maybe<ModelAlterationPendingAlterationCompositeKeyInput>,
  ge?: Maybe<ModelAlterationPendingAlterationCompositeKeyInput>,
  gt?: Maybe<ModelAlterationPendingAlterationCompositeKeyInput>,
  between?: Maybe<Array<Maybe<ModelAlterationPendingAlterationCompositeKeyInput>>>,
  beginsWith?: Maybe<ModelAlterationPendingAlterationCompositeKeyInput>,
};

export type ModelAlterationPendingAlterationCompositeKeyInput = {
  userEmail?: Maybe<Scalars['String']>,
  polarity?: Maybe<AlterationPolarity>,
  createdAt?: Maybe<Scalars['String']>,
};

export type ModelAlterationPolarityFilterInput = {
  eq?: Maybe<AlterationPolarity>,
  ne?: Maybe<AlterationPolarity>,
};

export type ModelAlterationProductChangeConnection = {
  __typename?: 'ModelAlterationProductChangeConnection',
  items?: Maybe<Array<Maybe<AlterationProductChange>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelAlterationProductChangeFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  status?: Maybe<ModelAlterationProductStatusFilterInput>,
  statusNote?: Maybe<ModelStringFilterInput>,
  receiverScannerUserEmail?: Maybe<ModelStringFilterInput>,
  senderScannerUserEmail?: Maybe<ModelStringFilterInput>,
  alterationProductChangeAlterationProductId?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelAlterationProductChangeFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelAlterationProductChangeFilterInput>>>,
  not?: Maybe<ModelAlterationProductChangeFilterInput>,
};

export type ModelAlterationProductConnection = {
  __typename?: 'ModelAlterationProductConnection',
  items?: Maybe<Array<Maybe<AlterationProduct>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelAlterationProductFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  pending?: Maybe<ModelBooleanFilterInput>,
  upc?: Maybe<ModelStringFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  alterationProductClosetProductId?: Maybe<ModelIdFilterInput>,
  alterationProductAlterationId?: Maybe<ModelIdFilterInput>,
  status?: Maybe<ModelAlterationProductStatusFilterInput>,
  statusNote?: Maybe<ModelStringFilterInput>,
  receiverScannerUserEmail?: Maybe<ModelStringFilterInput>,
  senderScannerUserEmail?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelAlterationProductFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelAlterationProductFilterInput>>>,
  not?: Maybe<ModelAlterationProductFilterInput>,
};

export type ModelAlterationProductStatusFilterInput = {
  eq?: Maybe<AlterationProductStatus>,
  ne?: Maybe<AlterationProductStatus>,
};

export type ModelBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>,
  eq?: Maybe<Scalars['Boolean']>,
};

export type ModelClosetConnection = {
  __typename?: 'ModelClosetConnection',
  items?: Maybe<Array<Maybe<Closet>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelClosetFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  userEmail?: Maybe<ModelStringFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  updatedAt?: Maybe<ModelStringFilterInput>,
  hasAcceptedEula?: Maybe<ModelBooleanFilterInput>,
  acceptedEulaAt?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelClosetFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelClosetFilterInput>>>,
  not?: Maybe<ModelClosetFilterInput>,
};

export type ModelClosetProductConnection = {
  __typename?: 'ModelClosetProductConnection',
  items?: Maybe<Array<Maybe<ClosetProduct>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelClosetProductFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  userEmail?: Maybe<ModelStringFilterInput>,
  upc?: Maybe<ModelStringFilterInput>,
  closetProductAlterationProductId?: Maybe<ModelIdFilterInput>,
  closetProductClosetId?: Maybe<ModelIdFilterInput>,
  and?: Maybe<Array<Maybe<ModelClosetProductFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelClosetProductFilterInput>>>,
  not?: Maybe<ModelClosetProductFilterInput>,
};

export type ModelFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>,
  eq?: Maybe<Scalars['Float']>,
  le?: Maybe<Scalars['Float']>,
  lt?: Maybe<Scalars['Float']>,
  ge?: Maybe<Scalars['Float']>,
  gt?: Maybe<Scalars['Float']>,
  between?: Maybe<Array<Maybe<Scalars['Float']>>>,
};

export type ModelIdFilterInput = {
  ne?: Maybe<Scalars['ID']>,
  eq?: Maybe<Scalars['ID']>,
  le?: Maybe<Scalars['ID']>,
  lt?: Maybe<Scalars['ID']>,
  ge?: Maybe<Scalars['ID']>,
  gt?: Maybe<Scalars['ID']>,
  contains?: Maybe<Scalars['ID']>,
  notContains?: Maybe<Scalars['ID']>,
  between?: Maybe<Array<Maybe<Scalars['ID']>>>,
  beginsWith?: Maybe<Scalars['ID']>,
};

export type ModelIntFilterInput = {
  ne?: Maybe<Scalars['Int']>,
  eq?: Maybe<Scalars['Int']>,
  le?: Maybe<Scalars['Int']>,
  lt?: Maybe<Scalars['Int']>,
  ge?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  between?: Maybe<Array<Maybe<Scalars['Int']>>>,
};

export type ModelPurposeTypeFilterInput = {
  eq?: Maybe<PurposeType>,
  ne?: Maybe<PurposeType>,
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringFilterInput = {
  ne?: Maybe<Scalars['String']>,
  eq?: Maybe<Scalars['String']>,
  le?: Maybe<Scalars['String']>,
  lt?: Maybe<Scalars['String']>,
  ge?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  notContains?: Maybe<Scalars['String']>,
  between?: Maybe<Array<Maybe<Scalars['String']>>>,
  beginsWith?: Maybe<Scalars['String']>,
};

export type ModelStringKeyConditionInput = {
  eq?: Maybe<Scalars['String']>,
  le?: Maybe<Scalars['String']>,
  lt?: Maybe<Scalars['String']>,
  ge?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  between?: Maybe<Array<Maybe<Scalars['String']>>>,
  beginsWith?: Maybe<Scalars['String']>,
};

export type Mutation = {
  __typename?: 'Mutation',
  updateClosetProductBatch?: Maybe<ClosetProductBatchResult>,
  updateAlterationProductBatch?: Maybe<AlterationProductBatchResult>,
  createClosetProductBatch?: Maybe<CreateClosetProductBatchResult>,
  deleteClosetProductBatch?: Maybe<DeleteClosetProductBatchResult>,
  createAlterationProductBatchAdd?: Maybe<CreateAlterationProductBatchAddResult>,
  removeCreateAlterationProductBatch?: Maybe<RemoveCreateAlterationProductBatchResult>,
  createAlterationProductChange?: Maybe<AlterationProductChange>,
  updateAlterationProductChange?: Maybe<AlterationProductChange>,
  deleteAlterationProductChange?: Maybe<AlterationProductChange>,
  createAlterationProduct?: Maybe<AlterationProduct>,
  updateAlterationProduct?: Maybe<AlterationProduct>,
  deleteAlterationProduct?: Maybe<AlterationProduct>,
  createAlteration?: Maybe<Alteration>,
  updateAlteration?: Maybe<Alteration>,
  deleteAlteration?: Maybe<Alteration>,
  createCloset?: Maybe<Closet>,
  updateCloset?: Maybe<Closet>,
  deleteCloset?: Maybe<Closet>,
  createClosetProduct?: Maybe<ClosetProduct>,
  updateClosetProduct?: Maybe<ClosetProduct>,
  deleteClosetProduct?: Maybe<ClosetProduct>,
};


export type MutationUpdateClosetProductBatchArgs = {
  input: Array<UpdateClosetProductBatchInput>
};


export type MutationUpdateAlterationProductBatchArgs = {
  input: Array<UpdateAlterationProductBatchInput>
};


export type MutationCreateClosetProductBatchArgs = {
  input: CreateClosetProductBatchInput
};


export type MutationDeleteClosetProductBatchArgs = {
  input: DeleteClosetProductBatchInput
};


export type MutationCreateAlterationProductBatchAddArgs = {
  input: CreateAlterationProductBatchAddInput
};


export type MutationRemoveCreateAlterationProductBatchArgs = {
  input: RemoveCreateAlterationProductBatchInput
};


export type MutationCreateAlterationProductChangeArgs = {
  input: CreateAlterationProductChangeInput
};


export type MutationUpdateAlterationProductChangeArgs = {
  input: UpdateAlterationProductChangeInput
};


export type MutationDeleteAlterationProductChangeArgs = {
  input: DeleteAlterationProductChangeInput
};


export type MutationCreateAlterationProductArgs = {
  input: CreateAlterationProductInput
};


export type MutationUpdateAlterationProductArgs = {
  input: UpdateAlterationProductInput
};


export type MutationDeleteAlterationProductArgs = {
  input: DeleteAlterationProductInput
};


export type MutationCreateAlterationArgs = {
  input: CreateAlterationInput
};


export type MutationUpdateAlterationArgs = {
  input: UpdateAlterationInput
};


export type MutationDeleteAlterationArgs = {
  input: DeleteAlterationInput
};


export type MutationCreateClosetArgs = {
  input: CreateClosetInput
};


export type MutationUpdateClosetArgs = {
  input: UpdateClosetInput
};


export type MutationDeleteClosetArgs = {
  input: DeleteClosetInput
};


export type MutationCreateClosetProductArgs = {
  input: CreateClosetProductInput
};


export type MutationUpdateClosetProductArgs = {
  input: UpdateClosetProductInput
};


export type MutationDeleteClosetProductArgs = {
  input: DeleteClosetProductInput
};

export enum PurposeType {
  AthleteService = 'ATHLETE_SERVICE',
  CharitableDonation = 'CHARITABLE_DONATION',
  Event = 'EVENT',
  GovernmentDonation = 'GOVERNMENT_DONATION',
  PhotoShoot = 'PHOTO_SHOOT',
  Recognition = 'RECOGNITION',
  Shred = 'SHRED',
  Transfer = 'TRANSFER',
  Van = 'VAN',
  Lost = 'LOST',
  Other = 'OTHER'
}

export type Query = {
  __typename?: 'Query',
  validateClosetAccess?: Maybe<Scalars['String']>,
  getAlterationProductChange?: Maybe<AlterationProductChange>,
  listAlterationProductChanges?: Maybe<ModelAlterationProductChangeConnection>,
  getAlterationProduct?: Maybe<AlterationProduct>,
  listAlterationProducts?: Maybe<ModelAlterationProductConnection>,
  getAlteration?: Maybe<Alteration>,
  listAlterations?: Maybe<ModelAlterationConnection>,
  getCloset?: Maybe<Closet>,
  listClosets?: Maybe<ModelClosetConnection>,
  getClosetProduct?: Maybe<ClosetProduct>,
  listClosetProducts?: Maybe<ModelClosetProductConnection>,
  listAlterationProductChangesByAlterationProductId?: Maybe<ModelAlterationProductChangeConnection>,
  listAlterationProductsByAlterationId?: Maybe<ModelAlterationProductConnection>,
  listPendingAlteration?: Maybe<ModelAlterationConnection>,
  listAlterationsByAlterationToClosetId?: Maybe<ModelAlterationConnection>,
  listAlterationsByAlterationFromClosetId?: Maybe<ModelAlterationConnection>,
  listClosetsByUserEmail?: Maybe<ModelClosetConnection>,
};


export type QueryValidateClosetAccessArgs = {
  email?: Maybe<Scalars['String']>
};


export type QueryGetAlterationProductChangeArgs = {
  id: Scalars['ID']
};


export type QueryListAlterationProductChangesArgs = {
  filter?: Maybe<ModelAlterationProductChangeFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetAlterationProductArgs = {
  id: Scalars['ID']
};


export type QueryListAlterationProductsArgs = {
  filter?: Maybe<ModelAlterationProductFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetAlterationArgs = {
  id: Scalars['ID']
};


export type QueryListAlterationsArgs = {
  filter?: Maybe<ModelAlterationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetClosetArgs = {
  id: Scalars['ID']
};


export type QueryListClosetsArgs = {
  filter?: Maybe<ModelClosetFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetClosetProductArgs = {
  id: Scalars['ID']
};


export type QueryListClosetProductsArgs = {
  filter?: Maybe<ModelClosetProductFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListAlterationProductChangesByAlterationProductIdArgs = {
  alterationProductChangeAlterationProductId?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<ModelStringKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelAlterationProductChangeFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListAlterationProductsByAlterationIdArgs = {
  alterationProductAlterationId?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<ModelStringKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelAlterationProductFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListPendingAlterationArgs = {
  alterationClosetId?: Maybe<Scalars['ID']>,
  userEmailPolarityCreatedAt?: Maybe<ModelAlterationPendingAlterationCompositeKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelAlterationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListAlterationsByAlterationToClosetIdArgs = {
  alterationToClosetId?: Maybe<Scalars['ID']>,
  submittedAt?: Maybe<ModelStringKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelAlterationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListAlterationsByAlterationFromClosetIdArgs = {
  alterationClosetId?: Maybe<Scalars['ID']>,
  submittedAt?: Maybe<ModelStringKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelAlterationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListClosetsByUserEmailArgs = {
  userEmail?: Maybe<Scalars['String']>,
  createdAt?: Maybe<ModelStringKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelClosetFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type RemoveCreateAlterationProductBatchInput = {
  alterationId: Scalars['String'],
  upc: Scalars['String'],
  units: Scalars['Int'],
};

export type RemoveCreateAlterationProductBatchResult = {
  __typename?: 'RemoveCreateAlterationProductBatchResult',
  processedCreated?: Maybe<Array<Maybe<AlterationProduct>>>,
  processedDeleted?: Maybe<Array<Maybe<AlterationProduct>>>,
  errors?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type Subscription = {
  __typename?: 'Subscription',
  onCreateAlterationProductChange?: Maybe<AlterationProductChange>,
  onUpdateAlterationProductChange?: Maybe<AlterationProductChange>,
  onDeleteAlterationProductChange?: Maybe<AlterationProductChange>,
  onCreateAlterationProduct?: Maybe<AlterationProduct>,
  onUpdateAlterationProduct?: Maybe<AlterationProduct>,
  onDeleteAlterationProduct?: Maybe<AlterationProduct>,
  onCreateAlteration?: Maybe<Alteration>,
  onUpdateAlteration?: Maybe<Alteration>,
  onDeleteAlteration?: Maybe<Alteration>,
  onCreateCloset?: Maybe<Closet>,
  onUpdateCloset?: Maybe<Closet>,
  onDeleteCloset?: Maybe<Closet>,
  onCreateClosetProduct?: Maybe<ClosetProduct>,
  onUpdateClosetProduct?: Maybe<ClosetProduct>,
  onDeleteClosetProduct?: Maybe<ClosetProduct>,
};

export type UpdateAlterationInput = {
  id: Scalars['ID'],
  polarity?: Maybe<AlterationPolarity>,
  pending?: Maybe<Scalars['Boolean']>,
  userEmail?: Maybe<Scalars['String']>,
  purposeType?: Maybe<PurposeType>,
  purposeNote?: Maybe<Scalars['String']>,
  alterationClosetId?: Maybe<Scalars['ID']>,
  alterationToClosetId?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  submittedAt?: Maybe<Scalars['AWSDateTime']>,
};

export type UpdateAlterationProductBatchInput = {
  id: Scalars['ID'],
  pending?: Maybe<Scalars['Boolean']>,
  upc?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  alterationProductClosetProductId?: Maybe<Scalars['ID']>,
  alterationProductAlterationId?: Maybe<Scalars['ID']>,
  status?: Maybe<AlterationProductStatus>,
  statusNote?: Maybe<Scalars['String']>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
};

export type UpdateAlterationProductChangeInput = {
  id: Scalars['ID'],
  status?: Maybe<AlterationProductStatus>,
  statusNote?: Maybe<Scalars['String']>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
  alterationProductChangeAlterationProductId?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['AWSDateTime']>,
};

export type UpdateAlterationProductInput = {
  id: Scalars['ID'],
  pending?: Maybe<Scalars['Boolean']>,
  upc?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  alterationProductClosetProductId?: Maybe<Scalars['ID']>,
  alterationProductAlterationId?: Maybe<Scalars['ID']>,
  status?: Maybe<AlterationProductStatus>,
  statusNote?: Maybe<Scalars['String']>,
  receiverScannerUserEmail?: Maybe<Scalars['String']>,
  senderScannerUserEmail?: Maybe<Scalars['String']>,
};

export type UpdateClosetInput = {
  id: Scalars['ID'],
  userEmail?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt?: Maybe<Scalars['AWSDateTime']>,
  hasAcceptedEula?: Maybe<Scalars['Boolean']>,
  acceptedEulaAt?: Maybe<Scalars['AWSDateTime']>,
};

export type UpdateClosetProductBatchInput = {
  id: Scalars['ID'],
  userEmail?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  closetProductAlterationProductId?: Maybe<Scalars['ID']>,
  closetProductClosetId?: Maybe<Scalars['ID']>,
};

export type UpdateClosetProductInput = {
  id: Scalars['ID'],
  userEmail?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  closetProductAlterationProductId?: Maybe<Scalars['ID']>,
  closetProductClosetId?: Maybe<Scalars['ID']>,
};

export type AlterationDetailQueryVariables = {
  id: Scalars['ID'],
  createdAt: Scalars['String']
};


export type AlterationDetailQuery = (
  { __typename?: 'Query' }
  & {
    getAlteration: Maybe<(
      { __typename?: 'Alteration' }
      & AlterationPartsFragment
    )>, listAlterationProductsByAlterationId: Maybe<(
      { __typename?: 'ModelAlterationProductConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & AlterationProductPartsFragment
        )>>>
      }
    )>
  }
);

export type TransferXingPartsFragment = (
  { __typename?: 'Alteration' }
  & {
    products: Maybe<(
      { __typename?: 'ModelAlterationProductConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id' | 'status'>
        )>>>
      }
    )>
  }
);

export type TransfersIncomingQueryVariables = {
  closetId: Scalars['ID']
};


export type TransfersIncomingQuery = (
  { __typename?: 'Query' }
  & {
    listAlterationsByAlterationToClosetId: Maybe<(
      { __typename?: 'ModelAlterationConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'Alteration' }
          & TransferXingPartsFragment
          & AlterationPartsFragment
        )>>>
      }
    )>
  }
);

export type TransfersOutgoingQueryVariables = {
  fromClosetId: Scalars['ID']
};


export type TransfersOutgoingQuery = (
  { __typename?: 'Query' }
  & {
    listAlterationsByAlterationFromClosetId: Maybe<(
      { __typename?: 'ModelAlterationConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'Alteration' }
          & TransferXingPartsFragment
          & AlterationPartsFragment
        )>>>
      }
    )>
  }
);

export type PendingAlterationQueryVariables = {
  alterationClosetId: Scalars['ID'],
  userEmail: Scalars['String'],
  polarity: AlterationPolarity,
  createdAt: Scalars['String']
};


export type PendingAlterationQuery = (
  { __typename?: 'Query' }
  & {
    listPendingAlteration: Maybe<(
      { __typename?: 'ModelAlterationConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'Alteration' }
          & AlterationPartsFragment
        )>>>
      }
    )>
  }
);

export type UpdateAlterationMutationVariables = {
  id: Scalars['ID'],
  pending: Scalars['Boolean'],
  purposeType?: Maybe<PurposeType>,
  purposeNote?: Maybe<Scalars['String']>,
  alterationClosetId: Scalars['ID'],
  alterationToClosetId?: Maybe<Scalars['ID']>,
  userEmail: Scalars['String'],
  polarity: AlterationPolarity,
  createdAt: Scalars['AWSDateTime'],
  submittedAt: Scalars['AWSDateTime']
};


export type UpdateAlterationMutation = (
  { __typename?: 'Mutation' }
  & {
    updateAlteration: Maybe<(
      { __typename?: 'Alteration' }
      & AlterationPartsFragment
    )>
  }
);

export type CreateAlterationMutationVariables = {
  id: Scalars['ID'],
  polarity: AlterationPolarity,
  pending: Scalars['Boolean'],
  alterationClosetId: Scalars['ID'],
  userEmail: Scalars['String'],
  createdAt: Scalars['AWSDateTime']
};


export type CreateAlterationMutation = (
  { __typename?: 'Mutation' }
  & {
    createAlteration: Maybe<(
      { __typename?: 'Alteration' }
      & AlterationPartsFragment
    )>
  }
);

export type DisputeAlterationProductsMutationVariables = {
  input: Array<UpdateAlterationProductBatchInput>
};


export type DisputeAlterationProductsMutation = (
  { __typename?: 'Mutation' }
  & {
    updateAlterationProductBatch: Maybe<(
      { __typename?: 'AlterationProductBatchResult' }
      & Pick<AlterationProductBatchResult, 'success' | 'message'>
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id'>
        )>>>
      }
    )>
  }
);

export type UpdateAlterationProductBatchMutationVariables = {
  input1: Array<UpdateAlterationProductBatchInput>,
  input2: Array<UpdateClosetProductBatchInput>
};


export type UpdateAlterationProductBatchMutation = (
  { __typename?: 'Mutation' }
  & {
    updateAlterationProductBatch: Maybe<(
      { __typename?: 'AlterationProductBatchResult' }
      & Pick<AlterationProductBatchResult, 'success' | 'message'>
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id'>
        )>>>
      }
    )>, updateClosetProductBatch: Maybe<(
      { __typename?: 'ClosetProductBatchResult' }
      & Pick<ClosetProductBatchResult, 'success' | 'message'>
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'ClosetProduct' }
          & Pick<ClosetProduct, 'id'>
        )>>>
      }
    )>
  }
);

export type UpdateAlterationProductStatusMutationVariables = {
  status: AlterationProductStatus,
  senderScannerUserEmail: Scalars['String'],
  alterationProductId: Scalars['ID'],
  alterationProductChangeId: Scalars['ID']
};


export type UpdateAlterationProductStatusMutation = (
  { __typename?: 'Mutation' }
  & {
    updateAlterationProduct: Maybe<(
      { __typename?: 'AlterationProduct' }
      & AlterationProductPartsFragment
    )>, createAlterationProductChange: Maybe<(
      { __typename?: 'AlterationProductChange' }
      & AlterationProductChangePartsFragment
    )>
  }
);

export type AlterationProductsQueryVariables = {
  alterationProductAlterationId: Scalars['ID'],
  createdAt: Scalars['String']
};


export type AlterationProductsQuery = (
  { __typename?: 'Query' }
  & {
    listAlterationProductsByAlterationId: Maybe<(
      { __typename?: 'ModelAlterationProductConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & AlterationProductPartsFragment
        )>>>
      }
    )>
  }
);

export type DeleteAlterationProductMutationVariables = {
  id: Scalars['ID']
};


export type DeleteAlterationProductMutation = (
  { __typename?: 'Mutation' }
  & {
    deleteAlterationProduct: Maybe<(
      { __typename?: 'AlterationProduct' }
      & AlterationProductPartsFragment
    )>
  }
);

export type CreateAlterationProductMutationVariables = {
  id: Scalars['ID'],
  upc: Scalars['String'],
  alterationProductAlterationId: Scalars['ID'],
  alterationProductClosetProductId: Scalars['ID'],
  pending: Scalars['Boolean']
};


export type CreateAlterationProductMutation = (
  { __typename?: 'Mutation' }
  & {
    createAlterationProduct: Maybe<(
      { __typename?: 'AlterationProduct' }
      & {
        alteration: (
          { __typename?: 'Alteration' }
          & AlterationPartsFragment
        )
      }
      & AlterationProductPartsFragment
    )>
  }
);

export type CreateAlterationProductBatchAddMutationVariables = {
  input: CreateAlterationProductBatchAddInput
};


export type CreateAlterationProductBatchAddMutation = (
  { __typename?: 'Mutation' }
  & {
    createAlterationProductBatchAdd: Maybe<(
      { __typename?: 'CreateAlterationProductBatchAddResult' }
      & Pick<CreateAlterationProductBatchAddResult, 'errors'>
      & {
        processedCreated: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id'>
        )>>>, processedDeleted: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id'>
        )>>>
      }
    )>
  }
);

export type RemoveCreateAlterationProductBatchMutationVariables = {
  input: RemoveCreateAlterationProductBatchInput
};


export type RemoveCreateAlterationProductBatchMutation = (
  { __typename?: 'Mutation' }
  & {
    removeCreateAlterationProductBatch: Maybe<(
      { __typename?: 'RemoveCreateAlterationProductBatchResult' }
      & Pick<RemoveCreateAlterationProductBatchResult, 'errors'>
      & {
        processedCreated: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id'>
        )>>>, processedDeleted: Maybe<Array<Maybe<(
          { __typename?: 'AlterationProduct' }
          & Pick<AlterationProduct, 'id'>
        )>>>
      }
    )>
  }
);

export type ListClosetsByUserEmailQueryVariables = {
  userEmail?: Maybe<Scalars['String']>
};


export type ListClosetsByUserEmailQuery = (
  { __typename?: 'Query' }
  & {
    listClosets: Maybe<(
      { __typename?: 'ModelClosetConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'Closet' }
          & ClosetPartsFragment
        )>>>
      }
    )>
  }
);

export type CreateClosetMutationVariables = {
  id: Scalars['ID'],
  userEmail: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  hasAcceptedEula?: Maybe<Scalars['Boolean']>,
  acceptedEulaAt?: Maybe<Scalars['AWSDateTime']>
};


export type CreateClosetMutation = (
  { __typename?: 'Mutation' }
  & {
    createCloset: Maybe<(
      { __typename?: 'Closet' }
      & ClosetPartsFragment
    )>
  }
);

export type UpdateClosetMutationVariables = {
  id: Scalars['ID'],
  hasAcceptedEula?: Maybe<Scalars['Boolean']>,
  acceptedEulaAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt: Scalars['AWSDateTime'],
  createdAt?: Maybe<Scalars['AWSDateTime']>
};


export type UpdateClosetMutation = (
  { __typename?: 'Mutation' }
  & {
    updateCloset: Maybe<(
      { __typename?: 'Closet' }
      & Pick<Closet, 'hasAcceptedEula' | 'acceptedEulaAt' | 'updatedAt'>
      & ClosetPartsFragment
    )>
  }
);

export type ClosetsByUserEmailQueryVariables = {
  userEmail: Scalars['String'],
  createdAt: Scalars['String']
};


export type ClosetsByUserEmailQuery = (
  { __typename?: 'Query' }
  & {
    listClosetsByUserEmail: Maybe<(
      { __typename?: 'ModelClosetConnection' }
      & {
        items: Maybe<Array<Maybe<(
          { __typename?: 'Closet' }
          & {
            products1: Maybe<(
              { __typename?: 'ModelClosetProductConnection' }
              & {
                items: Maybe<Array<Maybe<(
                  { __typename?: 'ClosetProduct' }
                  & ClosetProductPartsFragment
                )>>>
              }
            )>
          }
          & ClosetPartsFragment
        )>>>
      }
    )>
  }
);

export type UpdateClosetProductMutationVariables = {
  id: Scalars['ID'],
  closetProductAlterationProductId?: Maybe<Scalars['ID']>
};


export type UpdateClosetProductMutation = (
  { __typename?: 'Mutation' }
  & {
    updateClosetProduct: Maybe<(
      { __typename?: 'ClosetProduct' }
      & ClosetProductPartsFragment
    )>
  }
);

export type DeleteClosetProductBatchMutationVariables = {
  input: DeleteClosetProductBatchInput
};


export type DeleteClosetProductBatchMutation = (
  { __typename?: 'Mutation' }
  & {
    deleteClosetProductBatch: Maybe<(
      { __typename?: 'DeleteClosetProductBatchResult' }
      & Pick<DeleteClosetProductBatchResult, 'errors'>
      & {
        processed: Maybe<Array<Maybe<(
          { __typename?: 'ClosetProduct' }
          & Pick<ClosetProduct, 'id'>
        )>>>
      }
    )>
  }
);

export type CreateClosetProductBatchMutationVariables = {
  input: CreateClosetProductBatchInput
};


export type CreateClosetProductBatchMutation = (
  { __typename?: 'Mutation' }
  & {
    createClosetProductBatch: Maybe<(
      { __typename?: 'CreateClosetProductBatchResult' }
      & Pick<CreateClosetProductBatchResult, 'errors'>
      & {
        processed: Maybe<Array<Maybe<(
          { __typename?: 'ClosetProduct' }
          & Pick<ClosetProduct, 'id'>
        )>>>
      }
    )>
  }
);

export type AlterationPartsFragment = (
  { __typename?: 'Alteration' }
  & Pick<Alteration, 'id' | 'polarity' | 'pending' | 'alterationClosetId' | 'userEmail' | 'createdAt' | 'submittedAt' | 'alterationToClosetId' | 'purposeType'>
  & {
    toCloset: Maybe<(
      { __typename?: 'Closet' }
      & Pick<Closet, 'id' | 'userEmail'>
    )>
  }
);

export type AlterationProductPartsFragment = (
  { __typename?: 'AlterationProduct' }
  & Pick<AlterationProduct, 'id' | 'pending' | 'upc' | 'createdAt' | 'alterationProductClosetProductId' | 'alterationProductAlterationId' | 'status'>
);

export type AlterationProductChangePartsFragment = (
  { __typename?: 'AlterationProductChange' }
  & Pick<AlterationProductChange, 'id'>
);

export type ClosetPartsFragment = (
  { __typename?: 'Closet' }
  & Pick<Closet, 'id' | 'userEmail' | 'hasAcceptedEula'>
);

export type ClosetProductPartsFragment = (
  { __typename?: 'ClosetProduct' }
  & Pick<ClosetProduct, 'id' | 'upc' | 'closetProductAlterationProductId'>
  & {
    alterationProduct: Maybe<(
      { __typename?: 'AlterationProduct' }
      & AlterationProductPartsFragment
    )>
  }
);

export const TransferXingPartsFragmentDoc = gql`
    fragment TransferXingParts on Alteration {
  products(limit: 9999) {
    items {
      id
      status
    }
  }
}
    `;
export const AlterationPartsFragmentDoc = gql`
    fragment AlterationParts on Alteration {
  id
  polarity
  pending
  alterationClosetId
  userEmail
  polarity
  createdAt
  submittedAt
  alterationToClosetId
  purposeType
  toCloset {
    id
    userEmail
  }
}
    `;
export const AlterationProductChangePartsFragmentDoc = gql`
    fragment AlterationProductChangeParts on AlterationProductChange {
  id
}
    `;
export const ClosetPartsFragmentDoc = gql`
    fragment ClosetParts on Closet {
  id
  userEmail
  hasAcceptedEula
}
    `;
export const AlterationProductPartsFragmentDoc = gql`
    fragment AlterationProductParts on AlterationProduct {
  id
  pending
  upc
  createdAt
  alterationProductClosetProductId
  alterationProductAlterationId
  status
}
    `;
export const ClosetProductPartsFragmentDoc = gql`
    fragment ClosetProductParts on ClosetProduct {
  id
  upc
  closetProductAlterationProductId
  alterationProduct {
    ...AlterationProductParts
  }
}
    ${AlterationProductPartsFragmentDoc}`;
export const AlterationDetailDocument = gql`
    query AlterationDetail($id: ID!, $createdAt: String!) {
  getAlteration(id: $id) {
    ...AlterationParts
  }
  listAlterationProductsByAlterationId(alterationProductAlterationId: $id, createdAt: {lt: $createdAt}, limit: 9999) {
    items {
      ...AlterationProductParts
    }
  }
}
    ${AlterationPartsFragmentDoc}
${AlterationProductPartsFragmentDoc}`;

/**
 * __useAlterationDetailQuery__
 *
 * To run a query within a React component, call `useAlterationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlterationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlterationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useAlterationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AlterationDetailQuery, AlterationDetailQueryVariables>) {
  return ApolloReactHooks.useQuery<AlterationDetailQuery, AlterationDetailQueryVariables>(AlterationDetailDocument, baseOptions);
}
export function useAlterationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlterationDetailQuery, AlterationDetailQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<AlterationDetailQuery, AlterationDetailQueryVariables>(AlterationDetailDocument, baseOptions);
}
export type AlterationDetailQueryHookResult = ReturnType<typeof useAlterationDetailQuery>;
export type AlterationDetailLazyQueryHookResult = ReturnType<typeof useAlterationDetailLazyQuery>;
export const TransfersIncomingDocument = gql`
    query TransfersIncoming($closetId: ID!) {
  listAlterationsByAlterationToClosetId(alterationToClosetId: $closetId, submittedAt: {lt: "5138-11-16T09:46:39.999Z"}, limit: 9999, filter: {purposeType: {eq: TRANSFER}}) {
    items {
      ...TransferXingParts
      ...AlterationParts
    }
  }
}
    ${TransferXingPartsFragmentDoc}
${AlterationPartsFragmentDoc}`;

/**
 * __useTransfersIncomingQuery__
 *
 * To run a query within a React component, call `useTransfersIncomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersIncomingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersIncomingQuery({
 *   variables: {
 *      closetId: // value for 'closetId'
 *   },
 * });
 */
export function useTransfersIncomingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransfersIncomingQuery, TransfersIncomingQueryVariables>) {
  return ApolloReactHooks.useQuery<TransfersIncomingQuery, TransfersIncomingQueryVariables>(TransfersIncomingDocument, baseOptions);
}
export function useTransfersIncomingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransfersIncomingQuery, TransfersIncomingQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<TransfersIncomingQuery, TransfersIncomingQueryVariables>(TransfersIncomingDocument, baseOptions);
}
export type TransfersIncomingQueryHookResult = ReturnType<typeof useTransfersIncomingQuery>;
export type TransfersIncomingLazyQueryHookResult = ReturnType<typeof useTransfersIncomingLazyQuery>;
export const TransfersOutgoingDocument = gql`
    query TransfersOutgoing($fromClosetId: ID!) {
  listAlterationsByAlterationFromClosetId(alterationClosetId: $fromClosetId, submittedAt: {lt: "5138-11-16T09:46:39.999Z"}, limit: 9999, filter: {purposeType: {eq: TRANSFER}}) {
    items {
      ...TransferXingParts
      ...AlterationParts
    }
  }
}
    ${TransferXingPartsFragmentDoc}
${AlterationPartsFragmentDoc}`;

/**
 * __useTransfersOutgoingQuery__
 *
 * To run a query within a React component, call `useTransfersOutgoingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersOutgoingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersOutgoingQuery({
 *   variables: {
 *      fromClosetId: // value for 'fromClosetId'
 *   },
 * });
 */
export function useTransfersOutgoingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransfersOutgoingQuery, TransfersOutgoingQueryVariables>) {
  return ApolloReactHooks.useQuery<TransfersOutgoingQuery, TransfersOutgoingQueryVariables>(TransfersOutgoingDocument, baseOptions);
}
export function useTransfersOutgoingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransfersOutgoingQuery, TransfersOutgoingQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<TransfersOutgoingQuery, TransfersOutgoingQueryVariables>(TransfersOutgoingDocument, baseOptions);
}
export type TransfersOutgoingQueryHookResult = ReturnType<typeof useTransfersOutgoingQuery>;
export type TransfersOutgoingLazyQueryHookResult = ReturnType<typeof useTransfersOutgoingLazyQuery>;
export const PendingAlterationDocument = gql`
    query PendingAlteration($alterationClosetId: ID!, $userEmail: String!, $polarity: AlterationPolarity!, $createdAt: String!) {
  listPendingAlteration(alterationClosetId: $alterationClosetId, limit: 9999, userEmailPolarityCreatedAt: {lt: {userEmail: $userEmail, polarity: $polarity, createdAt: $createdAt}}, filter: {userEmail: {eq: $userEmail}, polarity: {eq: $polarity}, pending: {eq: true}}) {
    items {
      ...AlterationParts
    }
  }
}
    ${AlterationPartsFragmentDoc}`;

/**
 * __usePendingAlterationQuery__
 *
 * To run a query within a React component, call `usePendingAlterationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingAlterationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingAlterationQuery({
 *   variables: {
 *      alterationClosetId: // value for 'alterationClosetId'
 *      userEmail: // value for 'userEmail'
 *      polarity: // value for 'polarity'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function usePendingAlterationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingAlterationQuery, PendingAlterationQueryVariables>) {
  return ApolloReactHooks.useQuery<PendingAlterationQuery, PendingAlterationQueryVariables>(PendingAlterationDocument, baseOptions);
}
export function usePendingAlterationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingAlterationQuery, PendingAlterationQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<PendingAlterationQuery, PendingAlterationQueryVariables>(PendingAlterationDocument, baseOptions);
}
export type PendingAlterationQueryHookResult = ReturnType<typeof usePendingAlterationQuery>;
export type PendingAlterationLazyQueryHookResult = ReturnType<typeof usePendingAlterationLazyQuery>;
export const UpdateAlterationDocument = gql`
    mutation UpdateAlteration($id: ID!, $pending: Boolean!, $purposeType: PurposeType, $purposeNote: String, $alterationClosetId: ID!, $alterationToClosetId: ID, $userEmail: String!, $polarity: AlterationPolarity!, $createdAt: AWSDateTime!, $submittedAt: AWSDateTime!) {
  updateAlteration(input: {id: $id, pending: $pending, purposeType: $purposeType, purposeNote: $purposeNote, alterationClosetId: $alterationClosetId, alterationToClosetId: $alterationToClosetId, userEmail: $userEmail, polarity: $polarity, createdAt: $createdAt, submittedAt: $submittedAt}) {
    ...AlterationParts
  }
}
    ${AlterationPartsFragmentDoc}`;
export type UpdateAlterationMutationFn = ApolloReactCommon.MutationFunction<UpdateAlterationMutation, UpdateAlterationMutationVariables>;

/**
 * __useUpdateAlterationMutation__
 *
 * To run a mutation, you first call `useUpdateAlterationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlterationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlterationMutation, { data, loading, error }] = useUpdateAlterationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pending: // value for 'pending'
 *      purposeType: // value for 'purposeType'
 *      purposeNote: // value for 'purposeNote'
 *      alterationClosetId: // value for 'alterationClosetId'
 *      alterationToClosetId: // value for 'alterationToClosetId'
 *      userEmail: // value for 'userEmail'
 *      polarity: // value for 'polarity'
 *      createdAt: // value for 'createdAt'
 *      submittedAt: // value for 'submittedAt'
 *   },
 * });
 */
export function useUpdateAlterationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlterationMutation, UpdateAlterationMutationVariables>) {
  return ApolloReactHooks.useMutation<UpdateAlterationMutation, UpdateAlterationMutationVariables>(UpdateAlterationDocument, baseOptions);
}
export type UpdateAlterationMutationHookResult = ReturnType<typeof useUpdateAlterationMutation>;
export const CreateAlterationDocument = gql`
    mutation CreateAlteration($id: ID!, $polarity: AlterationPolarity!, $pending: Boolean!, $alterationClosetId: ID!, $userEmail: String!, $createdAt: AWSDateTime!) {
  createAlteration(input: {id: $id, polarity: $polarity, pending: $pending, alterationClosetId: $alterationClosetId, alterationToClosetId: "null", userEmail: $userEmail, createdAt: $createdAt}) {
    ...AlterationParts
  }
}
    ${AlterationPartsFragmentDoc}`;
export type CreateAlterationMutationFn = ApolloReactCommon.MutationFunction<CreateAlterationMutation, CreateAlterationMutationVariables>;

/**
 * __useCreateAlterationMutation__
 *
 * To run a mutation, you first call `useCreateAlterationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlterationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlterationMutation, { data, loading, error }] = useCreateAlterationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      polarity: // value for 'polarity'
 *      pending: // value for 'pending'
 *      alterationClosetId: // value for 'alterationClosetId'
 *      userEmail: // value for 'userEmail'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useCreateAlterationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAlterationMutation, CreateAlterationMutationVariables>) {
  return ApolloReactHooks.useMutation<CreateAlterationMutation, CreateAlterationMutationVariables>(CreateAlterationDocument, baseOptions);
}
export type CreateAlterationMutationHookResult = ReturnType<typeof useCreateAlterationMutation>;
export const DisputeAlterationProductsDocument = gql`
    mutation DisputeAlterationProducts($input: [UpdateAlterationProductBatchInput!]!) {
  updateAlterationProductBatch(input: $input) {
    items {
      id
    }
    success
    message
  }
}
    `;
export type DisputeAlterationProductsMutationFn = ApolloReactCommon.MutationFunction<DisputeAlterationProductsMutation, DisputeAlterationProductsMutationVariables>;

/**
 * __useDisputeAlterationProductsMutation__
 *
 * To run a mutation, you first call `useDisputeAlterationProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisputeAlterationProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disputeAlterationProductsMutation, { data, loading, error }] = useDisputeAlterationProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisputeAlterationProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisputeAlterationProductsMutation, DisputeAlterationProductsMutationVariables>) {
  return ApolloReactHooks.useMutation<DisputeAlterationProductsMutation, DisputeAlterationProductsMutationVariables>(DisputeAlterationProductsDocument, baseOptions);
}
export type DisputeAlterationProductsMutationHookResult = ReturnType<typeof useDisputeAlterationProductsMutation>;
export const UpdateAlterationProductBatchDocument = gql`
    mutation UpdateAlterationProductBatch($input1: [UpdateAlterationProductBatchInput!]!, $input2: [UpdateClosetProductBatchInput!]!) {
  updateAlterationProductBatch(input: $input1) {
    items {
      id
    }
    success
    message
  }
  updateClosetProductBatch(input: $input2) {
    items {
      id
    }
    success
    message
  }
}
    `;
export type UpdateAlterationProductBatchMutationFn = ApolloReactCommon.MutationFunction<UpdateAlterationProductBatchMutation, UpdateAlterationProductBatchMutationVariables>;

/**
 * __useUpdateAlterationProductBatchMutation__
 *
 * To run a mutation, you first call `useUpdateAlterationProductBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlterationProductBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlterationProductBatchMutation, { data, loading, error }] = useUpdateAlterationProductBatchMutation({
 *   variables: {
 *      input1: // value for 'input1'
 *      input2: // value for 'input2'
 *   },
 * });
 */
export function useUpdateAlterationProductBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlterationProductBatchMutation, UpdateAlterationProductBatchMutationVariables>) {
  return ApolloReactHooks.useMutation<UpdateAlterationProductBatchMutation, UpdateAlterationProductBatchMutationVariables>(UpdateAlterationProductBatchDocument, baseOptions);
}
export type UpdateAlterationProductBatchMutationHookResult = ReturnType<typeof useUpdateAlterationProductBatchMutation>;
export const UpdateAlterationProductStatusDocument = gql`
    mutation UpdateAlterationProductStatus($status: AlterationProductStatus!, $senderScannerUserEmail: String!, $alterationProductId: ID!, $alterationProductChangeId: ID!) {
  updateAlterationProduct(input: {id: $alterationProductId, status: $status, senderScannerUserEmail: $senderScannerUserEmail}) {
    ...AlterationProductParts
  }
  createAlterationProductChange(input: {id: $alterationProductChangeId, status: $status, senderScannerUserEmail: $senderScannerUserEmail, alterationProductChangeAlterationProductId: $alterationProductId}) {
    ...AlterationProductChangeParts
  }
}
    ${AlterationProductPartsFragmentDoc}
${AlterationProductChangePartsFragmentDoc}`;
export type UpdateAlterationProductStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateAlterationProductStatusMutation, UpdateAlterationProductStatusMutationVariables>;

/**
 * __useUpdateAlterationProductStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAlterationProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlterationProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlterationProductStatusMutation, { data, loading, error }] = useUpdateAlterationProductStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      senderScannerUserEmail: // value for 'senderScannerUserEmail'
 *      alterationProductId: // value for 'alterationProductId'
 *      alterationProductChangeId: // value for 'alterationProductChangeId'
 *   },
 * });
 */
export function useUpdateAlterationProductStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlterationProductStatusMutation, UpdateAlterationProductStatusMutationVariables>) {
  return ApolloReactHooks.useMutation<UpdateAlterationProductStatusMutation, UpdateAlterationProductStatusMutationVariables>(UpdateAlterationProductStatusDocument, baseOptions);
}
export type UpdateAlterationProductStatusMutationHookResult = ReturnType<typeof useUpdateAlterationProductStatusMutation>;
export const AlterationProductsDocument = gql`
    query AlterationProducts($alterationProductAlterationId: ID!, $createdAt: String!) {
  listAlterationProductsByAlterationId(limit: 3000, alterationProductAlterationId: $alterationProductAlterationId, createdAt: {lt: $createdAt}) {
    items {
      ...AlterationProductParts
    }
  }
}
    ${AlterationProductPartsFragmentDoc}`;

/**
 * __useAlterationProductsQuery__
 *
 * To run a query within a React component, call `useAlterationProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlterationProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlterationProductsQuery({
 *   variables: {
 *      alterationProductAlterationId: // value for 'alterationProductAlterationId'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useAlterationProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AlterationProductsQuery, AlterationProductsQueryVariables>) {
  return ApolloReactHooks.useQuery<AlterationProductsQuery, AlterationProductsQueryVariables>(AlterationProductsDocument, baseOptions);
}
export function useAlterationProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlterationProductsQuery, AlterationProductsQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<AlterationProductsQuery, AlterationProductsQueryVariables>(AlterationProductsDocument, baseOptions);
}
export type AlterationProductsQueryHookResult = ReturnType<typeof useAlterationProductsQuery>;
export type AlterationProductsLazyQueryHookResult = ReturnType<typeof useAlterationProductsLazyQuery>;
export const DeleteAlterationProductDocument = gql`
    mutation DeleteAlterationProduct($id: ID!) {
  deleteAlterationProduct(input: {id: $id}) {
    ...AlterationProductParts
  }
}
    ${AlterationProductPartsFragmentDoc}`;
export type DeleteAlterationProductMutationFn = ApolloReactCommon.MutationFunction<DeleteAlterationProductMutation, DeleteAlterationProductMutationVariables>;

/**
 * __useDeleteAlterationProductMutation__
 *
 * To run a mutation, you first call `useDeleteAlterationProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlterationProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlterationProductMutation, { data, loading, error }] = useDeleteAlterationProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlterationProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlterationProductMutation, DeleteAlterationProductMutationVariables>) {
  return ApolloReactHooks.useMutation<DeleteAlterationProductMutation, DeleteAlterationProductMutationVariables>(DeleteAlterationProductDocument, baseOptions);
}
export type DeleteAlterationProductMutationHookResult = ReturnType<typeof useDeleteAlterationProductMutation>;
export const CreateAlterationProductDocument = gql`
    mutation CreateAlterationProduct($id: ID!, $upc: String!, $alterationProductAlterationId: ID!, $alterationProductClosetProductId: ID!, $pending: Boolean!) {
  createAlterationProduct(input: {id: $id, upc: $upc, alterationProductAlterationId: $alterationProductAlterationId, alterationProductClosetProductId: $alterationProductClosetProductId, pending: $pending}) {
    ...AlterationProductParts
    alteration {
      ...AlterationParts
    }
  }
}
    ${AlterationProductPartsFragmentDoc}
${AlterationPartsFragmentDoc}`;
export type CreateAlterationProductMutationFn = ApolloReactCommon.MutationFunction<CreateAlterationProductMutation, CreateAlterationProductMutationVariables>;

/**
 * __useCreateAlterationProductMutation__
 *
 * To run a mutation, you first call `useCreateAlterationProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlterationProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlterationProductMutation, { data, loading, error }] = useCreateAlterationProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      upc: // value for 'upc'
 *      alterationProductAlterationId: // value for 'alterationProductAlterationId'
 *      alterationProductClosetProductId: // value for 'alterationProductClosetProductId'
 *      pending: // value for 'pending'
 *   },
 * });
 */
export function useCreateAlterationProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAlterationProductMutation, CreateAlterationProductMutationVariables>) {
  return ApolloReactHooks.useMutation<CreateAlterationProductMutation, CreateAlterationProductMutationVariables>(CreateAlterationProductDocument, baseOptions);
}
export type CreateAlterationProductMutationHookResult = ReturnType<typeof useCreateAlterationProductMutation>;
export const CreateAlterationProductBatchAddDocument = gql`
    mutation CreateAlterationProductBatchAdd($input: CreateAlterationProductBatchAddInput!) {
  createAlterationProductBatchAdd(input: $input) {
    processedCreated {
      id
    }
    processedDeleted {
      id
    }
    errors
  }
}
    `;
export type CreateAlterationProductBatchAddMutationFn = ApolloReactCommon.MutationFunction<CreateAlterationProductBatchAddMutation, CreateAlterationProductBatchAddMutationVariables>;

/**
 * __useCreateAlterationProductBatchAddMutation__
 *
 * To run a mutation, you first call `useCreateAlterationProductBatchAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlterationProductBatchAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlterationProductBatchAddMutation, { data, loading, error }] = useCreateAlterationProductBatchAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlterationProductBatchAddMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAlterationProductBatchAddMutation, CreateAlterationProductBatchAddMutationVariables>) {
  // console.log("CreateAlteration");
  return ApolloReactHooks.useMutation<CreateAlterationProductBatchAddMutation, CreateAlterationProductBatchAddMutationVariables>(CreateAlterationProductBatchAddDocument, baseOptions);
}
export type CreateAlterationProductBatchAddMutationHookResult = ReturnType<typeof useCreateAlterationProductBatchAddMutation>;
export const RemoveCreateAlterationProductBatchDocument = gql`
    mutation RemoveCreateAlterationProductBatch($input: RemoveCreateAlterationProductBatchInput!) {
  removeCreateAlterationProductBatch(input: $input) {
    processedCreated {
      id
    }
    processedDeleted {
      id
    }
    errors
  }
}
    `;
export type RemoveCreateAlterationProductBatchMutationFn = ApolloReactCommon.MutationFunction<RemoveCreateAlterationProductBatchMutation, RemoveCreateAlterationProductBatchMutationVariables>;

/**
 * __useRemoveCreateAlterationProductBatchMutation__
 *
 * To run a mutation, you first call `useRemoveCreateAlterationProductBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreateAlterationProductBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCreateAlterationProductBatchMutation, { data, loading, error }] = useRemoveCreateAlterationProductBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCreateAlterationProductBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCreateAlterationProductBatchMutation, RemoveCreateAlterationProductBatchMutationVariables>) {
  return ApolloReactHooks.useMutation<RemoveCreateAlterationProductBatchMutation, RemoveCreateAlterationProductBatchMutationVariables>(RemoveCreateAlterationProductBatchDocument, baseOptions);
}
export type RemoveCreateAlterationProductBatchMutationHookResult = ReturnType<typeof useRemoveCreateAlterationProductBatchMutation>;
export const ListClosetsByUserEmailDocument = gql`
    query ListClosetsByUserEmail($userEmail: String) {
  listClosets(filter: {userEmail: {contains: $userEmail}}, limit: 9999) {
    items {
      ...ClosetParts
    }
  }
}
    ${ClosetPartsFragmentDoc}`;

/**
 * __useListClosetsByUserEmailQuery__
 *
 * To run a query within a React component, call `useListClosetsByUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClosetsByUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClosetsByUserEmailQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useListClosetsByUserEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClosetsByUserEmailQuery, ListClosetsByUserEmailQueryVariables>) {
  return ApolloReactHooks.useQuery<ListClosetsByUserEmailQuery, ListClosetsByUserEmailQueryVariables>(ListClosetsByUserEmailDocument, baseOptions);
}
export function useListClosetsByUserEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClosetsByUserEmailQuery, ListClosetsByUserEmailQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<ListClosetsByUserEmailQuery, ListClosetsByUserEmailQueryVariables>(ListClosetsByUserEmailDocument, baseOptions);
}
export type ListClosetsByUserEmailQueryHookResult = ReturnType<typeof useListClosetsByUserEmailQuery>;
export type ListClosetsByUserEmailLazyQueryHookResult = ReturnType<typeof useListClosetsByUserEmailLazyQuery>;
export const CreateClosetDocument = gql`
    mutation CreateCloset($id: ID!, $userEmail: String!, $createdAt: AWSDateTime!, $updatedAt: AWSDateTime!, $hasAcceptedEula: Boolean, $acceptedEulaAt: AWSDateTime) {
  createCloset(input: {id: $id, userEmail: $userEmail, createdAt: $createdAt, updatedAt: $updatedAt, hasAcceptedEula: $hasAcceptedEula, acceptedEulaAt: $acceptedEulaAt}) {
    ...ClosetParts
  }
}
    ${ClosetPartsFragmentDoc}`;
export type CreateClosetMutationFn = ApolloReactCommon.MutationFunction<CreateClosetMutation, CreateClosetMutationVariables>;

/**
 * __useCreateClosetMutation__
 *
 * To run a mutation, you first call `useCreateClosetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClosetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClosetMutation, { data, loading, error }] = useCreateClosetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userEmail: // value for 'userEmail'
 *      createdAt: // value for 'createdAt'
 *      updatedAt: // value for 'updatedAt'
 *      hasAcceptedEula: // value for 'hasAcceptedEula'
 *      acceptedEulaAt: // value for 'acceptedEulaAt'
 *   },
 * });
 */
export function useCreateClosetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClosetMutation, CreateClosetMutationVariables>) {
  return ApolloReactHooks.useMutation<CreateClosetMutation, CreateClosetMutationVariables>(CreateClosetDocument, baseOptions);
}
export type CreateClosetMutationHookResult = ReturnType<typeof useCreateClosetMutation>;
export const UpdateClosetDocument = gql`
    mutation UpdateCloset($id: ID!, $hasAcceptedEula: Boolean, $acceptedEulaAt: AWSDateTime, $updatedAt: AWSDateTime!, $createdAt: AWSDateTime) {
  updateCloset(input: {id: $id, hasAcceptedEula: $hasAcceptedEula, acceptedEulaAt: $acceptedEulaAt, updatedAt: $updatedAt, createdAt: $createdAt}) {
    ...ClosetParts
    hasAcceptedEula
    acceptedEulaAt
    updatedAt
  }
}
    ${ClosetPartsFragmentDoc}`;
export type UpdateClosetMutationFn = ApolloReactCommon.MutationFunction<UpdateClosetMutation, UpdateClosetMutationVariables>;

/**
 * __useUpdateClosetMutation__
 *
 * To run a mutation, you first call `useUpdateClosetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClosetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClosetMutation, { data, loading, error }] = useUpdateClosetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hasAcceptedEula: // value for 'hasAcceptedEula'
 *      acceptedEulaAt: // value for 'acceptedEulaAt'
 *      updatedAt: // value for 'updatedAt'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useUpdateClosetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClosetMutation, UpdateClosetMutationVariables>) {
  return ApolloReactHooks.useMutation<UpdateClosetMutation, UpdateClosetMutationVariables>(UpdateClosetDocument, baseOptions);
}
export type UpdateClosetMutationHookResult = ReturnType<typeof useUpdateClosetMutation>;
export const ClosetsByUserEmailDocument = gql`
    query ClosetsByUserEmail($userEmail: String!, $createdAt: String!) {
  listClosetsByUserEmail(userEmail: $userEmail, createdAt: {lt: $createdAt}) {
    items {
      ...ClosetParts
      products1(limit: 9999) {
        items {
          ...ClosetProductParts
        }
      }
    }
  }
}
    ${ClosetPartsFragmentDoc}
${ClosetProductPartsFragmentDoc}`;

/**
 * __useClosetsByUserEmailQuery__
 *
 * To run a query within a React component, call `useClosetsByUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosetsByUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosetsByUserEmailQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useClosetsByUserEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClosetsByUserEmailQuery, ClosetsByUserEmailQueryVariables>) {
  return ApolloReactHooks.useQuery<ClosetsByUserEmailQuery, ClosetsByUserEmailQueryVariables>(ClosetsByUserEmailDocument, baseOptions);
}
export function useClosetsByUserEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClosetsByUserEmailQuery, ClosetsByUserEmailQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<ClosetsByUserEmailQuery, ClosetsByUserEmailQueryVariables>(ClosetsByUserEmailDocument, baseOptions);
}
export type ClosetsByUserEmailQueryHookResult = ReturnType<typeof useClosetsByUserEmailQuery>;
export type ClosetsByUserEmailLazyQueryHookResult = ReturnType<typeof useClosetsByUserEmailLazyQuery>;
export const UpdateClosetProductDocument = gql`
    mutation UpdateClosetProduct($id: ID!, $closetProductAlterationProductId: ID) {
  updateClosetProduct(input: {id: $id, closetProductAlterationProductId: $closetProductAlterationProductId}) {
    ...ClosetProductParts
  }
}
    ${ClosetProductPartsFragmentDoc}`;
export type UpdateClosetProductMutationFn = ApolloReactCommon.MutationFunction<UpdateClosetProductMutation, UpdateClosetProductMutationVariables>;

/**
 * __useUpdateClosetProductMutation__
 *
 * To run a mutation, you first call `useUpdateClosetProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClosetProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClosetProductMutation, { data, loading, error }] = useUpdateClosetProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      closetProductAlterationProductId: // value for 'closetProductAlterationProductId'
 *   },
 * });
 */
export function useUpdateClosetProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClosetProductMutation, UpdateClosetProductMutationVariables>) {
  return ApolloReactHooks.useMutation<UpdateClosetProductMutation, UpdateClosetProductMutationVariables>(UpdateClosetProductDocument, baseOptions);
}
export type UpdateClosetProductMutationHookResult = ReturnType<typeof useUpdateClosetProductMutation>;
export const DeleteClosetProductBatchDocument = gql`
    mutation DeleteClosetProductBatch($input: DeleteClosetProductBatchInput!) {
  deleteClosetProductBatch(input: $input) {
    processed {
      id
    }
    errors
  }
}
    `;
export type DeleteClosetProductBatchMutationFn = ApolloReactCommon.MutationFunction<DeleteClosetProductBatchMutation, DeleteClosetProductBatchMutationVariables>;

/**
 * __useDeleteClosetProductBatchMutation__
 *
 * To run a mutation, you first call `useDeleteClosetProductBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClosetProductBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClosetProductBatchMutation, { data, loading, error }] = useDeleteClosetProductBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClosetProductBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClosetProductBatchMutation, DeleteClosetProductBatchMutationVariables>) {
  return ApolloReactHooks.useMutation<DeleteClosetProductBatchMutation, DeleteClosetProductBatchMutationVariables>(DeleteClosetProductBatchDocument, baseOptions);
}
export type DeleteClosetProductBatchMutationHookResult = ReturnType<typeof useDeleteClosetProductBatchMutation>;
export const CreateClosetProductBatchDocument = gql`
    mutation CreateClosetProductBatch($input: CreateClosetProductBatchInput!) {
  createClosetProductBatch(input: $input) {
    processed {
      id
    }
    errors
  }
}
    `;
export type CreateClosetProductBatchMutationFn = ApolloReactCommon.MutationFunction<CreateClosetProductBatchMutation, CreateClosetProductBatchMutationVariables>;

/**
 * __useCreateClosetProductBatchMutation__
 *
 * To run a mutation, you first call `useCreateClosetProductBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClosetProductBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClosetProductBatchMutation, { data, loading, error }] = useCreateClosetProductBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClosetProductBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClosetProductBatchMutation, CreateClosetProductBatchMutationVariables>) {
  return ApolloReactHooks.useMutation<CreateClosetProductBatchMutation, CreateClosetProductBatchMutationVariables>(CreateClosetProductBatchDocument, baseOptions);
}
export type CreateClosetProductBatchMutationHookResult = ReturnType<typeof useCreateClosetProductBatchMutation>;