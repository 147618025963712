import { AlterationPolarity } from "../../../apis/cims/generated/graphql-cims";
import { Dispatch, SetStateAction } from "react";
import { recordError } from "../../../utils/logging";

export const onClickClosetFab = (
  activeClosetId: string | null,
  setActiveClosetId: (t: string | null) => void,
  alterationPolarity: AlterationPolarity,
  push: (path: string) => void,
  setProductScanPageIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const onSuccessValue = () => {
    setProductScanPageIsLoading(false);
    return true;
  };
  const onSuccessPromise = () => {
    const resolveValue = onSuccessValue();
    return new Promise<boolean>(resolve => {
      return resolve(resolveValue);
    });
  };
  const onFailureValue = () => {
    setProductScanPageIsLoading(false);
    return false;
  };
  const onFailurePromise = () => {
    const resolveValue = onFailureValue();
    return new Promise<boolean>(resolve => {
      return resolve(resolveValue);
    });
  };
  return (): Promise<boolean> => {
    setProductScanPageIsLoading(true);
    const afterClosetOkay = (closetId: string) => {
      setActiveClosetId(closetId);
      if (alterationPolarity === AlterationPolarity.Remove) {
        push("/remove");
        return onSuccessPromise();
      } else {
        push("/add");
        return onSuccessPromise();
      }
    };
    if (activeClosetId) {
      return afterClosetOkay(activeClosetId);
    } else {
      recordError("dcc1440c-64cb-46f1-8ea6-0e6b1da49adc", `activeClosetId is '${activeClosetId}'`);
      return onFailurePromise();
    }
  };
};
