import "./css/num-input.css";
import { TextField } from "@rmwc/textfield";
import {
  parseInputValue,
  className,
  getInputNumValue,
} from "./utils/numInputUtils";
import React from "react";
import { scrollIntoView } from "./utils/domUtils";

export type NumInputProps = {
  min?: number;
  max?: number;
  value?: number;
  disabled?: boolean;
  onChange: (v: any) => void;
};

const DEFAULT_MIN = 0;
const DEFAULT_MAX = Number.MAX_VALUE;

NumInput.displayName = "NumInput";
export function NumInput(props: NumInputProps) {
  const {
    value,
    min = DEFAULT_MIN,
    max = DEFAULT_MAX,
    disabled,
    onChange
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [valueInner, setValueInner] = React.useState(() => String(value));
  const isValid = typeof error === "undefined";

  const handleChangeValueInner = React.useCallback(
    event => {
      let valueInnerNew = event.target.value || "";
      // removes all non digits
      valueInnerNew = valueInnerNew.replace(/\D/g,'');
      // removes all leading 0 if string is greater than 1
      valueInnerNew = valueInnerNew.length > 1 ? valueInnerNew.replace(/^0+/, '') : valueInnerNew

      setValueInner(valueInnerNew)
      const { err } = parseInputValue(valueInnerNew, min, max);

      if (err) {
        setError(err);
      } else {
        setError(undefined);
      }
    },
    [max, min]
  );

  React.useEffect(() => {
    setIsDirty(String(value) !== valueInner);
  }, [value, valueInner]);

  React.useEffect(() => {
    setValueInner(String(value));
  }, [value]);

  React.useLayoutEffect(() => {
    const inputElem = inputRef.current;
    const handleBlur = () => {
      const { num, err } = getInputNumValue(inputElem, min, max);
      if (!err) {
        onChange(num);
      }
    };
    if (inputElem) {
      inputElem.addEventListener("blur", handleBlur);
    }
    return () => {
      if (inputElem) {
        inputElem.removeEventListener("blur", handleBlur);
      }
    };
  }, [isValid, max, min, onChange, value]);

  const isFocused = useIsFocusedElement(inputRef)
  useScrollIntoView(inputRef, isFocused)


  const classes = className({
    "c-num-input": true,
    "m-error": !isValid,
    "m-dirty": isDirty
  });
  const title = isValid ? `Range from ${min} to ${max}` : error

  return (
    <span className={classes}>
      <TextField
        title={title}
        pattern="\d*"
        type="number"
        outlined={true}
        invalid={!isValid}
        value={valueInner}
        inputRef={inputRef}
        disabled={disabled}
        onChange={handleChangeValueInner}
      />
    </span>
  );
}


function useScrollIntoView(inputRef: React.RefObject<HTMLInputElement>, isFocused: boolean) {
  // @ts-ignore
  React.useLayoutEffect(() => {
    const inputElem = inputRef.current;
    if (inputElem && isFocused) {
      const unsubscribeFn = subscribeOnWindowResize(() => scrollIntoView(inputElem), 500)
      return () => unsubscribeFn()
    }
  }, [inputRef, isFocused]);
}

function useIsFocusedElement(inputRef: React.RefObject<HTMLInputElement>) {
  const [isFocused, setIsFocused] = React.useState(false)
  // @ts-ignore
  React.useLayoutEffect(() => {
    const inputElem = inputRef.current;
    if (inputElem) {
      if (document.activeElement === inputElem) {
        setIsFocused(true)
      }
      const handleBlur = () => {
        setIsFocused(false)
      }
      const handleFocus = () => {
        setIsFocused(true)
      };
      inputElem.addEventListener("focus", handleFocus, true);
      inputElem.addEventListener("blur", handleBlur, true);
      return () => {
        inputElem.removeEventListener("focus", handleFocus, true);
        inputElem.removeEventListener("blur", handleBlur, true);
      };
    }
  }, [inputRef]);

  return isFocused
}



function subscribeOnWindowResize(onResize: () => void, debounce: number = 500): () => void {
  let tid: NodeJS.Timeout
  const handleResize = () => {
    clearTimeout(tid)
    tid = setTimeout(() => {
      onResize()
    },debounce)
  }

  window.addEventListener('resize', handleResize)
  return () => {
    window.removeEventListener('resize', handleResize)
  }
}
