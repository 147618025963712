import { UseOktaUser, useOktaUserStore } from "../../../store/useOktaUser";
import ApolloClient from "apollo-client";
import {
  useFetchAllProductsInfo,
} from "../../cpg/hooks/ProductPageBatch";
import { closetProductsRefetchQuery } from "./ClosetProduct";
import {
  useClosetsByUserEmailQuery,
  AlterationProductStatus,
  Maybe,
  Closet,
} from "../generated/graphql-cims";
import { ProductDetail, UpcProduct } from "./UpcProduct";

export const useClosetRefetchQuery = (oktaUser: UseOktaUser) => {
  const { value: user, loading } = oktaUser;
  return {
    value:
      loading || !user
        ? null
        : closetProductsRefetchQuery((user && user.email) || "no email"),
    loading
  };
};

export type ProductDetailsLoading = {
  productDetails: {
    value: {
      items: (ProductDetail<UpcProduct> | null)[] | null;
    };
    error: Error | undefined;
    loading: boolean;
  };
  closet: {
    loading: boolean;
    data: Maybe<Pick<Closet, "id" | "userEmail" | "hasAcceptedEula">>;
  };
};

export function useProductDetailsFetchClosetId() {
  const { closet } = useCloset();
  return closet?.id || null;
}

function useCloset() {
  const [oktaUser] = useOktaUserStore();
  const email = (oktaUser && oktaUser.value && oktaUser.value.email) || "";
  const refetchQuery = closetProductsRefetchQuery(email);
  const closetsResult = useClosetsByUserEmailQuery({
    variables: refetchQuery.variables,
    skip: email === ""
  });

  // `const firstCloset` refers to the 0th closet in the listClosetsByUserEmail results.
  // If firstCloset returns null it means that the closet does not exist.
  // TODO: move server-side: merge two arrays from different apis

  const closetsList = closetsResult.data?.listClosetsByUserEmail?.items;
  const closet = closetsList?.find(x => !!x) || null;

  return {
    email,
    loading: closetsResult.loading,
    closet
  };
}

export const useProductDetailsFetch = (
  client: ApolloClient<unknown>
): ProductDetailsLoading => {
  const { email, closet, loading: isClosetLoading } = useCloset();

  const closetProductsFiltered =
    closet?.products1?.items?.filter(closetProduct => {
      // being transferred if status not null
      // closet product should not be shown
      // in closet if it's being transferred to another closet
      const status =
        closetProduct &&
        closetProduct.alterationProduct &&
        closetProduct.alterationProduct.status;
      return !status || status === AlterationProductStatus.Accepted;
    }) ?? null;

  const productDetailsItems = useFetchAllProductsInfo(
    { items: closetProductsFiltered, loading: isClosetLoading },
    client
  );

  const productDetails = {
    value: { items: productDetailsItems.value || null },
    error: productDetailsItems.error,
    loading: productDetailsItems.loading || isClosetLoading || email === ""
  };

  return {
    productDetails,
    closet: {
      loading: isClosetLoading || email === "",
      data: closet
    }
  };
};
