import Dynamsoft from "./Dynamsoft";
import React from "react";
import { queue } from "../../utils/rmwcDialogQueue";

class BarcodeScanner extends React.Component {
  constructor(props) {
    super(props);
    this.bDestroyed = false;
    this.scanner = null;
    this.elRef = React.createRef();
  }
  
  async componentDidMount() {
    try {
      let scanner = (this.scanner =
        this.scanner || (await Dynamsoft.BarcodeScanner.createInstance()));
      if (this.bDestroyed) {
        this.destroy();
        return;
      }
      let settings = await scanner.getRuntimeSettings();
      //REMOVE THIS CODE TO SUPPORT OTHER BARCODE TYPES.
      settings.barcodeFormatIds = Dynamsoft.EnumBarcodeFormat.BF_ONED;
      settings.region.regionMeasuredByPercentage = 1;
      settings.region.regionTop = 10;
      settings.region.regionBottom = 50;
      settings.region.regionRight = 100;
      await scanner.updateRuntimeSettings(settings);
      try {
        scanner.setUIElement(this.elRef.current);
        scanner.onFrameRead = results => {
          if (results.length) {
          }
        };
        scanner.onUnduplicatedRead = (txt, result) => {
          const upc = "00" + result.barcodeText;
          window.dispatchEvent(
            new CustomEvent("ManualEntryUpc", {
              detail: upc
            })
          );
        };

        const matchChrome = new RegExp(/Chrome/gi);
        const is_chrome = matchChrome.test(navigator.userAgent);
        const matchFirefox = new RegExp(/Firefox/gi);
        const is_firefox = matchFirefox.test(navigator.userAgent);
        const inEmulator = 'TouchEvent' in window && 'ontouchstart' in window;
        
        const cameraConstraint = inEmulator || (is_chrome || is_firefox) ? { video: true } : { video: { facingMode: { exact: "environment" } } };

        const noCameraPermission = await navigator.mediaDevices.getUserMedia(cameraConstraint)
        .then(function(result) {
          return result.active !== true;
        })
        .catch(error => this.props.setUserDeniedScanner(true))
        
        try {

          if (
            (window.location.href.indexOf("/add") > -1 ||
              window.location.href.indexOf("/remove") > -1) && (noCameraPermission
            )) {
                queue.alert({
                  title: "Failed to gain access to camera.",
                  body: "Access to the camera is required to scan barcodes."
                });
              } else {
                await scanner.open();
                noCameraPermission ? this.props.setIsScannerLoaded(false) : this.props.setIsScannerLoaded(true);
              }
          
        } catch {
          if (
            (window.location.href.indexOf("/add") > -1 ||
              window.location.href.indexOf("/remove") > -1) && (noCameraPermission
            )) {
              queue.alert({
                title: "Failed to gain access to camera.",
                body: "Access to the camera is required to scan barcodes."
              });
          } 
        }

        if (this.bDestroyed) {
          this.destroy();
          return;
        }
      } catch {}
    } catch (ex) {}
  }
  componentWillUnmount() {
    if (this.reader) {
      this.destroy();
    }
  }
  shouldComponentUpdate() {
    // Never update UI after mount, the sdk use native way to bind event, update will remove it.
    return false;
  }
  render() {
    return (
      <div ref={this.elRef} style={{ height: "30%", overflowY: "hidden" }}>
        <video className="dbrScanner-video" style={style.video}></video>
      </div>
    );
  }
  async destroy() {
    this.bDestroyed = true;
    if (this.scanner) {
      this.scanner.close();
      this.scanner.destroy();
      this.scanner = null;
    }
  }
}

const style = {
  video: {
    width: "100%",
    objectFit: "cover",
    height: "100%"
  }
};

export default BarcodeScanner;
