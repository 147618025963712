import React from "react";

function SvgHome(props) {
  return (
    <svg {...props} viewBox="0 0 700 250">
      <path fill="none" d="M-1-1h258v258H-1z" />
      <g>
        <path
          d="M239.393 68.693l-92.297-60.13a35.236 35.236 0 00-38.192 0l-92.297 60.13a27.962 27.962 0 00-13.64 23.415V253.06h102.3v-63.312h45.466v63.312h102.3V92.11a27.962 27.962 0 00-13.64-23.416zm-9.094 161.633h-56.833v-63.312H82.534v63.312H25.7V92.108a5.57 5.57 0 012.955-4.433l92.297-60.129a12.958 12.958 0 0113.412 0l92.297 60.13a5.57 5.57 0 012.955 4.432l.682 138.218z"
          fill="#111"
        />
        <path
          d="M24.275 89.032c.534-.802 95.17-62.823 94.956-62.833"
          opacity={0.5}
          fillOpacity="null"
          fill="#111"
        />
        <path
          d="M20.8 87.161l107.787-69.517 107.468 70.041-.534 79.131c-66.923 6.416-145.608 2.673-218.412 4.01"
          fillOpacity="null"
          fill="#111"
        />
        <path
          fillOpacity="null"
          fill="#111"
          d="M23.205 167.628h64.427v72.447H23.205zM169.971 164.42h68.972v68.972h-68.972z"
        />
      </g>
    </svg>
  );
}

export default SvgHome;
