import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Timestamp: any,
  Upload: any,
};

export type Aggregation = {
  __typename?: 'Aggregation',
  key: Scalars['ID'],
  value?: Maybe<Scalars['Float']>,
};

export enum AggregationType {
  WholesaleMin = 'wholesale_min',
  WholesaleMax = 'wholesale_max'
}

export type Athlete = {
  __typename?: 'Athlete',
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
};

export type AuroraImage = {
  __typename?: 'AuroraImage',
  url?: Maybe<Scalars['String']>,
  proxyUrl?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type Bom = {
  __typename?: 'Bom',
  colors?: Maybe<BomColors>,
};

export type BomColors = {
  __typename?: 'BomColors',
  primary?: Maybe<Scalars['String']>,
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Copy = {
  __typename?: 'Copy',
  language?: Maybe<Scalars['String']>,
  primaryName?: Maybe<Scalars['String']>,
  secondaryName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  reasonToBuy?: Maybe<Scalars['String']>,
  features?: Maybe<Array<Maybe<Features>>>,
};

export type Cursor = {
  __typename?: 'Cursor',
  after: Scalars['String'],
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Facet {
  Division = 'division',
  Gender = 'gender',
  Sport = 'sport',
  Team = 'team',
  League = 'league',
  Category = 'category',
  Silhouette = 'silhouette',
  Segment = 'segment',
  Size = 'size',
  SilhouetteType = 'silhouetteType',
  Dimensions = 'dimensions',
  Color = 'color'
}

export type FacetValues = {
  __typename?: 'FacetValues',
  key: Scalars['ID'],
  values: Array<Maybe<Scalars['String']>>,
};

export type Features = {
  __typename?: 'Features',
  sequence?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
};

export type GenderAgeReferenceType = {
  __typename?: 'GenderAgeReferenceType',
  id?: Maybe<Scalars['Int']>,
  code?: Maybe<Scalars['String']>,
  statusCode?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genderId?: Maybe<Scalars['Int']>,
  ageId?: Maybe<Scalars['Int']>,
  summaryCode?: Maybe<Scalars['String']>,
  summaryDescription?: Maybe<Scalars['String']>,
};

export type Image = {
  __typename?: 'Image',
  url?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export enum ImageView {
  Pv = 'PV',
  Sv = 'SV',
  Thumbnail = 'THUMBNAIL',
  All = 'ALL'
}

export type Launch = {
  __typename?: 'Launch',
  beginDate?: Maybe<Scalars['Timestamp']>,
  code?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type League = {
  __typename?: 'League',
  id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  abbreviation?: Maybe<Scalars['String']>,
  usageIndicator?: Maybe<Scalars['String']>,
  statusCode?: Maybe<Scalars['String']>,
};

export type MerchantClassification = {
  __typename?: 'MerchantClassification',
  id?: Maybe<Scalars['String']>,
  abbreviation?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  statusCode?: Maybe<Scalars['String']>,
};

export type OfferDates = {
  __typename?: 'OfferDates',
  firstProduct?: Maybe<Scalars['String']>,
  endProduct?: Maybe<Scalars['String']>,
  endFuture?: Maybe<Scalars['String']>,
};

export type Price = {
  __typename?: 'Price',
  retail?: Maybe<Scalars['Float']>,
  retailBeginDate?: Maybe<Scalars['Timestamp']>,
  wholesale?: Maybe<Scalars['Float']>,
  wholesaleBeginDate?: Maybe<Scalars['Timestamp']>,
  currency?: Maybe<Scalars['String']>,
  priceListType?: Maybe<Scalars['String']>,
};

export type Product = {
  __typename?: 'Product',
  id: Scalars['ID'],
  athlete?: Maybe<Athlete>,
  bodyType?: Maybe<Scalars['String']>,
  bom?: Maybe<Bom>,
  carryOver?: Maybe<Scalars['Boolean']>,
  category?: Maybe<Scalars['String']>,
  categoryCoreFocus?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  colorName?: Maybe<Scalars['String']>,
  copy?: Maybe<Copy>,
  deliveryUnitNumber?: Maybe<Scalars['String']>,
  deliveryUnitOfMeasure?: Maybe<Scalars['String']>,
  dimensions?: Maybe<Scalars['String']>,
  division?: Maybe<Scalars['String']>,
  divisionCode?: Maybe<Scalars['String']>,
  endFutureOfferDate?: Maybe<Scalars['String']>,
  endProductOfferDate?: Maybe<Scalars['String']>,
  externalMaterialGroupCode?: Maybe<Scalars['String']>,
  fabricContent?: Maybe<Scalars['String']>,
  firstProductOfferDate?: Maybe<Scalars['String']>,
  genderAge?: Maybe<Scalars['String']>,
  genderAgeReferenceType?: Maybe<GenderAgeReferenceType>,
  images?: Maybe<Array<Maybe<Image>>>,
  auroraImages?: Maybe<Array<Maybe<AuroraImage>>>,
  keyTechnology?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  lastModifiedDate?: Maybe<Scalars['String']>,
  league?: Maybe<League>,
  lifecycle?: Maybe<Scalars['String']>,
  launch?: Maybe<Launch>,
  merchantClassification?: Maybe<MerchantClassification>,
  offerDates?: Maybe<OfferDates>,
  prePackCode?: Maybe<Scalars['String']>,
  prePackIndicator?: Maybe<Scalars['String']>,
  prices?: Maybe<Array<Maybe<Price>>>,
  productCode?: Maybe<Scalars['String']>,
  productRegionPromoIndicator?: Maybe<Scalars['String']>,
  rawName?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  regionalOfferDates?: Maybe<OfferDates>,
  regionalSizeRange?: Maybe<Scalars['String']>,
  salesOrganizationCode?: Maybe<Scalars['String']>,
  season?: Maybe<Scalars['String']>,
  seasonalLifecycle?: Maybe<SeasonalLifecycle>,
  segment?: Maybe<Scalars['String']>,
  silhouetteAbbreviation?: Maybe<Scalars['String']>,
  silhouetteCode?: Maybe<Scalars['String']>,
  silhouetteSummary?: Maybe<Scalars['String']>,
  silhouetteTypeAbbreviation?: Maybe<Scalars['String']>,
  silhouetteTypeCode?: Maybe<Scalars['String']>,
  silo?: Maybe<Scalars['String']>,
  sizes?: Maybe<Array<Maybe<Size>>>,
  sportActivity?: Maybe<Scalars['String']>,
  styleGenderAge?: Maybe<Scalars['String']>,
  styleName?: Maybe<Scalars['String']>,
  styleNumber?: Maybe<Scalars['String']>,
  styleSalesCompany?: Maybe<Scalars['String']>,
  subCategory?: Maybe<Scalars['String']>,
  team?: Maybe<Team>,
  usaSizeRange?: Maybe<Scalars['String']>,
};

export type ProductFeature = {
  __typename?: 'productFeature',
  id?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  sequence?: Maybe<Scalars['Int']>,
};

export type ProductPage = {
  __typename?: 'ProductPage',
  cursor?: Maybe<Cursor>,
  products: Array<Maybe<Product>>,
};

export type ProductSizeDisplays = {
  __typename?: 'ProductSizeDisplays',
  sizeTypeCode?: Maybe<Scalars['String']>,
  sizeDisplay?: Maybe<Scalars['String']>,
  sizeTypeDescription?: Maybe<Scalars['String']>,
};

export type ProductStyle = {
  __typename?: 'ProductStyle',
  styleNumber: Scalars['ID'],
  products: Array<Maybe<Product>>,
  videos?: Maybe<Array<Maybe<Video>>>,
};

export type ProductStylePage = {
  __typename?: 'ProductStylePage',
  count?: Maybe<Scalars['Int']>,
  cursor?: Maybe<Cursor>,
  styles: Array<Maybe<ProductStyle>>,
  facets: Array<Maybe<FacetValues>>,
  aggregations?: Maybe<Array<Maybe<Aggregation>>>,
};

export type Query = {
  __typename?: 'Query',
  findProductsByUpc?: Maybe<ProductPage>,
  findProductById?: Maybe<Product>,
  findProductsByIds?: Maybe<Array<Product>>,
  findStyles?: Maybe<ProductStylePage>,
};


export type QueryFindProductsArgs = {
  after?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['Int']>,
  ids?: Maybe<Array<Maybe<Scalars['String']>>>,
  seasons?: Maybe<Array<Maybe<Scalars['String']>>>,
  numbers?: Maybe<Array<Maybe<Scalars['String']>>>,
  codes?: Maybe<Array<Maybe<Scalars['String']>>>,
  regions?: Maybe<Array<Maybe<Scalars['String']>>>,
  languages?: Maybe<Array<Maybe<Scalars['String']>>>,
  lang?: Maybe<Scalars['String']>,
  primaryColors?: Maybe<Array<Maybe<Scalars['String']>>>,
  gender?: Maybe<Array<Maybe<Scalars['String']>>>,
  segment?: Maybe<Array<Maybe<Scalars['String']>>>,
  productSize?: Maybe<Array<Maybe<Scalars['String']>>>,
  silhouette?: Maybe<Array<Maybe<Scalars['String']>>>,
  productType?: Maybe<Array<Maybe<Scalars['String']>>>,
  shoeHeight?: Maybe<Array<Maybe<Scalars['String']>>>,
  shoeWidth?: Maybe<Array<Maybe<Scalars['String']>>>,
  sportCategory?: Maybe<Array<Maybe<Scalars['String']>>>,
  productName?: Maybe<Scalars['String']>,
  sort?: Maybe<Array<Maybe<Sort>>>,
  upc?: Maybe<Array<Maybe<Scalars['String']>>>,
  team?: Maybe<Array<Maybe<Scalars['String']>>>,
  league?: Maybe<Array<Maybe<Scalars['String']>>>,
  images?: Maybe<Array<Maybe<ImageView>>>
};


export type QueryFindProductByIdArgs = {
  id?: Maybe<Scalars['String']>
};


export type QueryFindProductsByIdsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>
};


export type QueryFindStylesArgs = {
  size?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  ids?: Maybe<Array<Maybe<Scalars['String']>>>,
  seasons?: Maybe<Array<Maybe<Scalars['String']>>>,
  numbers?: Maybe<Array<Maybe<Scalars['String']>>>,
  codes?: Maybe<Array<Maybe<Scalars['String']>>>,
  regions?: Maybe<Array<Maybe<Scalars['String']>>>,
  languages?: Maybe<Array<Maybe<Scalars['String']>>>,
  primaryColors?: Maybe<Array<Maybe<Scalars['String']>>>,
  segment?: Maybe<Array<Maybe<Scalars['String']>>>,
  gender?: Maybe<Array<Maybe<Scalars['String']>>>,
  productSize?: Maybe<Array<Maybe<Scalars['String']>>>,
  silhouette?: Maybe<Array<Maybe<Scalars['String']>>>,
  productType?: Maybe<Array<Maybe<Scalars['String']>>>,
  shoeHeight?: Maybe<Array<Maybe<Scalars['String']>>>,
  shoeWidth?: Maybe<Array<Maybe<Scalars['String']>>>,
  sport?: Maybe<Array<Maybe<Scalars['String']>>>,
  bodyType?: Maybe<Array<Maybe<Scalars['String']>>>,
  sportCategory?: Maybe<Array<Maybe<Scalars['String']>>>,
  productName?: Maybe<Scalars['String']>,
  upc?: Maybe<Array<Maybe<Scalars['String']>>>,
  team?: Maybe<Array<Maybe<Scalars['String']>>>,
  league?: Maybe<Array<Maybe<Scalars['String']>>>,
  facets?: Maybe<Array<Maybe<Facet>>>,
  sort?: Maybe<Array<Maybe<Sort>>>,
  aggregations?: Maybe<Array<Maybe<AggregationType>>>,
  retailMin?: Maybe<Scalars['Float']>,
  retailMax?: Maybe<Scalars['Float']>,
  wholesaleMin?: Maybe<Scalars['Float']>,
  wholesaleMax?: Maybe<Scalars['Float']>,
  currency?: Maybe<Scalars['String']>,
  images?: Maybe<Array<Maybe<ImageView>>>
};

export enum SeasonalLifecycle {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Size = {
  __typename?: 'Size',
  upc?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  sortOrderNumber?: Maybe<Scalars['String']>,
  productSizeDisplays?: Maybe<Array<Maybe<ProductSizeDisplays>>>,
  promoIndicator?: Maybe<Scalars['Boolean']>,
};

export type Sort = {
  field: Scalars['String'],
  direction: Direction,
};

export type Style = {
  __typename?: 'Style',
  id: Scalars['ID'],
  number?: Maybe<Scalars['String']>,
  season?: Maybe<Scalars['String']>,
  products?: Maybe<Array<Maybe<Product>>>,
  videos?: Maybe<Array<Maybe<Video>>>,
};


export type StyleProductsArgs = {
  codes?: Maybe<Array<Maybe<Scalars['String']>>>
};

export type StylePage = {
  __typename?: 'StylePage',
  cursor?: Maybe<Cursor>,
  styles: Array<Maybe<Style>>,
};

export type Team = {
  __typename?: 'Team',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  statusCode?: Maybe<Scalars['String']>,
  abbreviation?: Maybe<Scalars['String']>,
};



export type Video = {
  __typename?: 'Video',
  url?: Maybe<Scalars['String']>,
};

export type FindProductsByUpcQueryVariables = {
  upc: Scalars['String']
};


export type FindProductsByUpcQuery = (
  { __typename?: 'Query' }
  & {
    findProductsByUpc: Maybe<(
      { __typename?: 'ProductPage' }
      & ProductPagePartsFragment
    )>
  }
);

export type FindAllProductsByUpcQueryVariables = {
  upc: Array<Maybe<Scalars['String']>>,
  region: Array<Maybe<Scalars['String']>>
};


export type FindAllProductsByUpcQuery = (
  { __typename?: 'Query' }
  & {
    findProductsByUpc: Maybe<(
      { __typename?: 'ProductPage' }
      & ProductPagePartsFragment
    )>
  }
);

export type FindProductsByStyleColorQueryVariables = {
  styleColor: Scalars['String']
};


export type FindProductsByStyleColorQuery = (
  { __typename?: 'Query' }
  & {
    findProducts: Maybe<(
      { __typename?: 'ProductPage' }
      & ProductPagePartsFragment
    )>
  }
);

export type ProductPagePartsFragment = (
  { __typename?: 'ProductPage' }
  & {
    products: Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'productCode' | 'region' | 'genderAge' | 'category'>
      & {
        copy: Maybe<(
          { __typename?: 'Copy' }
          & Pick<Copy, 'primaryName' | 'secondaryName'>
        )>, sizes: Maybe<Array<Maybe<(
          { __typename?: 'Size' }
          & Pick<Size, 'upc' | 'description'>
          & {
            productSizeDisplays: Maybe<Array<Maybe<(
              { __typename?: 'ProductSizeDisplays' }
              & Pick<ProductSizeDisplays, 'sizeDisplay' | 'sizeTypeDescription'>
            )>>>
          }
        )>>>
      }
    )>>
  }
);

export const ProductPagePartsFragmentDoc = gql`
    fragment ProductPageParts on ProductPage {
      cursor {
        after
        __typename
      }
  products {
    id
    productCode
    region
    copy {
      primaryName
      secondaryName
    }
    sizes {
      upc
      description
      productSizeDisplays {
        sizeDisplay
        sizeTypeDescription
      }
    }
    genderAge
    category
  }
}
    `;
export const FindProductsByUpcDocument = gql`
    query FindProductsByUpc($upc: String!) {
  findProductsByUpc(upc: [$upc]) {
    ...ProductPageParts
  }
}
    ${ProductPagePartsFragmentDoc}`;

/**
 * __useFindProductsByUpcQuery__
 *
 * To run a query within a React component, call `useFindProductsByUpcQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsByUpcQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsByUpcQuery({
 *   variables: {
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function useFindProductsByUpcQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindProductsByUpcQuery, FindProductsByUpcQueryVariables>) {
  return ApolloReactHooks.useQuery<FindProductsByUpcQuery, FindProductsByUpcQueryVariables>(FindProductsByUpcDocument, baseOptions);
}
export function useFindProductsByUpcLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindProductsByUpcQuery, FindProductsByUpcQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<FindProductsByUpcQuery, FindProductsByUpcQueryVariables>(FindProductsByUpcDocument, baseOptions);
}
export type FindProductsByUpcQueryHookResult = ReturnType<typeof useFindProductsByUpcQuery>;
export type FindProductsByUpcLazyQueryHookResult = ReturnType<typeof useFindProductsByUpcLazyQuery>;
// modified size to 200 as per latest CPG query, size 10000 gave a timeout error since CPG elastic search has a size limitation
export const FindAllProductsByUpcDocument = gql`
    query FindAllProductsByUpc($upc: [String]!, $region: [String]!) {
  findProductsByUpc(upc: $upc, regions: $region, size: 200) {
    ...ProductPageParts
  }
}
    ${ProductPagePartsFragmentDoc}`;

/**
 * __useFindAllProductsByUpcQuery__
 *
 * To run a query within a React component, call `useFindAllProductsByUpcQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllProductsByUpcQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllProductsByUpcQuery({
 *   variables: {
 *      upc: // value for 'upc'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useFindAllProductsByUpcQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindAllProductsByUpcQuery, FindAllProductsByUpcQueryVariables>) {
  return ApolloReactHooks.useQuery<FindAllProductsByUpcQuery, FindAllProductsByUpcQueryVariables>(FindAllProductsByUpcDocument, baseOptions);
}
export function useFindAllProductsByUpcLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindAllProductsByUpcQuery, FindAllProductsByUpcQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<FindAllProductsByUpcQuery, FindAllProductsByUpcQueryVariables>(FindAllProductsByUpcDocument, baseOptions);
}
export type FindAllProductsByUpcQueryHookResult = ReturnType<typeof useFindAllProductsByUpcQuery>;
export type FindAllProductsByUpcLazyQueryHookResult = ReturnType<typeof useFindAllProductsByUpcLazyQuery>;
// added size variable as 200 as per latest CPG query,the default size is 10 that doesnt work for bulk import products
export const FindProductsByStyleColorDocument = gql`
    query FindProductsByStyleColor($styleColor: String!) {
  findProducts(codes: [$styleColor], size: 200) {
    ...ProductPageParts
  }
}
    ${ProductPagePartsFragmentDoc}`;

/**
 * __useFindProductsByStyleColorQuery__
 *
 * To run a query within a React component, call `useFindProductsByStyleColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsByStyleColorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsByStyleColorQuery({
 *   variables: {
 *      styleColor: // value for 'styleColor'
 *   },
 * });
 */
export function useFindProductsByStyleColorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindProductsByStyleColorQuery, FindProductsByStyleColorQueryVariables>) {
  return ApolloReactHooks.useQuery<FindProductsByStyleColorQuery, FindProductsByStyleColorQueryVariables>(FindProductsByStyleColorDocument, baseOptions);
}
export function useFindProductsByStyleColorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindProductsByStyleColorQuery, FindProductsByStyleColorQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<FindProductsByStyleColorQuery, FindProductsByStyleColorQueryVariables>(FindProductsByStyleColorDocument, baseOptions);
}
export type FindProductsByStyleColorQueryHookResult = ReturnType<typeof useFindProductsByStyleColorQuery>;
export type FindProductsByStyleColorLazyQueryHookResult = ReturnType<typeof useFindProductsByStyleColorLazyQuery>;