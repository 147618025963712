import { SetStateAction, Dispatch } from "react";
import { DialogProps } from "../components/DisputeDialog";
import {
  useDisputeAlterationProductsMutation,
  AlterationProductStatus
} from "../../../apis/cims/generated/graphql-cims";
import { useHistory } from "react-router-dom";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { alterationDetailRefetchQuery } from "../../shared/functions/alterationDetailRefetchQuery";

export const useOnClickDispute = (
  props: DialogProps,
  setLoadingState: Dispatch<SetStateAction<boolean>>,
  setSubmitErrorMessage: Dispatch<SetStateAction<string[] | null>>,
  memoValue: string
) => {
  const [oktaUser] = useOktaUserStore();
  const receiverEmail = oktaUser.value?.email;
  const history = useHistory();
  const [
    disputeAlterationProductsMutation
  ] = useDisputeAlterationProductsMutation();

  return async (): Promise<true | Error> => {
    const itemsToSubmit = props.pendingItems;
    setLoadingState(true);
    const res = await disputeAlterationProductsMutation({
      awaitRefetchQueries: true,
      refetchQueries: [alterationDetailRefetchQuery(props.transferId)],
      variables: {
        input: itemsToSubmit.map(item => {
          return {
            id: item?.id || "no id",
            // id: "no id",
            statusNote: memoValue.trim(),
            status: AlterationProductStatus.Rejected,
            receiverScannerUserEmail: receiverEmail || "NO RECEIVER EMAIL"
          };
        })
      }
    });
    try {
      setLoadingState(false);
      let errorMessages = [];
      const updateAlterationProductBatch =
        (res.data && res.data.updateAlterationProductBatch) || null;
      const updateSuccess = updateAlterationProductBatch?.success;
      if (updateSuccess) {
        props.setIsShowSuccessSnackbar(true);
        history.goBack();
        return true;
      } else {
        updateAlterationProductBatch?.message &&
          errorMessages.push(updateAlterationProductBatch.message);
        errorMessages && setSubmitErrorMessage(errorMessages);
        return new Error(errorMessages.find(x => !!x));
      }
    } catch (err) {
      setLoadingState(false);
      const errorMessage = "There was a network error and your request failed.";
      setSubmitErrorMessage([errorMessage]);
      return new Error(errorMessage);
    }
  };
};
