import React, { useState } from "react";

import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogButton
} from "../../../rmwc/dialog";
import { TextField } from "../../../rmwc/textfield";
import { CircularProgress } from "@rmwc/circular-progress";
import { Maybe } from "../../../apis/cpg/generated/graphql-cpg";
import { AlterationProduct } from "../../../apis/cims/generated/graphql-cims";
import { useOnClickDispute } from "../hooks/useOnClickDispute";
import { Typography } from "@rmwc/typography";
import "../Reconcile.css";

export type DialogProps = {
  open: boolean;
  handleOpen: (t: boolean) => void;
  transferId: string | null;
  pendingItems: Maybe<Pick<AlterationProduct, "id">>[];
  setIsShowSuccessSnackbar: (t: boolean) => void;
};

export const ReconcilePageDialog = (props: DialogProps) => {
  const [loadingState, setLoadingState] = useState(false);
  const [memoValue, setMemoValue] = useState("");
  const isPurposeNoteValid = !!memoValue && !memoValue.match(/^\s*$/);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<Array<
    string
  > | null>(null);

  const onClickDispute = useOnClickDispute(
    props,
    setLoadingState,
    setSubmitErrorMessage,
    memoValue
  );

  return (
    <div style={{ zIndex: 9999 }} className="dispute-dialog">
      <Dialog open={props.open}>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: 0,
            margin: 0,
            marginRight: 10,
            marginTop: 5,
            backgroundColor: "#fff"
          }}
        >
          <span
            style={{ cursor: "pointer", color: "#8d8d8d" }}
            onClick={() => props.handleOpen(false)}
          >
            {" "}
            X{" "}
          </span>
        </div>
        <DialogTitle>
          <Typography use="headline3">DISPUTE</Typography>
          <hr style={{ marginTop: 0 }} />
        </DialogTitle>
        <DialogContent>
          <p>Dispute Item</p>
          <p>
            Disputing an item in this transfer will notify the sender and the
            admin.
          </p>

          <TextField
            placeholder="Memo required"
            textarea
            outlined
            fullwidth
            rows={2}
            maxLength={200}
            characterCount
            onChange={event => {
              setMemoValue(
                event.currentTarget.value.replace(/[^a-z0-9 .?!]/gi, "")
              );
            }}
            helpText={{
              persistent: true,
              validationMsg: true
            }}
            value={memoValue}
            style={{
              borderRadius: 0,
              fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif"
            }}
          />
          {submitErrorMessage?.map((errorMsg: string) => (
            <p key={errorMsg} style={{ color: "red" }}>
              {errorMsg}
            </p>
          ))}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-around",
            margin: "0 40px 20px 40px"
          }}
        >
          <DialogButton
            className="dialogButton"
            style={{
              padding: "5px 20px",
              borderRadius: 20,
              border: "1px solid black",
              fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
              textTransform: "capitalize",
              fontSize: 16,
              letterSpacing: 0,
              textAlign: "center",
              backgroundColor: loadingState
                ? "silver"
                : "var(--mdc-theme-on-primary)",
              color: "black"
            }}
            onClick={() => props.handleOpen(false)}
            disabled={loadingState}
          >
            Cancel
          </DialogButton>
          <DialogButton
            className="dialogButton"
            style={{
              padding: "5px 20px",
              fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
              textTransform: "capitalize",
              borderRadius: 20,
              border:
                loadingState || memoValue.length < 1 || !isPurposeNoteValid
                  ? "1px solid silver"
                  : "1px solid black",
              fontSize: 16,
              letterSpacing: 0,
              textAlign: "center",
              backgroundColor:
                loadingState || memoValue.length < 1 || !isPurposeNoteValid
                  ? "#F5F5F5"
                  : "var(--mdc-theme-primary)",
              color:
                loadingState || memoValue.length < 1 || !isPurposeNoteValid
                  ? "#CCCCCC"
                  : "var(--mdc-theme-on-primary)"
            }}
            disabled={
              loadingState || memoValue.length < 1 || !isPurposeNoteValid
            }
            action="accept"
            isDefaultAction
            icon={loadingState ? <CircularProgress /> : undefined}
            onClick={onClickDispute}
          >
            Dispute
          </DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
