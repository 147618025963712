import {
  useAlterationDetailQuery,
  Maybe,
  AlterationProduct,
  Alteration
} from "../../../apis/cims/generated/graphql-cims";
import { MAX_AWS_PARSABLE_DATE } from "../../../utils/constants";

type AlteratonProductsLoading = {
  alterationProducts: Maybe<
    Pick<
      AlterationProduct,
      "id" | "upc" | "status" | "alterationProductClosetProductId"
    >
  >[];
  alteration: Maybe<
    Pick<
      Alteration,
      | "id"
      | "polarity"
      | "pending"
      | "alterationClosetId"
      | "userEmail"
      | "createdAt"
      | "submittedAt"
      | "alterationToClosetId"
      | "purposeType"
    >
  >;
  loading: boolean;
};

export const useAlterationProducts = (
  transferId: string | null
): AlteratonProductsLoading => {
  const { data, loading } = useAlterationDetailQuery({
    variables: {
      id: transferId || "no transferId",
      createdAt: MAX_AWS_PARSABLE_DATE
    }
  });

  const alteration = data?.getAlteration || null;
  const alterationProducts =
    data?.listAlterationProductsByAlterationId?.items || [];

  return { alterationProducts, alteration, loading };
};
