import React from "react";
import { DataTableRow, DataTableCell } from "@rmwc/data-table";

export function EmptyStateDataTableRow(props: { children: React.ReactNode }) {
  return (
    <DataTableRow key="emptyrow">
      <DataTableCell></DataTableCell>
      <DataTableCell alignMiddle style={{ paddingTop: 50 }}>
        {props.children}
      </DataTableCell>
      <DataTableCell></DataTableCell>
    </DataTableRow>
  );
}
