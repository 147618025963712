import {
  Product,
  // useFindProductsByStyleColorQuery,
  FindProductsByStyleColorQuery,
  FindProductsByStyleColorQueryVariables
} from "../../../apis/cpg/generated/graphql-cpg";
import { QueryResult } from "@apollo/react-common";
import { cpgProductTitle } from "../../../apis/cpg/hooks/ProductPage";

interface CpgProductResult {
  product: Product | null;
  title: string;
}

// Size sorting of apparel and shoe sizes for manual entry

const otherSizes: string[] = [];
[...Array(22).keys()].map(sizeType => {
  otherSizes.push(sizeType / 2 + "Y");
  return otherSizes.push(sizeType / 2 + "C");
});

const sizesApparel = "XS|S|S-T|M|M-T|L|L-T|XL|XL-T|XXL|2XL|2XL-T|XXXL|3XL|3XL-T|4XL|4XL-T".split(
  "|"
);

export const sizesApparelAndOther = [...otherSizes, ...sizesApparel];

export const compareSizesToApparelOrder = (
  aApparelSize: string,
  bApparelSize: string
) => {
  const aApparelSizeIndex = sizesApparelAndOther.indexOf(aApparelSize);
  const bApparelSizeIndex = sizesApparelAndOther.indexOf(bApparelSize);
  if (aApparelSizeIndex < 0) {
    return 1;
  } else if (aApparelSizeIndex < bApparelSizeIndex) {
    return -1;
  } else if (aApparelSizeIndex > bApparelSizeIndex) {
    return 1;
  } else {
    return 0;
  }
};

export const compareSizesByShoeNumber = (
  aShoeSize: string,
  bShoeSize: string
) => {
  if (Number(aShoeSize) < Number(bShoeSize)) {
    return -1;
  } else if (Number(aShoeSize) > Number(bShoeSize)) {
    return 1;
  } else {
    return 0;
  }
};

export type CpgProductResultLoading = CpgProductResult & {
  loading: boolean;
};

export const useSizesFromStyleColor = (
  foundProducts: QueryResult<
    FindProductsByStyleColorQuery,
    FindProductsByStyleColorQueryVariables
  >
): CpgProductResultLoading => {
  const cpgProducts =
    foundProducts &&
    foundProducts.data &&
    foundProducts.data.findProducts &&
    foundProducts.data.findProducts.products;
  const cpgProduct =
    (cpgProducts &&
      (cpgProducts.find(product => {
        return (
          !!product &&
          !!product.region &&
          product.region === "USA" && // default to USA
          !!product.sizes &&
          !!product.sizes.find(size => {
            const isFound = !!size;
            return isFound; // but size must exist
          })
        );
      }) ||
        cpgProducts.find(product => {
          return (
            !!product &&
            !!product.sizes &&
            !!product.sizes.find(size => {
              const isFound = !!size;
              return isFound; // non-usa size better than none
            })
          );
        }) ||
        cpgProducts.find(product => {
          return !!product; // without size better than none
        }))) ||
    null;

  return {
    product: cpgProduct,
    title: cpgProductTitle(cpgProduct),
    loading: foundProducts.loading
  };
};
