import React from "react";
import { Typography } from "@rmwc/typography";

export default function NoResults() {
  return (
    <Typography
      use="body2"
      style={{
        color: "#616161"
      }}
    >
      No results
    </Typography>
  );
}
