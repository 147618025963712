import React from "react";
import {
  List,
  ListGroup,
  ListItem,
  ListItemGraphic,
  ListItemText
} from "@rmwc/list";
import { Link } from "react-router-dom";
import slugid from "slugid";
import { Drawer, DrawerContent } from "../../../rmwc/drawer";
import { DrawerHeader, DrawerTitle } from "@rmwc/drawer";
import SwooshCimsInverted from "../../../icons/SwooshCimsInverted";
import { useOktaUserStore } from "../../../store/useOktaUser";
import { Home, ArrowAltLeft, ArrowAltRight, Handshake, VerifiedUser } from "../../../icons";

type ClosetDrawerProps = {
  closetId: string | null;
  drawerOpen: boolean;
  setDrawerOpen: (x: boolean) => void;
};

export const ClosetDrawer = (props: ClosetDrawerProps) => {
  const activeClosetId = props.closetId ;
  const activeClosetSlugid = activeClosetId
    ? slugid.encode(activeClosetId)
    : "";
  const urlIncomingClosetId = `/transfers/incoming/?closet=${activeClosetSlugid}`;
  const urlOutgoingClosetId = `/transfers/outgoing/?closet=${activeClosetSlugid}`;
  const [oktaUser] = useOktaUserStore();
  const userEmail = oktaUser.value?.email?.replace("@nike.com", "");

  return (
    <Drawer
      open={props.drawerOpen}
      onClick={() => {
        props.setDrawerOpen(!props.drawerOpen);
      }}
      modal={true}
      onClose={() => props.setDrawerOpen(false)}
    >
      <DrawerHeader
        style={{
          backgroundColor: "var(--mdc-theme-primary)"
        }}
      >
        <DrawerTitle
          style={{
            color: "var(--mdc-theme-on-primary)",
            position:'relative',
            top: 4
          }}
        >
          <SwooshCimsInverted
            style={{ height: 18}}
          />
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <List>
          <ListGroup>

            <Link to="/closet" style={{ textDecoration: "none" }}>
              <ListItem>
                <Home style={{height: "18px", padding: "0 0 0 5px"}} />
                <ListItemText>Home</ListItemText>
              </ListItem>
            </Link>
            <Link to={urlIncomingClosetId} style={{ textDecoration: "none" }}>
              <ListItem>
                <ArrowAltLeft style={{height: "18px", padding: "0 35px 0 5px"}}/>
                <ListItemText>Incoming Transfers</ListItemText>
              </ListItem>
            </Link>
            <Link to={urlOutgoingClosetId} style={{ textDecoration: "none" }}>
              <ListItem>
                <ArrowAltRight style={{height: "18px", padding: "0 35px 0 5px"}}/>
                <ListItemText>Outgoing Transfers</ListItemText>
              </ListItem>
            </Link>
            <hr style={{borderColor: "black"}} />
            <a href="mailto:Lst-NA.Tech.SpecialAssets.Support@nike.com?subject=CIMS User Feedback" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemGraphic icon="mail" style={{color: "black"}} />
                <ListItemText>
                  Feedback
                </ListItemText>
              </ListItem>
            </a>
            <Link to="/eula" style={{ textDecoration: "none" }}>
              <ListItem>
                <Handshake style={{height: "18px", padding: "0 33px 0 1px"}} />
                <ListItemText>
                  User Agreement
                </ListItemText>
              </ListItem>
            </Link>
            <a href="https://nikehr.nike.com/?q=page/comp-product-policy-16349" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <ListItem>
                <VerifiedUser style={{height: "25px", padding: "0 33px 0 0"}} />
                <ListItemText>
                  Comp Product Policy
                </ListItemText>
              </ListItem>
            </a>
          </ListGroup>
          <ListGroup style={{ position: "absolute", bottom: 5, height: "100px" }}>
          <ListItem style={{height: "100%"}}>
                <ListItemText>
                  User: {userEmail}
                  <br/>
                  Developed by: Serve NA Tech
                  <br/>
                  V 1.1.0
                </ListItemText>
              </ListItem>
          </ListGroup>
        </List>
      </DrawerContent>
    </Drawer>
  );
};
