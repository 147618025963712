import React from "react";
import {
  DataTable,
  DataTableContent,
  DataTableBody
} from "../../../rmwc/data-table";
import { TransferDataTableHeader } from "./TransfersDataTableHeader";

export function TableWrapper(props: {
  children: React.ReactNode;
  loading: boolean;
  listType: "Outgoing" | "Incoming";
  statusType: "Disputed" | "Pending";
}) {
  return (
    <DataTable
      style={{
        width: "100%",
        maxWidth: 600,
        height: "calc(100% - 10px)",
        borderWidth: 0
      }}
    >
      <h5 style={{ marginBottom: 3 }}>{props.statusType}</h5>
      <DataTableContent style={{ width: "100%", borderWidth: 0 }}>
        <TransferDataTableHeader listType={props.listType} />
        <DataTableBody
          style={{
            height: "100%",
            width: "100%",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {props.children}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}
