enum CPG_ENDPOINT {
  PROD = "https://cpg.core.nike.net/",
  STAGING = "https://cpg-stg.core.nike.net/"
}

enum OIDC_ISSUER {
  PROD = "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
  STAGING = "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
}

const CONFIG = {
  // friendly prd environment
  ...getProductionConfig("cims.nike.com"),

  // friendly uat environment
  ...getStagingConfig("cims-uat.nike.com"),

  // friendly qa environment
  ...getStagingConfig("cims-qa.nike.com"),

  // friendly qaauto environment
  ...getStagingConfig("cims-qaauto.nike.com"),

  // friendly dev environment
  ...getStagingConfig("cims-dev.nike.com"),

  // unfriendly prd environment
  ...getProductionConfig("d31d21ern2vald.cloudfront.net"),

  // unfriendly uat environment
  ...getStagingConfig("dzmjgbbtwfmmn.cloudfront.net"),

  // unfriendly qa environment
  ...getStagingConfig("dujt0t1dut95n.cloudfront.net"),

  // unfriendly qaauto environment
  ...getStagingConfig("d7l5qhghynfy3.cloudfront.net"),

  // unfriendly dev environment
  ...getStagingConfig("dg4rjhqjaw2sf.cloudfront.net"),

  // local
  ...getStagingConfig("localhost")
}

export function getEnvironmentByHostname() {
  const currentHostName = window.location.hostname

  return CONFIG[currentHostName] || CONFIG["localhost"]
}

function getOIDC({ redirectUri, issuer }: { redirectUri: string, issuer: OIDC_ISSUER }) {
  return {
    clientId: "nike.natech.compinventory",
    issuer,
    redirectUri,
    scopes: ["gtms.cc.cpg.read", "openid", "profile", "email"]
  }
}

function getProductionConfig(host: string) {
  const redirectUri = `https://${host}/implicit/callback`
  return {
    [host]: {
      cpgEndpoint: CPG_ENDPOINT.PROD,
      oidc: getOIDC({ redirectUri, issuer: OIDC_ISSUER.PROD })
    }
  }
}

function getStagingConfig(host: string) {
  const port = host === 'localhost' ? ':3000' : ''
  const protocol = host === 'localhost' ? 'http' : 'https'
  const redirectUri = `${protocol}://${host}${port}/implicit/callback`

  return {
    [host]: {
      cpgEndpoint: CPG_ENDPOINT.STAGING,
      oidc: getOIDC({ redirectUri, issuer: OIDC_ISSUER.STAGING })
    }
  }
}
