import {
  IncomingProps,
  OutgoingProps,
  IncomingRejectedProps,
  OutgoingRejectedProps
} from "../TransferDetailPage";

export const enforceUserClosetIdRedirect = (
  currentPage: string,
  props:
    | IncomingProps
    | OutgoingProps
    | IncomingRejectedProps
    | OutgoingRejectedProps,
  activeUserClosetId: string | null,
  alterationToCloset: string | null
) => {
  if (
    currentPage.includes("incoming") &&
    activeUserClosetId !== alterationToCloset
  ) {
    const newPage = currentPage.replace("incoming", "outgoing");
    props.history.push(newPage);
  }
};
