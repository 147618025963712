import { useApolloClient } from "@apollo/react-hooks";
import { useProductDetailsFetch } from "../../../apis/cims/hooks/Closet";
import { productDetailsSummary } from "../../../apis/cims/hooks/UpcProduct";
import { useActiveClosetId } from "../../../store/useGlobalState";
import { useEffect } from "react";

export function useClosetProductsSummary() {
  const client = useApolloClient();

  const { productDetails, closet } = useProductDetailsFetch(client);
  const closetProductsSummarized = productDetailsSummary({
    ...productDetails,
    tag: "ClosetProducts"
  });

  const [,setActiveClosetId] = useActiveClosetId();

  useEffect(() => {
    if (closet?.data?.id) {
      setActiveClosetId(closet.data.id);
    }
  })

  return {
    closetProductDetails: productDetails,
    closetProductsSummarized,
    closet
  };
}
