import { useState, useEffect } from "react";
import ApolloClient from "apollo-client";
import SchemaLink from "apollo-link-schema";
import { mergeAllAPIEndpoints } from "../functions/mergeAllAPIEndpoints";
import { WithAuth } from "../../types/okta";
import { InMemoryCache } from "apollo-cache-inmemory";

export type Env = {
  cpgEndpoint: any;
  oidc?: {
    clientId: string;
    issuer: string;
    redirectUri: string;
    scopes: string[];
  };
};

interface UseApolloClient {
  loading: boolean;
  value: ApolloClient<any> | null;
}

export function useMergedApolloClient(
  env: Env,
  getAccessToken: WithAuth["auth"]["getAccessToken"],
  authenticated: boolean | null
) {
  const [client, setClient] = useState<UseApolloClient>({
    value: null,
    loading: true
  });
  useEffect(() => {
    // CPG request will fail if a user is not authenticated through Okta.
    if (authenticated) {
      (async () => {
        const combinedClient = await (async () => {
          return {
            value: new ApolloClient({
              link: new SchemaLink({
                //Make two GraphQL APIs consumable as if they were one API.
                //TECH DEBT: An API Gateway would be preferrable. https://www.apollographql.com/docs/apollo-server/api/apollo-gateway/#apollogateway
                //SUGGESTION:`amplify api add` (use rest endpoint to set up ApolloGateway)
                schema: await mergeAllAPIEndpoints(
                  getAccessToken,
                  env.cpgEndpoint
                )
              }),
              cache: new InMemoryCache()
            }),
            loading: false
          };
        })();
        setClient(combinedClient);
      })();
    }
  }, [authenticated, getAccessToken, env.cpgEndpoint]);
  return { client };
}
