import React from "react";
import {
  DataTableRow,
  DataTableHead,
  DataTableHeadCell
} from "../../../rmwc/data-table";

interface TransferDataTableHeaderProps {
  listType: string;
}

export function TransferDataTableHeader({
  listType
}: TransferDataTableHeaderProps) {
  return (
    <DataTableHead>
      <DataTableRow>
        <DataTableHeadCell style={{ width: "20%" }}>
          Transfer ID 
        </DataTableHeadCell>
        <DataTableHeadCell style={{ width: "60%" }}>
          {/* TODO: Replace ternary with exhaustive switch statement */}
          {listType === "Incoming"
            ? "Sent by email"
            : listType === "Outgoing"
            ? "Sent to email"
            : "Transfer email"}{" "}
          address 
        </DataTableHeadCell>
        <DataTableHeadCell style={{ width: "20%" }}>
          Sent Date
        </DataTableHeadCell>
      </DataTableRow>
    </DataTableHead>
  );
}
