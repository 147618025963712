import React from "react";

function SvgSwooshCimsInverted(props) {
  return (
    <svg
      id="SwooshCimsInverted_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 333.74 101.46"
      {...props}
    >
      <defs>
        <style>{".SwooshCimsInverted_svg__cls-1{fill:#fff}"}</style>
      </defs>
      <path
        className="SwooshCimsInverted_svg__cls-1"
        d="M190.28 339.65c-10.69 0-18.52-8.35-16.82-19.17s11.74-19.17 22.43-19.17c8.09 0 14.48 4.56 19.43 9.91l4.83-37.43a68.54 68.54 0 00-23.35-4.05c-28.82 0-55 23-59.47 51.65-4.43 28 14 49.82 42 49.82 6.39 0 19.95-1.18 26.08-3.91l6.91-36.78c-6.65 5.61-13.82 9.13-22.04 9.13z"
        transform="translate(-136.67 -269.74)"
      />
      <path
        className="SwooshCimsInverted_svg__cls-1"
        d="M87.39 1.56L71.86 99.9h35.74l15.52-98.34H87.39z"
      />
      <path
        className="SwooshCimsInverted_svg__cls-1"
        d="M342.61 271.31l-17.09 38.6A97.51 97.51 0 00321 323h-.91l-5.48-51.64h-36.82l-31.43 98.33h34.56l10.83-42c1-3.91 1.95-8 2.73-11.87h1.18a64.48 64.48 0 00.39 12.26l6.13 41.6h20.87l21.25-44.08c1.44-3.13 2.61-6.52 3.79-9.78h.65l-3.65 53.86h34.56l-.65-98.37zM442.9 308l-7.83-1.83c-2.61-.65-6.91-1.17-6.39-4.82.65-4.05 5.35-5.22 8.48-5.22 7.69 0 14.09 3.26 19.56 7.43l13.7-25.17c-8.74-5.87-18.65-8.61-29.87-8.61-21.39 0-44.73 13.05-48.38 36.39-2.48 15.39 7.95 23.61 21.51 26.08l7.05 1.31c3.13.65 7.82 1 7 5.48s-6.52 5.86-10 5.86c-8.22 0-15.26-4-20.87-9l-1.56-1.44-15.39 27.78c10.17 5.61 24 9 36.38 9 21.26 0 44.48-11.61 48.26-35.08 2.61-16.73-7.05-24.81-21.65-28.16z"
        transform="translate(-136.67 -269.74)"
      />
      <path
        d="M413.9 299.75q-36.73 9.36-73.47 18.71-32.48 8.25-65 16.41c-6.34 1.58-12.78 2.18-19.24.46-7.28-1.94-11.55-6.66-12.18-13.62-.49-5.26 1.13-10.17 3.16-15 1-2.44 2.24-4.82 3.37-7.22l-.22-.13c-.43.37-.88.72-1.29 1.11a116 116 0 00-18.77 22.76c-3.74 6-6.93 12.19-7.58 19.22-.75 8.15 2.92 16.58 13.2 19.84 6.9 2.19 13.87 1.7 20.83.42 8.47-1.57 16.39-4.57 24.25-7.81q66-27.27 132-54.4c.48-.2 1-.43 1.44-.65a.7.7 0 00-.5-.1z"
        transform="translate(-136.67 -269.74)"
        fill="#231f20"
      />
    </svg>
  );
}

export default SvgSwooshCimsInverted;
