import { useEffect } from "react";
import { queue } from "../../../utils/rmwcDialogQueue";

export const useUnsupportedBrowser = () => {
  useEffect(() => {
    const isChromeAndIos = navigator.userAgent.match("CriOS");
    if (isChromeAndIos) {
      queue.alert({
        title: "Unsupported Browser",
        body: "Use Safari on iOS"
      });
    }
  }, []);
};
