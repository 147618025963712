import ApolloClient from "apollo-client";
import {
  UpdateClosetMutation,
  UpdateClosetMutationVariables,
  UpdateClosetDocument
} from "../../../apis/cims/generated/graphql-cims";
import { closetProductsRefetchQuery } from "../../../apis/cims/hooks/ClosetProduct";
import { recordError } from "../../../utils/logging";

export const updateCloset = (client: ApolloClient<any>) => {
  return async (activeClosetId: string, userEmail: string, hasAcceptedEula: boolean) => {
    return client
      .mutate<UpdateClosetMutation, UpdateClosetMutationVariables>({
        mutation: UpdateClosetDocument,
        variables: {
          id: activeClosetId,
          hasAcceptedEula: hasAcceptedEula,
          acceptedEulaAt: new Date().toISOString(), 
          updatedAt: new Date().toISOString(),
        } as UpdateClosetMutationVariables,
        refetchQueries: [closetProductsRefetchQuery(userEmail)]
      })
      .then(result => {
        if (result) {
          return result;
        } else {
          recordError("83dfd90a-4fd8-4f01-82d1-05c79944f35e", result);
          return null;
        }
      })
      .catch(error => {
        recordError("e872d79d-55fa-48ae-a174-28e2e7bb83e9", error);
        return null;
      });
  };
};
