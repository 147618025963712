import { queue } from "../../../utils/rmwcDialogQueue";
import { recordGuid, recordError } from "../../../utils/logging";
import {
  graphqlErrorAlert,
  networkErrorAlert
} from "../../../utils/errorAlerts";
import { UseOktaUser } from "../../../store/useOktaUser";
import { MutationFn } from "../../../types/apollo";
import {
  pendingAlterationRefetchQuery,
  AlterationLoading
} from "../../../apis/cims/hooks/Alteration";
import { closetProductsRefetchQuery } from "../../../apis/cims/hooks/ClosetProduct";
import {
  UpdateAlterationProductStatusMutation,
  UpdateAlterationProductStatusMutationVariables,
  DeleteClosetProductBatchMutation,
  DeleteClosetProductBatchMutationVariables,
  UpdateAlterationMutation,
  UpdateAlterationMutationVariables,
  PurposeType,
  AlterationPolarity
} from "../../../apis/cims/generated/graphql-cims";
import { ClosetSelected } from "../types/ClosetSelected";
import { UseAlterationProducts } from "../../AddRemove/hooks/useAlterationProducts";

export const deleteClosetProductsOnClick = (
  closetSelectedConfirmed: ClosetSelected,
  updateAlterationProductStatusMutation: MutationFn<
    UpdateAlterationProductStatusMutation,
    UpdateAlterationProductStatusMutationVariables
  >,
  deleteClosetProductBatchMutation: MutationFn<
    DeleteClosetProductBatchMutation,
    DeleteClosetProductBatchMutationVariables
  >,
  alteration: AlterationLoading["data"],
  alterationProducts: UseAlterationProducts["alterationProducts"]["data"],
  updateAlterationMutationFn: MutationFn<
    UpdateAlterationMutation,
    UpdateAlterationMutationVariables
  >,
  activeClosetId: string | null,
  oktaUser: UseOktaUser,
  setSubmitAlterationProductsPending: (t: boolean) => void,
  purposeType: PurposeType | null,
  purposeNote: string | null,
  onStart: () => void,
  onSuccess: () => void
) => {
  const uponFailValue = (guid: string, error?: any) => {
    if (error) {
      recordError(guid, error);
    } else {
      recordGuid(guid);
    }
    setSubmitAlterationProductsPending(false);
    return false;
  };
  const uponSucceedValue = () => {
    onSuccess();
    setSubmitAlterationProductsPending(false);
    return false;
  };
  const uponFailPromise = (guid: string, error?: any): Promise<boolean> =>
    new Promise<boolean>(resolve => {
      resolve(uponFailValue(guid, error));
    });

  return (): Promise<boolean> => {
    const isPurposeNoteValid = !!purposeNote && !purposeNote.match(/^\s*$/);

    if (!isPurposeNoteValid) {
      return (async () => {
        return await queue.alert({
          title: "Purpose Required",
          body: "Please write your purpose in the text field."
        });
      })();
    } else if (!purposeType) {
      return (async () => {
        return await queue.alert({
          title: "Purpose Required",
          body: "Please select your purpose."
        });
      })();
    } else if (
      !closetSelectedConfirmed &&
      purposeType === PurposeType.Transfer
    ) {
      return (async () => {
        return await queue.alert({
          title: "Destination Closet Required",
          body: "Please select destination."
        });
      })();
    }

    setSubmitAlterationProductsPending(true);
    onStart();

    if (alterationProducts.length < 1) {
      queue.alert({
        title: "No items to submit",
        body: "Please scan items first."
      });
      return uponFailPromise("295ad0bd-bd0c-49dc-a555-7bd9bf1e62c0");
    } else {
      if (alteration) {
        if (alterationProducts) {
          if (activeClosetId) {
            return deleteClosetProductBatchMutation({
              variables: {
                input: {
                  alterationId: alteration.id,
                  purposeType,
                }
              }
            })
              .then(result => {
                const numOfErrors = result?.data?.deleteClosetProductBatch?.errors?.length || 0
                if (numOfErrors > 0) {
                  // the alteration was partially submitted.
                  queue.alert({
                    title: "Partial Submission",
                    body: "Please resubmit the alteration."
                  });
                  return uponFailPromise(
                    "8fdfc1bd-12b7-4466-9368-71d22fbae0f7"
                  );
                }
                if (result && result.errors) {
                  graphqlErrorAlert<
                    | DeleteClosetProductBatchMutation
                    | UpdateAlterationProductStatusMutation
                  >(result);
                  return uponFailPromise(
                    "8fdfc1bd-12b7-4466-9368-71d22fbae0f7"
                  );
                } else {
                  if (alteration && alteration.id) {
                    return updateAlterationMutationFn({
                      variables: {
                        id: alteration && alteration.id,
                        pending: false,
                        alterationClosetId:
                          alteration.alterationClosetId ||
                          "no alterationClosetId",
                        alterationToClosetId:
                          closetSelectedConfirmed && closetSelectedConfirmed.id,
                        purposeType: purposeType,
                        purposeNote: purposeNote,
                        userEmail:
                          (oktaUser.value && oktaUser.value.email) ||
                          "no userEmail",
                        polarity:
                          alteration.polarity || AlterationPolarity.Remove,
                        createdAt: alteration.createdAt || "no createdAt",
                        submittedAt: new Date().toISOString()
                      },
                      refetchQueries: [
                        closetProductsRefetchQuery(
                          (oktaUser.value && oktaUser.value.email) || ""
                        ),
                        closetProductsRefetchQuery(
                          oktaUser?.value?.email ?? "no email"
                        ),
                        pendingAlterationRefetchQuery(
                          oktaUser,
                          activeClosetId,
                          alteration.polarity || AlterationPolarity.Add
                        )
                      ].flat()
                    })
                      .then((result): boolean => {
                        if (
                          result &&
                          result.data &&
                          result.data.updateAlteration
                        ) {
                          if (result.data.updateAlteration.pending === false) {
                            return uponSucceedValue();
                          } else {
                            queue.alert({
                              title: "Partial Submission",
                              body: "Please resubmit remaining items."
                            });
                            return uponFailValue(
                              "12db5456-230f-4057-9253-59e604068f90"
                            );
                          }
                        } else {
                          return uponFailValue(
                            "590f4514-aedb-4dbc-8ae1-ea0337eab645"
                          );
                        }
                      })
                      .catch(error => {
                        return uponFailPromise(
                          "97ac39fe-2d0a-4217-a6ff-b11e3924b886"
                        );
                      });
                  } else {
                    return uponFailPromise(
                      "790bb181-6b19-47a7-9037-1de4300a827c",
                      alteration
                    );
                  }
                }
              })
              .catch(error => {
                const guid = "46390b88-806b-4f02-a0d6-cf88a68c1946";
                networkErrorAlert(guid, error);
                return uponFailValue(guid);
              });
          } else {
            recordGuid("8f1ecb89-2cb5-4038-886a-ad0273bd5546");
            return uponFailPromise("bc4831f8-7410-4bb5-99b0-d7d0395af8ac");
          }
        } else {
          recordGuid("58a969b8-7e89-4dd8-84c0-7f615d1d790e");
          return uponFailPromise("af55acae-4561-421e-b2b6-a24db0fec295");
        }
      } else {
        recordGuid("16927363-a271-4338-915a-c5b0b17d64a9");
        return uponFailPromise("1ac25596-c191-4456-a478-3ea5a4612176");
      }
    }
  };
};
